import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';


Vue.use(Vuetify);

export default new Vuetify({
    treeShake: true,
    defaultAssets: {
		font: {
			family: 'Inter'
		}
    },
    theme: {
        themes: {
            light: {
                primary: '#0E6BA8',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#41B883',
                warning: '#FFC107',
                lilas: '#a16efd',
                terciary: '#001C55',
                quarternary: '#A6E1FA'
            }
        }
    },
    lang: {
        locales: { pt },
        current: 'pt',
    }
});
