<template>
    <v-row>
        <v-dialog
        v-model="modalTurmas"
        width="900px"
        height="1200px"
        >
        <v-card  v-onClickaway="away">
            <v-card-title>
                <span class="text-h5">Quantidade de estudantes por turma</span>
            </v-card-title>
            <v-card-text>
                <div class="sm">
                <apexchart type="bar" height="100%" width="100%" :options="modalGraficoOptions" :series="modalGraficoSeries"></apexchart>
                </div>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="away()"
            >
                FECHAR
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>     
</template>

<script>

import pt from 'apexcharts/dist/locales/pt-br.json'
import { directive as onClickaway } from 'vue-clickaway'

export default {
    props: {
        data: Object
    },
    directives: {
      onClickaway: onClickaway
    },
    template: '<v-card v-on-clickaway="away">Click away</v-card>',
    data() {
        return { 
            modalTurmas: false,
            modalGraficoSeries:[{
                name: "Matriculados",
                data: []
            }],
            modalGraficoOptions: {
                chart: {
                    locales: [pt],
                    defaultLocale: "pt-br",
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                    tooltip: {
                        enabled: true,
                    }
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: "center",
                    text: "Nenhum dado disponível até o momento",
                    verticalAlign: "middle"
                }
            }
        }       
    },
    methods: {
        exibirTurmas(){
            this.axios.get('turma/ocupacaodetalhadaturma', {
                params: {
                    idUnidade: this.$props.data.IdUnidade,
                    ano: this.$props.data.ano,
                    idSerie: this.$props.data.IdSerie,
                    idTurno: this.$props.data.IdTurno,
                }
            }).then(res => {
                
                this.modalTurmas = true

                this.modalGraficoSeries = [{...this.modalGraficoSeries[0], 
                    ...{
                        data: []
                    }
                }]
                this.modalGraficoOptions = {...this.modalGraficoOptions, 
                    ...{
                        xaxis: {
                            categories: []
                        }
                    }
                }
                
                for(var i=0; i < res.data.length; i++){
                    this.modalGraficoOptions.xaxis.categories.push(res.data[i].Serie+" "+res.data[i].TurmaDescricao)
                    this.modalGraficoSeries[0].data.push(res.data[i].QtdAlunos)
                }
            })
        },
        away(){
            this.modalTurmas = false
            this.$emit('fecharModal', this.modalTurmas)
        },
    },
    mounted(){
        this.exibirTurmas()
    }
}
</script>
