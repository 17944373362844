<template>
  <div class="container-fluid">

    <br>

        <v-row>

            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                        PREFEITURA MUNICIPAL DE MACAÍBA - EPIDEMIOLÓGICO
                    </h4>
                    <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> PAINEL DE INFORMAÇÕES EPIDEMIOLÓGICAS </h5>
                </v-card>
            </v-col>
        
        </v-row>

    <v-layout row justify-space-between>
      
    <v-card height="500px" width="100%">
   
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between" width: style=" height:10%;width: 100% repeat-x;">
            <h6 class="m-0 font-weight-bold text-primary" style="text-align: center">Atendimentos por município no ano de {{this.anoAtual}} </h6>
          </div>
          <div class="card-body foobar1" style="height: 450px; width: 100%;z-index:0; position: relative">

              <div class="row pa-0 ma-0" align="center"
                    justify="center">
                <div class="col-xl-12 col-lg-12 pa-0 ma-0" align="center">
                  <v-progress-circular
                      v-show="!showMap"
                      size="70"
                      width="7"
                      color="primary"
                      indeterminate
                  ></v-progress-circular>
                </div>
              </div>

            <l-map v-if="showMap"
                  :zoom="zoom"
                  :center="center"
                  @update:zoom="zoomUpdated"
                  @update:center="centerUpdated"
                  @update:bounds="boundsUpdated"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-geo-json :geojson="geoJson"></l-geo-json>

              <!-- INSIRA O VALOR QUE QUISER PARA MULTIPLICAR O RADIUS NORMALIZADO -->
              <l-circle-marker v-for="item in arrayNormalizado" :key="item.codarea" :lat-lng="item.centroide"
                              :radius="item.quantidadeNormalizada*25"
                              :color="circle.color"
                              :fill-color="'red'"
              >
                <l-popup :latLng="item.centroide"
                        :options="{closeOnClick: false, autoClose: false, openPopup: false}">
                  <div class="box box5 shadow5">
                  <p>
                  {{item.nome}}
                  <br />
                  Quantidade de atendimentos: {{item.quantidade}}
                  </p>
                  </div>
                </l-popup>
              </l-circle-marker>
            </l-map>
          </div>
       
  
    </v-card>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment'
import pt from "apexcharts/dist/locales/pt-br.json"

export default {
  name: 'Home',
  data: () => {
    return {
      showChartOne: false,
      showChartTwo: false,
      showChartThree: false,
      showChartFour: false,
      showMap: false,
      anoAtual: moment().format('YYYY'),
      classificacaorisco: {
        series: [],
        chartOptions: {
          chart: {
            locales: [pt],
            defaultLocale: "pt-br",
            type: 'pie'
          },
          noData: {
            align: "center",
            text: "Nenhum dado disponível até o momento",
            verticalAlign: "middle"
          },
          colors: [],
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      faixaetaria: {
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
        series: [{
          name: 'Atendimentos por faixa etária',
          data: []
        }],
        chartOptions: {
          noData: {
            align: "center",
            text: "Carregando...",
            verticalAlign: "middle"
          },
          chart: {
            locales: [pt],
            defaultLocale: "pt-br",
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },

          xaxis: {
            categories: ["0 a 3", "4 a 7 ", "8 a 12", "13 a 18", "19 a 25", "26 a 40", "41 a 60", "61 a 80", "81 a 100", "100+"],
            position: 'top',
            offsetY: -10,
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              }
            }

          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        }
      },
      meses: {
        series: [{
          name: 'Atendimentos nos últimos meses',
          data: []
        }],
        chartOptions: {
          noData: {
            align: "center",
            text: "Carregando...",
            verticalAlign: "middle"
          },
          chart: {
            locales: [pt],
            defaultLocale: "pt-br",
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },

          xaxis: {
            categories: [],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              }
            }

          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        }
      },
      dias: {
        series: [{
          name: 'Atendimentos nos últimos 30 dias',
          data: []
        }],
        chartOptions: {
          chart: {
            locales: [pt],
            defaultLocale: "pt-br",
            height: 350,
            type: 'area'
          },
          noData: {
            align: "center",
            text: "Carregando...",
            verticalAlign: "middle"
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'date',
            categories: []
          },
          tooltip: {
            x: {
              format: 'MM/yyyy'
            },
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              }
            }
          }
        }
      },
      circle: {
        center: [47.413220, -1.0482],
        radius: null,
        color: 'blue',
        interactive: true,
        //  fillColor: 'red'
      },
      arrayLatLng: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8.5,
      center: [-5.877358, -35.632548],
      bounds: null,
      geoJson: null,
      arrayQuantidade:[],
      keyMunicipiosDatacom: null,
      arrayNormalizado: [],
      verificarNulo: null,
      arrayNomeMunicipios: []
    }
  },
  methods: {
    //METODO ONDE É POPULADO TODOS OS GRAFICOS DA SEÇÃO, COM EXCEÇÃO DO MAPA
    async getDataCharts() {
      this.axios('atendimento/atendimentosfaixaetaria').then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.faixaetaria.series[0].data.push(res.data[i].Quantidade)
        }
      })
      await this.axios('atendimento/ultimosatendimentosmeses').then(res => {
        for (var i = 0; i < res.data.length; i++) {
          moment.updateLocale('pt', {
            months : [
              "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul",
              "Ago", "Set", "Out", "Nov", "Dez"
            ]
          });
          var mesFormatado = moment([res.data[i].Ano, res.data[i].Mes], 'YYYY/MM').format('MMMM/YY');
          this.meses.series[0].data.push(res.data[i].Quantidade)
          this.meses.chartOptions.xaxis.categories.push(mesFormatado)
        }
      })
      await this.axios('atendimento/ultimosatendimentosdias').then(res => {
        for (var i = 0; i < res.data.length; i++) {
          var dataFormatada = moment(res.data[i].Data).format('DD/MM');
          this.dias.series[0].data.push(res.data[i].Quantidade)
          this.dias.chartOptions.xaxis.categories.push(dataFormatada)
        }
      })
      await this.axios('atendimento/atendimentoclassificacaorisco'
      //PARAMETRO QUE PODE SER USADO FUTURAMENTE
      // ,{
      //   params:{
      //     ano: 2019
      //   }
      // }
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.classificacaorisco.series.push(res.data[i].Quantidade)
          this.classificacaorisco.chartOptions.labels.push(res.data[i].classificacao)
          switch (res.data[i].classificacao){
            case "VERMELHO":
              this.classificacaorisco.chartOptions.colors.push('#ff6961')
              break;
            case "AMARELO":
              this.classificacaorisco.chartOptions.colors.push('#ffbf00')
              break;
            case "VERDE":
              this.classificacaorisco.chartOptions.colors.push('#77dd77')
              break;
            case "AZUL":
              this.classificacaorisco.chartOptions.colors.push('#0000ff')
              break;
            default:
              this.classificacaorisco.chartOptions.colors.push('#808080')
              break
          }
        }
      })
      await this.axios('atendimento/atendimentoclassificacaogenero'
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.classificacaogenero.series.push(res.data[i].Quantidade)
          this.classificacaogenero.chartOptions.labels.push(res.data[i].classificacao)
          switch (res.data[i].classificacao){
            case "Masculino":
              this.classificacaogenero.chartOptions.colors.push('#1976D2')
              break;
            case "Feminino":
              this.classificacaogenero.chartOptions.colors.push('#5B9AD9')
              break;
            default:
              this.classificacaogenero.chartOptions.colors.push('#808080')
              break
          }
        }
      })
      await this.axios('atendimento/atendimentoclassificacaodoencas'
      ).then(res => {
        for (var i = 0; i < res.data.length; i++) {
          this.classificacaogenero.series.push(res.data[i].Quantidade)
          this.classificacaogenero.chartOptions.labels.push(res.data[i].classificacao)
          switch (res.data[i].classificacao){
            case "Masculino":
              this.classificacaogenero.chartOptions.colors.push('#8EEFAA')
              break;
            case "Feminino":
              this.classificacaogenero.chartOptions.colors.push('#C08EEF')
              break;
            default:
              this.classificacaogenero.chartOptions.colors.push('#808080')
              break
          }
        }
      })

      .catch( () => {})
      .finally( () => {
        this.showChartOne = true
        this.showChartTwo = true
        this.showChartThree = true
        this.showChartFour = true
      })
    },
    //PEGAR QUANTIDADES DE ATENDIMENTOS DOS MUNICIPIOS NO BANCO DA DATACOM
    getQuantidadesDatacom(){
      this.axios('atendimento/atendimentoquantidademunicipio'
      //PARAMETRO QUE PODE SER USADO FUTURAMENTE
      // ,{
      //   params:{
      //     ano: 2019
      //   }
      // }
      ).then(res => {
        for(var i = 0; i < res.data.length; i++){
          this.arrayQuantidade.push(
              {
                IBGE: res.data[i].IBGE,
                Quantidade: res.data[i].Quantidade
              }
          )
        }
        console.log(this.arrayQuantidade)
        //METODO getLatLng chamado apenas após de já ter sido populado o array da datacom
        this.getLatLng()
      })
    },
    //METODO PARA VERIFICAR SE TAL MUNICIPIO DO IBGE EXISTE NO BANCO DA DATACOM
    //SE ELE EXISTE, QUER DIZER QUE POSSUI QUANTIDADE > 0
    verificaMunicipio(value){
      for (var j=0; j < this.arrayQuantidade.length; j++) {
        if (this.arrayQuantidade[j].IBGE == value) {
          this.verificarExistencia = true
          //Variável para armazenar a posição da cidade encontrada
          this.keyMunicipiosDatacom = j
        }
      }
    },
    getNomeMunicipiosIBGE(){
      this.axios('https://servicodados.ibge.gov.br/api/v1/localidades/estados/24/municipios').then(res =>{
        for(var i = 0; i < res.data.length; i++){
          this.arrayNomeMunicipios.push(
              {
                ID: res.data[i].id,
                Nome: res.data[i].nome
              }
          )
        }
      })
    },
    getLatLng(){
      this.axios.get('https://servicodados.ibge.gov.br/api/v2/malhas/24?resolucao=5&formato=application/json').then((res) => {
        for(var i = 0; i < res.data.objects.foo.geometries.length; i++){
          this.verificaMunicipio(res.data.objects.foo.geometries[i].properties.codarea);
          if(this.verificarExistencia===true){
            this.arrayLatLng.push({
              codarea: res.data.objects.foo.geometries[i].properties.codarea,
              nome: this.arrayNomeMunicipios[i].Nome,
              //Variável armazenada sendo usada pra acessar as quantidades da cidade da datacom
              quantidade: this.arrayQuantidade[this.keyMunicipiosDatacom].Quantidade,
              centroide: res.data.objects.foo.geometries[i].properties.centroide.reverse()
            })
          }
          this.verificarExistencia = null
          this.keyMunicipiosDatacom = null
        }
        //MÉTODO MATEMATICA PARA NORMALIZAR VARIAÇÕES MUITO GRANDES DE VALORES QUANTITATIVOS
        let media = this.arrayLatLng.reduce((total, valor) => total+valor.quantidade/this.arrayLatLng.length, 0);
        let variancia = this.arrayLatLng.reduce((total, valor) => total + Math.pow(media - valor.quantidade, 2)/this.arrayLatLng.length, 0);
        let desvioPadrao = Math.sqrt(variancia);
        //RECONSTRUÇÃO DE UM NOVO ARRAY COM AS QUANTIDADES NORMALIZADAS
        this.arrayNormalizado = this.arrayLatLng.map(item => {
          const container = {};
          container.codarea = item.codarea;
          container.centroide = item.centroide;
          container.nome = item.nome;
          container.quantidade = item.quantidade
          //Deslocamento da normalização do intervalo [-2;2] a [0;4] tornando [0:1]
          container.quantidadeNormalizada = ((((item.quantidade)-media)/desvioPadrao)+2)/4;
          return container;
        })
        this.showMap = true
        console.log(this.arrayNormalizado)
      })
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    }
  },
  mounted() {
    this.getDataCharts()
    this.getQuantidadesDatacom()
    this.getNomeMunicipiosIBGE()
  },
  async created () {
    //MALHA DO IBGE
    await this.axios.get('https://servicodados.ibge.gov.br/api/v2/malhas/24?resolucao=5&formato=application/vnd.geo+json').then( async (response) => {
      this.geoJson = await response.data
    })
  }
}
</script>
<style>

@media screen and (min-width: 200px) and (max-width: 1256px) {
#MAPA{
  width:100%;
}
}

.box{
  width:200px;
  height:40px;

}
.box p{
  font-family: 'Didact Gothic', sans-serif;
  font-size:1em;
  text-align:center;
  color:black;
}
.shadow5{
  position:relative;
}
.shadow5{
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}
.shadow5:before, .shadow5:after{
  position:absolute;
  content:"";
  box-shadow:0 10px 25px 20px #518C96;
  top:40px;left:10px;bottom:50px;
  width:15%;
  z-index:-1;
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  transform: rotate(-8deg);
}
.shadow5 p{
  width:100%;
  height:100%;
  margin-left:6%;
  border:2px dashed #F7EEEE;
  border-radius:5px;
}
.shadow5:after{
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  transform: rotate(8deg);
  right: 10px;left: auto;
}
</style>