<template>
    <div class="page">
        <br />
        <div class="container-fluid">
            <v-row class="mb-2">
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                            Relação de RH
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> Painel informativo da direção</h5>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                    <v-col :cols="12" sm="3">
                        <v-select
                            v-model="IdAno"
                            :items="anos"
                            label="Filtrar pelo ano"
                            @change="[getFuncionarios($event)]"
                            hide-details
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                    <v-col :cols="12" sm="3">
                        <v-autocomplete
                            v-model="IdUnidade"
                            :items="unidadesList"
                            item-text="Unidade"
                            item-value="IdUnidade"
                            label="Filtrar pela unidade"
                            @input="[getFuncionarios($event)]"
                            hide-details
                            :disabled="isDisabledAdmin"
                            outlined
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col :cols="12" sm="3">
                        <v-autocomplete
                            v-model="TipoVinculo"
                            :items="funcionarios"
                            item-text="TipoVinculo"
                            item-value="TipoVinculo"
                            label="Filtrar pelo tipo de vinculo"
                            @input="[getFuncionarios($event)]"
                            hide-details
                            :disabled="isDisabledAdmin"
                            outlined
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col :cols="12" sm="3" v-if="IdUnidade != null || IdAno != null">
                        <v-btn color="primary" width="100%" v-bind:id="dynamicCol='4'" @click="[limparFiltros()]" dark>
                            Limpar Filtros
                        </v-btn>
                    </v-col>

                    <v-col :cols="12" sm="6">
                        
                        <div class="card border-left-primary shadow h-100">
                            <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <i class="fas fa-user fa-2x text-gray-300 mr-3"></i>
                                <div class="h8 mb-0 text-gray-800">{{ nomeDiretor }} </div>                            
                            <div class="col-auto">
                            </div>
                            </div>
                                <div class="text-xs font-weight-bold text-primary text-uppercase mt-3"> Diretor </div>
                            </div>
                        </div>
                        
                    </v-col>
                    <v-col :cols="12" sm="6">
                        
                        <div class="card border-left-primary shadow h-100">
                            <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <i class="fas fa-user-friends fa-2x text-gray-300 mr-3"></i>
                                <div class="h8 mb-0 text-gray-800">{{ nomeViceDiretor }} </div>
                            <div class="col-auto">
                            </div>
                            </div>
                                <div class="text-xs font-weight-bold text-primary text-uppercase mt-3"> Vice Diretor </div>
                            </div>
                        </div>
                    </v-col>

            </v-row>
            <v-data-table
                    :headers="headersFuncionarios"
                    :items="funcionarios"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                    >
                    <!-- group-by="TipoVinculo" -->
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { mdiTruckCheckOutline } from '@mdi/js'
export default {
    data () {
        return {
            anos: [],
            diretores:[],
            nomeDiretor:null,
            nomeViceDiretor: null,
            IdAno: null,
            IdUnidade: null,
            unidadesList: null,
            unidadesList2:null,
            loading: false,
            search: null,
            headersFuncionarios:
            [
                {text:'Nome', value:'Nome', width:'25%'},
                {text:'Matrícula', value:'Matricula', width:'10%'},
                {text:'Tipo de vínculo', value:'TipoVinculo', width:'10%'},
                {text:'Cargo', value:'Cargo', width:'25%'},
                {text:'Função', value:'Funcao', width:'25%'},
            ],
            dynamicCol: 6,
            funcionarios: [],
            acesso: false,
        }
    },
    computed: {
        isDisabledAdmin() {
            return this.acesso
        }
    },
    methods: {
        getAnos(){
            for(let a = new Date().getFullYear(); a >= new Date().getFullYear() - 8; a--){
                this.anos.push(a);
            }
        },
        getUnidades(){
            this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
            this.axios.get('escola/user/escolas').then((res) => {
                this.unidadesList = res.data.filter(unidade => {
                unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
                if(unidade.IdUnidadeTipo === 1) {
                    return unidade;
                }
                })
            }).finally(()=>{
                this.getFuncionarios()
            })
        },
        getFuncionarios(){
            this.loading = mdiTruckCheckOutline
            this.axios.get('pessoa/funcionarios', {
                params: {
                IdUnidade: this.IdUnidade,
                ano: this.IdAno,
                tipoVinculo: this.TipoVinculo
                }
            }).then((res) => {

                if(this.funcionarios!=null){
                this.funcionarios = []
                }
                this.funcionarios = res.data
                this.getDiretores();
            }).finally(() => {
                this.loading = false
            })
        },
        getDiretores(){
            this.axios.get('/escola/escolas/diretores').then((res) => {
                this.diretores = res.data;
                const diretores = this.diretores.find(diretores => diretores.IdUnidade == this.IdUnidade)
                this.nomeDiretor = diretores.Diretor;
                this.nomeViceDiretor = diretores.Vice;
                if (diretores.Diretor == null) {
                    this.nomeDiretor = "Não existe diretor vinculado a unidade"
                }
                if (diretores.Vice == null) {
                    this.nomeViceDiretor = "Não existe vice diretor vinculado a unidade"
                }
            }).finally(() => {
                this.loading = false
            })
        },
        limparFiltros(){
            this.IdAno = new Date().getFullYear();
            this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
            this.TipoVinculo = null
            this.dynamicCol = 6
            this.getFuncionarios()
        }
    },
    mounted() {
        if(sessionStorage.getItem('admin') === 1 || sessionStorage.getItem('diretor') === 1){
            this.acesso = true
        }
        this.getAnos()
        this.getUnidades()
        this.getDiretores()
        this.IdAno = new Date().getFullYear();
    }
}
</script>
