<template>
    <div id="chart">
        <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
        dados: Array
    },
    data () {  
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                labels: []
            },
        }
    },
    mounted() {
        let total = null

        this.$props.dados.filter(array => {
            this.series.push(array.Quantidade)
            this.chartOptions.labels.push(array.Sexo)
        })

        for(let i = 0; i < this.$props.dados.length; i++)
            total += this.$props.dados[i].Quantidade
            
        this.$emit('messageFromChild', total)
    }
}
</script>