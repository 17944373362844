import { render, staticRenderFns } from "./SaudePainelAssistencia.vue?vue&type=template&id=8f4b51fc"
import script from "./SaudePainelAssistencia.vue?vue&type=script&lang=js"
export * from "./SaudePainelAssistencia.vue?vue&type=script&lang=js"
import style0 from "./SaudePainelAssistencia.vue?vue&type=style&index=0&id=8f4b51fc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports