<template>
  <v-container>
    <v-row class="justify-center pt-2">
      <v-card width="95%" class="primary rounded">
        <v-card-title class="primary" style="color: white">Minhas Turmas </v-card-title>
        <v-data-table
            :headers="headersTurmas"
            :items="turmasList"
            class="elevation-1"
            style="width: 100%"
        >
          <template v-slot:[`item.b2`] = "{ item }">
            <v-chip color="green" dark v-if="item.b2 == 0"> Presente </v-chip>
            <v-chip color="orange" dark v-else-if="item.b2 == 1"> {{ item.b2 }} Falta (s) </v-chip>
            <v-chip color="red" dark v-else> {{ item.b2 }} Falta (s) </v-chip>
          </template>
          <template v-slot:[`item.actions`] = "{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn class="mx-3"
                       v-on="on"
                       v-bind="attrs"
                       color="primary"
                       @click="[
                             aulason = false,
                             materiaison = false,
                             disciplinasLista = [],
                             disciplinason = true,
                             getDisciplina(item.IdTurma)]"
                       icon
                       v-scroll-to="{
                        el: '#Disciplinas',
                        easing: [.6, .80, .30, 1.9],
                        duration: 2000
                    }"
                >
                  <v-icon dark>
                    mdi-text-box-search
                  </v-icon>
                </v-btn>
              </template>
              Disciplinas
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <br/>
    
    <SectionOne id="Disciplinas">
      <v-row class="justify-center">
        <v-alert v-if="this.disciplinason==false"
                 width="95%"
                 class="black--text my-5"
                 color="amber lighten-2"
                 dark
                 dense
                 border="left"
        >
          <v-icon color="black"> mdi-school-outline </v-icon>
          <span class="pl-2"> Por favor, selecione uma <strong> Turma </strong> para visualizar as <strong>Disciplinas</strong>. </span>
        </v-alert>
        <v-card width="95%" class="primary rounded md-5 mt-5"  v-if="this.disciplinason==true">
          <v-card-title class="primary" style="color: white"> Disciplinas Cursadas
            <v-icon
            @click=[fecharModalDisciplina()]
            color="white"
            class="ml-auto"
            >
              X
            </v-icon> </v-card-title>

          <v-data-table
              :headers="headersDisciplinas"
              :items="disciplinasLista"
              class="elevation-1"
              style="width: 100%"
              :items-per-page="15"

          >
            <template v-slot:[`item.actions`] = "{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="mx-3"
                         v-on="on"
                         v-bind="attrs"
                         color="primary"
                         @click="[
                             aulason=true,
                             materiaison=false,
                             aulaList = [],
                             flag = true,
                             getAula(item.IdDisciplina)]"
                         icon
                         v-scroll-to="{
                        el: '#aulas',
                        easing: [.6, .80, .30, 1.9],
                        duration: 2000
                    }"
                  >
                    <v-icon dark>
                      mdi-text-box-search
                    </v-icon>
                  </v-btn>
                </template>
                Aulas
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="mx-3"
                         v-on="on"
                         v-bind="attrs"
                         color="primary"
                         @click="[
                           aulason = false,
                           materiaison = true,
                           aulaList = [],
                           flag = true,
                           getMateriais(item.IdDisciplina)]"
                         icon
                         v-scroll-to="{
                        el: '#materiais',
                        easing: [.6, .80, .30, 1.9],
                        duration: 2000
                    }"
                  >
                    <v-icon dark>
                      mdi-archive-arrow-down
                    </v-icon>
                  </v-btn>
                </template>
                Materiais
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </SectionOne>

    

    <SectionOne id="aulas">
      <v-row class="justify-center">
        <v-alert v-if="this.disciplinason==true && (this.aulason == false && this.materiaison == false)"
                 width="95%"
                 class="black--text my-5"
                 color="amber lighten-2"
                 dark
                 dense
                 border="left"
        >
          <v-icon color="black"> mdi-school-outline </v-icon>
          <span class="pl-2"> Por favor, selecione uma <strong> Disciplina </strong> para visualizar suas informações. </span>
        </v-alert>
        <v-card width="95%" class="primary rounded md-5 mt-5"  v-if="this.aulason==true">
          <v-card-title class="primary" style="color: white"> Aulas Ministradas
            <v-icon
            @click=[fecharModalAulas()]
            color="white"
            class="ml-auto"
            >
              X
            </v-icon>
            </v-card-title>
          <v-data-table
              :headers="headersAulas"
              :items="aulaList"
              class="elevation-1"
              style="width: 100%"
          >
            <template v-slot:[`item.DataAula`] = "{ item }">
              {{ $moment(item.created_at).format('L LTS') }}
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item }">
                <v-btn :href="item.Path" color="success" target="_blank"> Visualizar
                    <v-icon  target="_blank">mdi mdi-file</v-icon>
                </v-btn>
            </template> -->
          </v-data-table>
        </v-card>
      </v-row>
    </SectionOne>
    
    <div v-if="flag">
      <br/>
    </div>

    <SectionOne id="materiais">
      <v-row class="justify-center">
        <v-card width="95%" class="primary rounded" v-if="this.materiaison==true">
          <v-card-title class="primary" style="color: white"> Materiais </v-card-title>
          <v-data-table
              :headers="headersMateriais"
              :items="materiaisList"

              :search="search"
              class="elevation-1"
              style="width: 100%"
          >

            <template v-slot:[`item.actions`] = "{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      icon
                      dark
                      small
                      
                      target="_blank"
                      :href="urlBase + item.Caminho.substring(6)"
                  >
                    <v-icon 
                    dark
                    target="_blank">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                Download
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </SectionOne>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        class="mx-2"
        fab
        dark
        fixed
        bottom
        small
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
    
    <div v-if="flag">
      <br/>
    </div>
    
    <v-row class="justify-center pt-2">
      <v-card width="95%" class="primary rounded md-5 mt-5">
        <v-card-title class="primary" style="color: white">Calendário Bimestral do Ano Letivo de {{ $moment().format("Y") }} </v-card-title>
      </v-card>
      <v-card width="95%">
        <div class="col-xl-12 col-lg-12" :style="$vuetify.breakpoint.mdAndDown ? 'margin-left: 30px;' : 'margin-left: 100px;'">
          <calendario :idAno="idAno"></calendario>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import { mdiAccountClock, mdiAccountArrowRightOutline, mdiAccountReactivateOutline, mdiAccountSearchOutline, mdiAccountCheckOutline } from '@mdi/js'
import calendario from '@/components/educacao/EducacaoCalendarioBimestral.vue'
export default {

  name: "AlunoAulas",
  components: {
    calendario
  },
  data: () => ({
    idAno: new Date().getFullYear(),
    icons: {
        mdiAccountClock,
        mdiAccountArrowRightOutline,
        mdiAccountReactivateOutline,
        mdiAccountSearchOutline,
        mdiAccountCheckOutline
    },
    turmasList:[],
    urlBase: 'http://sis.macaiba.dti.systems/ge/',
    flag: false,
    materiaisList:[],
    aulaList:[],
    disciplinasLista:[],
    TurmaSelecionada:null,
    AulaSelecionada:null,
    aulason: false,
    materiaison: false,
    disciplinason: false,
    fab: false,
    search: "",
    headersTurmas: [
      {text: 'Ano Letivo', value: 'AnoLetivo', show: true, align: 'left', width: '20%'},
      {text: 'Turma', value: 'TurmaDescricao', show: true, width: '30%', align: 'left'},
      {text: 'Escola', value: 'Unidade', show: true, width: '40%',align: 'left'},
      {text: 'Ações', value: 'actions', show:true, width:'10%', align: 'center'}
    ],
    headersDisciplinas: [
      {text: 'Disciplina', value: 'Disciplina', show:true, width: '40%'},
      {text: 'Docente', value: 'Docente', show:true, width: '40%'},
      {text: 'Ações', value: 'actions', show: true, align: 'center', width: '20%'},

    ],
    headersAulas: [
      {text: 'Data', value: 'DataAula', show: true, align: 'center', width: '10%'},
      {text: 'Objeto de conhecimento', value: 'ObjetoConhecimento', show: true, width: '40%'},
      {text: 'Detalhamento da Aula', value: 'detalhamentoAula', show:true, width: '50%'},
    ],
    headersMateriais: [
      {text: 'Data', value: 'DataIns', show: true, align: 'center', width: '16%'},
      {text: 'Arquivo', value: 'Descricao', show: true, width: '37%'},
      {text: 'Detalhamento do Arquivo', value: 'Historico', show:true, width: '37%'},
      {text: 'Ações', value: 'actions', show: true, align: 'center', width: '10%'},
    ],
    materiais: [],

  }),
  methods:{
    
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    getTurmas(){
      this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
      this.axios.get('turma/aluno').then((res) => {
        this.turmasList = res.data.map(turma => {
          return {
            IdTurma: turma.IdTurma,
            TurmaDescricao: turma.Serie+' - '+turma.TurmaDescricao,
            AnoLetivo: turma.AnoLetivo,
            Unidade: turma.Unidade,
            Sigla: turma.Sigla,
            IdTurmaDisciplina: turma.IdTurmaDisciplina,
          }
        })
      })
    },
    getDisciplina(Idturma){
      this.axios.get('turma/disciplinas', {
        params: {
          IdTurma: Idturma,
        }
      }).then((res) => {
        this.disciplinasLista = res.data.map(disciplina => {
          return {
            Disciplina: disciplina.Disciplina,
            Docente: disciplina.Docente,
            IdDisciplina: disciplina.IdTurmaDisciplina
          }
        })
      })
    },
    getAula(IdTurmaDisciplina) {
      this.axios.get('aluno/turma/aula', {
        params: {
          IdDisciplina: IdTurmaDisciplina,
        }
      }).then((res) => {
        this.aulaList = res.data.map(aula => {
          return {
            ObjetoConhecimento: aula.objetoConhecimento,
            detalhamentoAula: aula.detalhamentoAula,
            DataAula: aula.DataAula,
          }
        })
      })
    },
    async getMateriais(IdTurmaDisciplina) {
       const materiais = await this.axios.get('aluno/turma/material', {
        params: {
          IdDisciplina: IdTurmaDisciplina,
          }
        })
      this.materiaisList = materiais.data
      
      },
      fecharModalDisciplina(){
      this.disciplinason = false,
      this.aulason = false
      },
      fecharModalAulas(){
      this.aulason = false
      } 
    },
  
  mounted(){
    this.getTurmas();
  }
}
</script>