import Vue from 'vue'
import VueRouter from 'vue-router'
import routerEducacao from './educacao.router'
import routerSaude from './saude.router'
import routerRequerimento from './requerimento.router'
import routerDirecao from './direcao.router'
import routerAluno from './aluno.router'
import routerProfessor from './professor.router'
import routerFinanceiro from './financeiro.router'
import routerAssistencia from './assistencia.router'
import Login from './../views/Login.vue'
//import Form from './../views/Form.vue'
import TermsForm from './../views/TermsForm.vue'
import Resultado from './../views/Resultado.vue'
import Home from './../views/Home.vue'
import About from './../views/About'
import ConfirmarEmail from '../views/ConfirmarEmail'
import EmConstrucao from '../views/EmConstrucao'

import VideosTable from '../views/VideosTable'
import { permissionChecker } from '../utils/permission'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/resultado',
        name: 'resultado',
        component: Resultado,
    },
    {
        path: '/termsForm',
        name: 'termsForm',
        component: TermsForm,
    },    
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/confirmarEmail/*',
        name: 'confirmarEmail',
        component: ConfirmarEmail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/EmConstrucao',
        name: 'EmConstrucao',
        component: EmConstrucao,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/videos',
        name: 'Videos',
        component: VideosTable,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_VIDEOS|PROPAGANDA_INSTITUCIONAL')
        }
    },  
    ...routerEducacao,
    ...routerSaude,
    ...routerRequerimento,
    ...routerDirecao,
    ...routerAluno,
    ...routerFinanceiro,
    ...routerAssistencia,
    ...routerProfessor
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) =>
{
    const isUserAuth = sessionStorage.getItem('token') !== null;

    if (to.meta.requiresAuth && !isUserAuth)
    {
        next('/')
    }
    if (
        to.meta.checker && !to.meta.checker()
        || to.path == '/' && isUserAuth
    )
    {
        next('/home');
    }

    next();
})

export default router
