<template>
  <v-card v-if="show" max-height="430" style="overflow-y:auto">
    <v-card-title class="pb-0">
      <span style="font-size: 0.9em; font-weight: normal;">Últimas fichas chamadas</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Consulte as últimas fichas chamadas</span>
        </v-tooltip>
    </v-card-title>

    <v-data-table
      :items-per-page="200"
      :headers="headers"
      :items="items"
      item-key="Nome"
      class="table-rounded"
      hide-default-footer
      disable-sort
    >
      <template #[`item.Nome`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.Nome }}</span>
          <small>{{ item.Profissioanl }}</small>
        </div>
      </template>
      <template #[`item.Data`]="{item}">
        {{ $moment(item.Data + ' ' + item.Hora, 'L LTS').startOf('minute').fromNow() }}
      </template>
      <template #[`item.Ficha`]="{item}">
        <v-chip
          small
          :color="statusColor(item.Risco)"
          class="font-weight-medium white--text"
        >
          {{ item.Ficha }}
        </v-chip>
      </template>
      <template #[`item.Tempo`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              :class="diffTime(item.Tempo) == true ? 'blink red--text' : 'red--text'"
            >
              <span>
                {{item.Tempo}}
              </span>
          </v-btn>
          </template>
          <span>Tempo médio de espera é {{ esperaMedia }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
  <v-card v-else height="430">
    <v-container fill-height fluid>
      <v-row align="center"
          justify="center">
          <jumper class="text-center"></jumper>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline } from '@mdi/js'
import {Jumper} from 'vue-loading-spinner'
import moment from 'moment'
export default {
  props: {
    items: Array,
    show: Boolean,
    averageWaiting: Number,
  },
  components: {
    Jumper
  },
  computed: {
    esperaMedia () {
      return moment.utc(this.averageWaiting*1000).format('LTS')
    },
  },
  data () {
    return {
      headers: [
        { text: 'Ficha', value: 'Ficha', align: 'center' },
        { text: 'Nome', value: 'Nome' },
        { text: 'Local', value: 'Local', align: 'left' },
        { text: 'Atendimento Médico', value: 'Data',  align: 'left'  },
        { text: 'Período em Espera', value: 'Tempo', align: 'center' },
      ],
      icons: {
        mdiSquareEditOutline,
      },
    }
  },
  methods: {
    diffTime (value) {
      if (Date.parse(`01/01/2021 ${value}`) > Date.parse(`01/01/2021 ${moment.utc(this.averageWaiting*1000).format('LTS')}`)){
        return true
      }else{
        return false
      }
    },
    statusColor(value){
      switch (value) {
        case 'VERDE':
          return 'success'
        case 'AMARELO':
          return '#FFC107'
        case 'VERMELHO':
          return '#FF5252'
        case 'LARANJA':
          return '#ffA500'
        case 'AZUL':
          return 'info'
        default:
          return 'warning'
      }
    }
  }
}
</script>
<style lang="scss">
.blink{
    span{
        animation: blink 2s linear infinite;
    }
}

@keyframes blink{
    0%{ opacity: .1; }
    50%{ opacity: .99; }
    100%{ opacity: .99; }
}
</style>
