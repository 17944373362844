<template>
    <v-card max-height="550" style="overflow-y:auto">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <v-text-field
                v-model="search"
                prepend-icon="mdi-feature-search-outline"
                label="Pesquisar"
                clearable
            ></v-text-field> 
        </div>
        <v-data-table 
            width="100%" 
            :headers="header"
            :items="cargos"
            :items-per-page="5"
            :search="search"
            class="elevation-0">
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: {
        dados: Array
    },
    data () {
        return {
            cargos: this.$props.dados,
            search: null,
            header:
            [
                // {text:'Ano', value:'Ano', width:'40%'},
                {text:'Descrição', value:this.$props.dados[0].centroCusto ? 'centroCusto' : 'Cargo', width:'40%'},
                {text:'Valor', value:'Valor', width:'20%', align:'right'},
            ]
        }
    },
    methods: {
        formatter(){
            for(let i = 0; i < this.cargos.length; i++)
                this.cargos[i].Valor = this.cargos[i].Valor.toLocaleString('pt-BR', { minimunFractionDigits: 4, style: 'currency', currency: 'BRL'})
        },
        // mudarNome(){
        //     if(this.$props.dados[0] == centroCusto){
        //         this.header = [
        //         {text:'Centro de Custo', value:this.$props.dados[0].centroCusto ? 'centroCusto' : 'Cargo', width:'40%'},
        //         {text:'Valor', value:'Valor', width:'20%', align:'right'},
        //         ]
        //     }
        //     else{
        //         this.header = [
        //         {text:'Cargo', value:this.$props.dados[0].centroCusto ? 'centroCusto' : 'Cargo', width:'40%'},
        //         {text:'Valor', value:'Valor', width:'20%', align:'right'},
        //         ]
        //     }
        // }
    },
    mounted() {
        this.formatter();
    }
}
</script>
