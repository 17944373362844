import AreaDiretor from '../views/direcao/AreaDiretor.vue'
import RelacaoFuncionarios from '../views/direcao/RelacaoFuncionarios.vue'
import { permissionChecker } from '../utils/permission';

export default [ 
    {
        path: '/direcao',
        name: 'areadiretor',
        component: AreaDiretor,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|PAINEL_DIRECAO')
        }
    },
    {
        path: '/funcionarios',
        name: 'relacaofuncionarios',
        component: RelacaoFuncionarios,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|RELACAO_RH')
        }
    }, 
];
