<template>
  <v-card height="408" v-if="show">
    <v-card-title class="align-start">
      <span style="font-size: 0.9em; font-weight: normal;">Municípios atendidos por ano</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly && (windowWidth > 1360 || windowWidth < 1264)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
              mdi-information-outline
          </v-icon>
        </template>
        <span>Confira o ranking dos municípios com os maiores <br> índices de atendimento para o ano vigente.</span>
      </v-tooltip>
    </v-card-title>
    
    <v-card-text class="my-0">
      <div class="d-flex align-center">
        <span style="font-size: 1.5em; font-weight: bold" class="mt-0">
          {{ amount.toLocaleString('pt-BR') }}
        </span>

        <div class="d-flex align-center">
          <v-icon
            size="40"
            color="success"
          >
            {{ icons.mdiMenuUp }}
          </v-icon>
          <!-- <span class="text-base font-weight-medium success--text ms-n2">100%</span> -->
        </div>
      </div>

      <span style="font-size: 1.2em" class="mt-0">
        Total de {{ amount.toLocaleString('pt-BR') }} atendimentos
      </span>
    </v-card-text>

    <v-card-text>
      <div
        v-for="item in items"
        :key="item.index"
        class='d-flex align-start'
      >
        <v-avatar
          rounded
          size="35"
          color="#5e56690a"
          class="me-2 mt-0"
        >
          <span class="primary--text">{{ item.Cidade.substring(0, 2) }}</span>
        </v-avatar>

        <div class="d-flex align-center flex-grow-1 flex-wrap pt-1">
          <div>
            <span class="text--primary text-base"> {{ item.Cidade }} </span>
            <span class="text-no-wrap" style="color: green; font-size: 0.9em">{{ ((item.Quantidade / amount)*100).toString().substring(0, 4) }}%</span>
          </div>

          <v-spacer></v-spacer>

          <div class="ms-1 pt-1" v-if="!((windowWidth >= 960 && windowWidth < 1040) || (windowWidth < 1360 && windowWidth >= 1264))">
            <p class="text--primary font-weight-medium mb-0">
              {{ item.Quantidade }}
            </p>
            <v-progress-linear
              :value="(item.Quantidade/amount)*100"
              color='primary'
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
  <v-card v-else height="408">
    <v-container fill-height fluid>
      <v-row align="center"
          justify="center">
          <hexagon class="text-center"></hexagon>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import { mdiMenuUp } from '@mdi/js'
import {Hexagon} from 'vue-loading-spinner'
export default {
  mixins: [resizeMixin],
  props: {
    amount: Number,
    items: Array,
    show: Boolean,
  },
  components: {
    Hexagon
  },
  data () {
    return {
      icons: { mdiMenuUp }
    }
  }
}
</script>
