<template>
  <v-card height="432" v-if="show">
    <template v-if="items.length">
      <v-carousel
        light
        vertical
        cycle
        continuous
        :show-arrows="false"
        hide-delimiters
        interval="4000"
        transition="fade"
        class="fill-height"
      >
        <v-carousel-item :key="index" v-for="(itemGroup, index) in chunkItems(itemsComputed)">
          <v-card-title class="pb-0">
            <span style="font-size: 0.9em; font-weight: normal;">Plantonistas</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Veja todos os plantonistas em <br> atuação neste momento</span>
          </v-tooltip>
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item
                :key="item.id" v-for="item in itemGroup"
                class='d-flex align-center px-0 pt-3'
              >
                <v-avatar
                  color="transparent"
                  :size="windowWidth < 1340 && windowWidth >= 1264 ? '20' : '40'"
                  class="white--text font-weight-medium me-3`"
                >
                  <v-img :src="require(`../../assets/${getIcone(item.Tipo)}`)"/>
                </v-avatar>

                <div class="d-flex align-center flex-grow-1 flex-wrap pl-4">
                  <div class="me-2">
                    <div class="font-weight-semibold">
                      <span class="text--primary text-base me-1">{{ item.Nome }}</span>
                    </div>

                    <v-list-item-subtitle class="text-xs">
                      {{ item.Tipo }}
                    </v-list-item-subtitle>
                  </div>

                  <v-spacer></v-spacer>

                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-carousel-item>
      </v-carousel>
    </template>
    <template v-else>
      <v-container fill-height fluid>
      <v-row align="center"
          justify="center">
          <v-col cols="12" align="center">
            <span style="color: #41B883; font-size: 1.2em"> Não há plantonistas no momento </span>
          </v-col>
          <v-col cols="12" align="center">
            <circle7 class="text-center"></circle7>
          </v-col>
      </v-row>
    </v-container>
    </template>
  </v-card>
  <v-card v-else height="432">
    <v-container fill-height fluid>
      <v-row align="center"
          justify="center">
          <rotate-square5 class="text-center"></rotate-square5>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import {RotateSquare5, Circle7} from 'vue-loading-spinner'
export default {
  mixins: [resizeMixin],
  props: {
    items: Array,
    show: Boolean
  },
  data () {
    return {
      itemsPerCarousel: 5,
    }
  },
  components: {
    RotateSquare5,
    Circle7
  },
  computed: {
    itemsComputed () {
      return this.items
    },
  },
  methods: {
    getIcone(value) {
      switch (value) {
        case 'MÉDICO':
          return 'medico.png'
        case 'ENFERMEIRO':
          return 'enfermeiro2.png'
        case 'RECEPCIONISTA':
          return 'recepcionista.png'
        case 'ASSIS. SOCIAL':
          return 'asocial.png'
        default:
          return 'medico.png'
      }
    },
    chunkItems(arr) {
      var newArr = [];
      for (var i = 0; i < arr.length; i += this.itemsPerCarousel) {
        newArr.push(arr.slice(i, i + this.itemsPerCarousel));
      }
      return newArr;
    },
  }
}
</script>
