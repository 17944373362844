<template>
        <v-card height="408" max-height="408" style="overflow-y:auto" v-if="show">
            <v-card-title class="pb-0">
                <span style="font-size: 0.9em; font-weight: normal;">
                    Registro no {{period}} de atendimentos por {{atendimentosBy}}
                </span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                        mdi-information-outline
                        </v-icon>
                    </template>
                    <span>
                        Acompanhe o ranking {{periodDescription}} de atendimentos<br>
                        por {{atendimentosBy}} dentro do município.
                    </span>
                </v-tooltip>
            </v-card-title>
            <v-simple-table max-height="400" style="table-layout:fixed;" width="100%">
                <template v-slot:default max-height="400">
                    <thead >
                        <tr>
                            <th width="50%" class="text-left">
                                {{capitalizedAtendimentosBy}}
                            </th>
                            <!-- <span>Retirado para análise de se é viável colocar o grau de estadp de alerta</span> -->
                            <!-- <th style="text-align: center !important" width="30%" class="text-left">
                                Grau
                            </th> -->
                            <th width="20%" class="text-left">
                                Atendimentos
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.especialidade"
                        >
                            <td>{{ item[keyAtendimentosBy] }}</td>
                            <!-- <span>Retirado para análise de se é viável colocar o grau de estadp de alerta</span> -->
                            <!-- <td align="center"><v-chip :color="item.color" class="white--text">{{ item.grau }}</v-chip></td> -->
                            <td align="center">{{ item[keyAmount] }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-card height="408" v-else>
            <v-container fill-height fluid>
                <v-row align="center" justify="center">
                    <diamond/>
                </v-row>
            </v-container>
        </v-card>
</template>

<script>
import {Diamond} from 'vue-loading-spinner'

export default {
    props: {
        atendimentosBy: String,
        period: String,
        keyAtendimentosBy: String,
        keyAmount: String,
        items: Array,
        show: Boolean
    },
    components: {
        Diamond
    },
    computed: {
        periodDescription: function() {
            switch(this.period) {
                case 'ano':
                    return 'anual'
                case 'mês':
                    return 'mensal'
                case 'dia':
                    return 'diário'
                default:
                    return ''
            }
        },
        capitalizedAtendimentosBy: function() {
            return this.atendimentosBy.charAt(0).toUpperCase() + this.atendimentosBy.slice(1)
        }
    }
} 
</script>