import jwtDecode from 'jwt-decode'

function getUserPermissions()
{
    return jwtDecode(sessionStorage.getItem('token')).permissions;
}

function getUserGroups()
{
    return jwtDecode(sessionStorage.getItem('token')).groups;
}

function isAdmin()
{
    return getUserGroups().includes('ADMINISTRADORES - DATACOM');
}

function genericPermissionCheck(userPermissions, requiredPermission)
{
    if (requiredPermission.includes('|*'))
    {
        userPermissions = userPermissions.map(
            permission => permission.split('|')[0]
        );
        requiredPermission = requiredPermission.replace('|*', '')
    }
    
    return userPermissions.includes(requiredPermission)
}


const permissionChecker =
{
    hasAny: (...required) =>
    {
        const userPermissions = getUserPermissions();
        
        return isAdmin()
        ||
        required.some(
            requiredPermission => genericPermissionCheck(userPermissions, requiredPermission)
        )
        
    },
    hasAll: (...required) =>
    {
        const userPermissions = getUserPermissions();

        return isAdmin()
        ||
        required.every(
            requiredPermission => genericPermissionCheck(userPermissions, requiredPermission)
        )
    }
};

const groupChecker =
{
    hasAny: (...required) =>
    {
        const userGroups = getUserGroups();

        return isAdmin()
        || 
        required.some(
            group => userGroups.includes(group)
        )
    },
    hasAll: (...required) =>
    {
        const userGroups = getUserGroups();

        return isAdmin()
        ||
        required.every(
            group => userGroups.includes(group)
        )
    }
};

export { permissionChecker, groupChecker };