<template>
    <v-select
        :items="list"
        v-model="defaults"
        item-text="name"
        item-value="id"
        label="Filtrar pelo bimestre"
        @change="sendMessageToParent($event)"
        hide-details
        outlined
        dense
    ></v-select>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    id: 20,
                    name: '1° Bimestre'
                },
                {
                    id: 21,
                    name: '2° Bimestre'
                },
                {
                    id: 22,
                    name: '3° Bimestre'
                },
                {
                    id: 23,
                    name: '4° Bimestre'
                }
            ],
            defaults: 20
        }
    },
    methods: {
        sendMessageToParent(value){
            this.$emit('messageFromChild', value)
        }
    }
}
</script>
