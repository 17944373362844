<template>
    <div class="load" v-if="isLoad">
        <v-container fill-height>
            <v-layout align-center justify-center>
                    <v-progress-circular
                    size="80"
                    color="blue"
                    indeterminate
                    ></v-progress-circular>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
export default {
    name:'progress-system',
    props:{
        isLoad:{
            type: Boolean,
            default: true
        }
    },
    data () {
        return{

        }
    }
}
</script>
<style>
.load{
    background: rgba(0,0,0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
</style>
