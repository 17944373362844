<template>
    <v-card v-if="show" height="408">
        <v-card-title class="align-start">
        <span style="font-size: 0.9em; font-weight: normal;">Atendimentos por CID-10</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                mdi-information-outline
            </v-icon>
            </template>
            <span>Aqui é possível visualizar os atendimentos de acordo com <br> a Classificação  Estatística Internacional de Doenças e Problemas <br> Relacionados à Saúde (CID -10) informados ao GS desde 2014.</span>
            <!-- <span>  Aqui é possivel ver os atendimentos por doenças <br> e por agravos a saúde do ano atual. <br> CID-10: Classificação Estatística Internacional de Doenças e <br> Problemas Relacionados à Saúde Informados ao GS desde 2014.</span> -->
        </v-tooltip>
        </v-card-title>
        <v-card-text max-height="350">
            <apexchart type="bar" height="320" :options="doencas.chartOptions" :series="doencas.series"></apexchart>
        </v-card-text>
    </v-card>
    <v-card v-else height="408">
        <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
            <cube-shadow class="text-center"></cube-shadow>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { CubeShadow } from 'vue-loading-spinner'
export default {
    props: {
        items: Array,
        show: Boolean,
    },
    components: {
        CubeShadow
    },
    data () {
		return {
            doencas: {
                responsive: [
                    {
                        breakpoint: 1000,
                        options: {
                            plotOptions: {
                                bar: {
                                    horizontal: false
                                }
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ],
                series: [{
                    name: 'Quantidade',
                    data: []
                }],
                chartOptions: {
                    colors: [
                        '#001C55',
                    ],
                    noData: {
                        align: "center",
                        text: "Carregando...",
                        verticalAlign: "middle"
                    },
                    chart: {
                        height: 200,
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: -40,
                        style: {
                            fontSize: '10px',
                            colors: ["#000000"]
                        }
                    },

                    xaxis: {
                        categories: [],
                        position: 'bottom',
                        labels: {
                            style:{
                                fontSize:'10px'
                            }
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                colorFrom: '#000000',
                                colorTo: '#A6E1FA',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return val;
                            }
                        }

                    },
                    title: {
                        text: '',
                        floating: true,
                        offsetY: 330,
                        align: 'center',
                        style: {
                        color: '#444'
                        }
                    }
                }
            },
            arrPatologia : []
        }
	},
    watch:{
        show(){
            if(this.show){
                for (var i = 0; i < this.items.length; i++) {
                     let string = this.items[i].Patologia;
                     this.arrPatologia = string.split(" ");

                    this.doencas.series[0].data.push(this.items[i].Quantidade)
                    
                    this.doencas.chartOptions.xaxis.categories.push(this.items[i].CodigoCID+" : "+this.arrPatologia[0])
                }

            }
            else
                this.doencas.series[0].data = []
        },   
    },
}
</script>
