// import AreaDiretor from '../views/direcao/AreaDiretor.vue'
import { permissionChecker } from '../utils/permission';
import CurriculoDocente from '../views/professor/CurriculoDocente'

export default [ 
    // {
    //     path: '/professor',
    //     name: 'areaprofessor',
    //     component: AreaProfessor,
    //     meta: {
    //         requiresAuth: true,
    //         checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|DIRETOR')
    //     }
    // },
    {
        path: '/professor/curriculodocente',
        name: '/curriculodocente',
        component: CurriculoDocente,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('DOCENTES|MODULO_GE_ACESSAR')
        }
    },
];
