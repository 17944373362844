<template>
  <div id="chart">
      <apexchart type="line" :height="$vuetify.breakpoint.lgAndUp ? '265' : '360'" ref="chart" :options="chartOptions" :series="series"></apexchart>
  </div>    
</template>

<script>
export default {
props: {
},
data() {
    return {
      obj: {
        name: '',
        data: []
      },
      obj1: {
        name: '',
        data: []
      },
      obj2: {
        name: '',
        data: []
      },
      obj3: {
        name: '',
        data: []
      },
      obj4: {
        name: '',
        data: []
      },
      obj5: {
        name: '',
        data: []
      },
      value: [],
      series: [],
      years: [],
      chartOptions: {
        chart: {
          height: 600,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: []
        }
      },   
    }
  },
  methods: {
    getByYears(){
      this.axios.get('turma/quantitativomatriculas')
      .then(res => {
        for(let i = 0; i < 20; i++){
          
          // 0 ~ 3
          if(i === 4){
            this.obj.name = 'Creche'
            // creche do ano atual - 4 anos
            this.obj.data[0] = res.data[0].Quantidade
            // pre escola do ano atual - 4 anos
            this.obj.data[1] = res.data[5].Quantidade
            // anos iniciais do ano atual - 4 anos
            this.obj.data[2] = res.data[10].Quantidade
            // anos finais do ano atual - 4 anos
            this.obj.data[3] = res.data[15].Quantidade
          }

          // 4 ~ 7
          if(i > 4 && i < 9){
            this.obj1.name = 'Pre Escola'
            // creche do ano atual - 3 anos
            this.obj1.data[0] = res.data[1].Quantidade
            // pre escola do ano atual - 3 anos
            this.obj1.data[1] = res.data[6].Quantidade
            // anos iniciais do ano atual - 3 anos
            this.obj1.data[2] = res.data[11].Quantidade
            // anos finais do ano atual - 3 anos
            this.obj1.data[3] = res.data[16].Quantidade
          }

          // 8 ~ 11
          if(i > 8 && i < 13){
            this.obj2.name = 'Anos Iniciais'
            // creche do ano atual - 2 anos
            this.obj2.data[0] = res.data[2].Quantidade
            // pre escola do ano atual - 2 anos
            this.obj2.data[1] = res.data[7].Quantidade
            // anos iniciais do ano atual - 2 anos
            this.obj2.data[2] = res.data[12].Quantidade
            // anos finais do ano atual - 2 anos
            this.obj2.data[3] = res.data[17].Quantidade
          }

          // 12 ~ 15
          if(i > 12 && i < 17){
            this.obj3.name = 'Anos Finais'
            // creche do ano atual - 1 anos
            this.obj3.data[0] = res.data[3].Quantidade
            // pre escola do ano atual - 1 anos
            this.obj3.data[1] = res.data[8].Quantidade
            // anos iniciais do ano atual - 1 anos
            this.obj3.data[2] = res.data[13].Quantidade
            // anos finais do ano atual - 1 anos
            this.obj3.data[3] = res.data[18].Quantidade
          }

          // 12 ~ 15
          if(i > 16 && i < 20){
            this.obj4.name = 'EJA'
            // creche do ano atual - 1 anos
            this.obj4.data[0] = res.data[4].Quantidade
            // pre escola do ano atual - 1 anos
            this.obj4.data[1] = res.data[9].Quantidade
            // anos iniciais do ano atual - 1 anos
            this.obj4.data[2] = res.data[14].Quantidade
            // anos finais do ano atual - 1 anos
            this.obj4.data[3] = res.data[19].Quantidade
          }
        }
        this.addSeries()            
      })
    },
    addSeries(){
      this.series.push(this.obj)
      this.series.push(this.obj1)
      this.series.push(this.obj2)
      this.series.push(this.obj3)
      this.series.push(this.obj4)
    },
    getYears(){
      let year = (new Date().getFullYear());
      Array.from({length: 4}, (v, k) =>  this.years.push(year - k));
      this.years.reverse();
      this.chartOptions.xaxis.categories = this.years
    }  
  },
  beforeMount(){
    this.getYears();
  },
  mounted() {
    this.getByYears();
    
  },
}
</script>
