<template>
    <div>
        <div class="container-fluid">
            <v-row>
                <v-dialog
                    v-model="modalVisualizar"
                    width="60%"
                    persistent
                    scrollable
                    :fullscreen="$vuetify.breakpoint.xsOnly"
                >
                    <v-card>
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Propagandas institucionais a serem exibidas nos painéis</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="red"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="cancelModal()"
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </div>
                        <v-spacer></v-spacer>
                        <video :key="videoKey" id="Video" width="100%" height="100%" preload="none" :muted=true autoplay controls repeat>
                            <source :src="videoDisplay" type="video/mp4">
                        </video>
                    </v-card>
                </v-dialog>
                
                <v-dialog
                    v-model="modalUpload"
                    width="60%"
                    persistent
                    scrollable
                    :fullscreen="$vuetify.breakpoint.xsOnly"
                >
                    <v-card >
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Adicionar vídeo</h6>
                        </div>
                        <v-row>
                        <v-text-field
                            class="m-3 pl-5"
                            label="Descrição"
                            v-model="descricaoVideo"
                            required
                        >
                        </v-text-field>
                        <input 
                        class="m-3" 
                        type="file"
                        @change="setVideo($event)"
                        >
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                dark
                                large
                                color="primary"
                                @click="cancelModal()"
                            >
                                Fechar
                            </v-btn>
                            <v-btn
                                dark
                                large
                                color="primary"
                                @click="uploadVideo()"
                            >
                                Inserir
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>

            <v-row class="m-3">
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                            PREFEITURA MUNICIPAL DE MACAÍBA - PROPAGANDAS INSTITUCIONAIS
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">TABELA DE SELEÇÃO DE PROPAGANDAS INSTITUCIONAIS</h5>
                    </v-card>
                </v-col>
                <v-col>
                    <v-btn
                        dark
                        large
                        color="primary"
                        @click="abrirModalUpload()"
                    >
                        Adicionar vídeo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="m-3"> 
                <v-col>
                    <v-card shaped>

                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Vídeos a serem exibidos nos painéis</h6>
                        </div>
                        <v-data-table :items="listaVideos" :headers="headers">
                            <template v-slot:[`item.Actions`]="{ item }">

                                <v-tooltip  bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="mr-3"
                                            v-bind="attrs" 
                                            v-on="on" 
                                            icon
                                            @click="abirModalVisualizarVideo(item)"
                                            color="primary">
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span> Visualizar </span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            icon
                                            @click="deletarItem(item)"
                                            color="red">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span> Deletar vídeo</span>
                                </v-tooltip>

                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                videoKey:0,
                descricaoVideo: null,
                modalUpload: false,
                modalVisualizar: false,
                videoDisplay: null,
                file: null,
                listaVideos: [],
                headers:[
                        {text:'Descrição', value:'descricao', width:"85%", align:"left"},
                        {text:'Ações', value:'Actions', width:"15%", align:"center"},
                    ],
                }
        },
        methods: {
            async getVideos(){
                const { data } = await this.axios.get('videos/index')
                this.listaVideos = data
            },
            deletarItem(item){
                this.axios.delete('videos/delete',{
                    params: {
                        'key': item.key
                    }
                }).then(res => {
                    res.data;
                    this.getVideos()
                })
            },
            setVideo(event)
            {
                this.file = event.target.files[0];
            },
            uploadVideo()
            {
                const fd = new FormData();

                fd.append('video', this.file);
                fd.append('descricao', this.descricaoVideo);

                this.axios.post('videos/create', fd)
                .then(res => {
                    console.log(res.data)
                    this.cancelModal()
                    this.getVideos()
                })
            },
            abirModalVisualizarVideo(item){
                this.modalVisualizar = true
                this.videoDisplay = "http://s3.us-west-2.amazonaws.com/portal.macaiba.dti.systems/videos/"+item.key
                this.videoKey++
            },
            abrirModalUpload(){
                this.modalUpload = true
            },
            cancelModal(){
                this.videoDisplay = null
                this.modalUpload = false
                this.modalVisualizar = false
                this.getVideos()
            }
        }, 
        mounted(){
            this.getVideos()
        }
    }
</script>
<style>
</style>