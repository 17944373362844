<template>
    <v-data-table
        :items-per-page="50"
        :headers="headersOcupacao"
        :items="informacoesOcupacao"
        item-key="Nome"
        class="table-rounded"
        hide-default-footer
        disable-sort>

        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="
                            modalData(item)
                        "
                        >
                            <v-icon color="primary">mdi-account-group</v-icon>
                        <span></span>
                    </v-btn>
                </template>
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        dados: Array
    },
    data() {
        return { 
            headersOcupacao: [
                { text: 'Ano', value: 'Serie', width:'20%' },
                { text: 'Turno', value: 'Turno', width:'20%'  },
                { text: 'Etapa', value: 'etapa', width:'10%'},
                { text: 'Quantidade de alunos', value: 'QtdAlunos', width:'20%', align:'center'},
                { text: 'Quantidade de turmas', value: 'QtdTurmas', width:'10%', align:'center'},
                { text: 'Ver Turmas', value:'actions',  width:'10%', align:'center'},
            ],
            informacoesOcupacao: this.$props.dados
        }       
    },
    methods: {
        modalData(value){
            console.log('tabela->modal', value)
            this.$emit('abrirPopUp', value)
        }
    }
}
</script>
