<template>
    <v-card v-if="show" :height="height">
        <v-card-title class="align-start">
            <span style="font-size: 0.9em; font-weight: normal;">Atendimentos nos últimos 30 dias</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="windowWidth >= 1600">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-information-outline
                    </v-icon>
                </template>
                <span>Confira o total e a média de atendimentos <br> para os últimos 30 dias</span>
            </v-tooltip>
        </v-card-title>

        <v-autocomplete
            v-if="filtering"
            :items="especialidades"
            v-model="idEspecialidade"
            item-text="Especialidade"
            item-value="Id"
            @change="updateEspecialidade"
            label="Filtrar pela especialidade"
            hide-details
            outlined
            dense
            clearable
            class="px-3"
        />

        <v-card-text>
            <apexchart type="line" :height="height - (filtering ? 130 : 70)" :options="dias.chartOptions" :series="dias.series"/>
        </v-card-text>
    </v-card>
    <v-card v-else :height="height">
        <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
            <rotate-square4 class="text-center"></rotate-square4>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
import moment from 'moment'
import { resizeMixin } from '../../mixins/resize'
import { RotateSquare4 } from 'vue-loading-spinner'
export default {
    props: {
        items: Array,
        show: Boolean,
        line: Number,
        height: {
            type: Number,
            default: 280
        },
        filtering: {
            type: Boolean,
            default: false
        },
        especialidades: Array
    },
    mixins: [resizeMixin],
    components: {
        RotateSquare4
    },
    data () {
		return {
            idEspecialidade: null,

            dias: {
                series: [{
                    name: 'Atendimentos no dia',
                    type: 'area',
                    data: []
                },{
                    name: 'Média de atendimentos',
                    type: 'line',
                    data: []
                }],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        type: 'line',
                        stacked: false,
                    },
                    colors: [
                        '#0E6BA8',
                        '#A6E1FA',
                    ],
                    markers: {
                        size: 0
                    },
                    noData: {
                        align: "center",
                        text: "Sem dados disponíveis",
                        verticalAlign: "middle"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 4],
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'date',
                        categories: [],
                        legend: {
                            show: false
                        },
                        labels: {
                            show: false,
                            formatter: val => val + ' - ' + moment(val, "L").format('dddd')
                        },
                        tooltip: {
                            enabled: false
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false
                        }
                    }
                }
            },
        }
	},
    methods: {
        updateEspecialidade(event) {
            this.$emit('updatedEspecialidade', event)
        }
    },
    watch: {
        show(show) {
            if(show) {
                this.items.forEach(dia => {
                    this.dias.series[0].data.push(dia.Quantidade)
                    this.dias.series[1].data.push(this.line)
                    this.dias.chartOptions.xaxis.categories.push(dia.Data)
                })
            } else {
                this.dias.series[0].data = []
                this.dias.series[1].data = []
                this.dias.chartOptions.xaxis.categories = []
            }
                 
        }
    }
}
</script>
