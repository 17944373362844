<template>
    <v-card v-if="show" height="408">
        <v-card-title class="align-start">
            <span style="font-size: 0.9em; font-weight: normal;">
                Comparativo de presença diária dos atendimentos
            </span>
        </v-card-title>

        <v-autocomplete
            :items="especialidades"
            v-model="idEspecialidade"
            item-text="Especialidade"
            item-value="Id"
            @change="$emit('updatedEspecialidade', idEspecialidade)"
            label="Filtrar pela especialidade"
            hide-details
            outlined
            dense
            clearable
            class="px-3"
        />
        <apexchart
            height="285"
            type="donut"
            :series="series"
            :options="chartOptions"
        />
    </v-card>
    
    <v-card v-else height="408">
        <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <cube-shadow class="text-center"/>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { CubeShadow } from 'vue-loading-spinner'
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        especialidades: {
            type: Array,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    components: {
        CubeShadow
    },
    data () {
		return {
            idEspecialidade: null,

            series: [],
            chartOptions: {
                chart: {
                    type: 'donut'
                },
                labels: ['Atendidos', 'Ausentes'],
                noData: {
                    align: "center",
                    text: "Sem dados disponíveis",
                },
                colors: ['#001C55', '#0E6BA8'],
                legend: {
                    position: 'bottom',
                    show: false
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#FFFFFF"]
                    }
                },
            }
        }
	},
    watch: {
        show(show) {
            if(show && this.items.length) {
                this.series = this.items
                this.chartOptions.legend.show = true
            } else 
                this.series = []
        }
    }
}
</script>
