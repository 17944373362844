<template>
        <v-card height="408" max-height="408" style="overflow-y:auto" v-if="show">
            <v-card-title class="pb-0">
                <span style="font-size: 0.9em; font-weight: normal;">
                    Registro mensal de procedimento realizados
                </span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                        mdi-information-outline
                        </v-icon>
                    </template>
                    <span>
                        Acompanhe o ranking mensal de atendimentos<br>
                        por procedimento dentro do município.
                    </span>
                </v-tooltip>
            </v-card-title>

            <v-autocomplete
                :items="gruposProcedimento"
                v-model="idGrupoProcedimento"
                item-text="GrupoProcedimento"
                item-value="Id"
                label="Filtrar por grupo de procedimento"
                @change="$emit('updatedGrupoProcedimento', idGrupoProcedimento)"
                hide-details
                outlined
                dense
                clearable
                class="mx-3 my-1"
            />

            <v-simple-table style="table-layout:fixed;" width="100%">
                <template v-slot:default max-height="400">
                    <thead >
                        <tr>
                            <th width="50%" class="text-left">
                                Procedimento
                            </th>
                            <th width="20%" class="text-left">
                                Atendimentos
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.especialidade"
                        >
                            <td>{{ item.Procedimento }}</td>
                            <td align="center">{{ item.QtdAtendimento }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-card height="408" v-else>
            <v-container fill-height fluid>
                <v-row align="center" justify="center">
                    <diamond/>
                </v-row>
            </v-container>
        </v-card>
</template>

<script>
import {Diamond} from 'vue-loading-spinner'

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        gruposProcedimento: {
            type: Array,
            required: true
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        idGrupoProcedimento: null
    }),
    components: {
        Diamond
    }
} 
</script>