<template>
    <div>
        <v-toolbar
            dense
            color="primary"
            dark
            extended
            extension-height="300"
            flat
        >
            <v-toolbar-title class="mx-auto pb-5" slot="extension">
            </v-toolbar-title>
        </v-toolbar>
        <v-container fill-height fluid>

            <v-row align="center" justify="center">
                <v-flex xs12 sm4 md3 lg3 xl2 class="ma-3 mb-0">
                    <v-text-field
                        v-model="code"
                        label="Código"
                        placeholder="Código de validação"
                        clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg3 xl2 class="ma-3 mt-0">
                    <v-btn
                        @click="validaAssinatura(code)"
                        color="primary"
                        large
                        block
                        >Validar Assinatura</v-btn
                    >
                </v-flex>
            </v-row>

            <progress-system :isLoad="progress"></progress-system>
            <system-snackbar :snackbar="snackbar"></system-snackbar>
        </v-container>
    </div>
</template>

<script>
import SystemSnackbar from './../components/SystemSnackbar'
import ProgressSystem from '../components/ProgressSystem'

export default {
    name: 'ValidarRequerimento',
    components:{
        SystemSnackbar,
        ProgressSystem
    },
    data: () => ({
        progress: false,
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        code: ''
    }),
    methods: {
        validaAssinatura(code){
            this.progress = true;
            this.axios.get(`requerimentos/validar/${code}`)
            .then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "Assinatura validada com sucesso!",
                    color: 'green'
                }
                this.progress = false;
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data,
                    color: 'red'
                }
                this.progress = false;
            })
        },
    },
    mounted() {
        this.code = this.$route.query.code;
    }
}
</script>