<template>
    <v-card v-if="show" height="408">
        <v-card-title class="align-start">
            <span style="font-size: 0.9em; font-weight: normal;">Atendimentos por turno</span>
        </v-card-title>
        <v-card-text>
            <apexchart type="donut" height="350" :options="chartOptions" :series="series"></apexchart>
        </v-card-text>
    </v-card>
    <v-card v-else height="408">
        <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
            <cube-shadow class="text-center"></cube-shadow>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { CubeShadow } from 'vue-loading-spinner'
export default {
    props: {
        items: Array,
        show: Boolean,
    },
    components: {
        CubeShadow
    },
    data () {
		return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut'
                },
                labels: ['Manhã', 'Tarde'],
                noData: {
                    align: "center",
                    text: "Sem dados disponíveis",
                },
                colors: ['#001C55', '#0E6BA8', '#808080'],
                legend: {
                    position: 'bottom',
                    show: false
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#FFFFFF"]
                    }
                },
            }
        }
	},
    watch: {
        show(show) {
            this.series = []
            if(show && this.items.length > 0) {
                this.items.forEach(turno => {
                    this.series.push(turno.Quantidade)
                })
                this.chartOptions.legend.show = true
            }
            else
                this.chartOptions.legend.show = false
        }
    }
}
</script>
