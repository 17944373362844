import SaudeExames from '../views/saude/SaudeExames.vue';
import SaudeAtestados from '../views/saude/SaudeAtestados.vue';
import SaudeDashboard from '../views/saude/SaudeDashboard.vue';
import SaudeDispensacao from '../views/saude/SaudeDispensacao.vue';
import SaudeAtendimento from '../views/saude/SaudeAtendimento.vue';
import SaudeMateriais from "@/views/saude/SaudeMateriais";
import SaudeMinisterio from "@/views/saude/SaudeMinisterio";
import SaudeMonitor from "../views/saude/SaudeMonitor";
import SaudeMonitorAmbulatorial from '../views/saude/SaudeMonitorAmbulatorial';
import SaudeMonitorHospitalar from "../views/saude/SaudeMonitorHospitalar";
import SaudeIndicador from "../views/saude/SaudeIndicadores";
import SaudeProducaoMedicos from '../views/saude/SaudeProducaoMedicos.vue';
import SaudeProducaoEnfermeiros from '../views/saude/SaudeProducaoEnfermeiros.vue';
import SaudePainel from '../views/saude/SaudePainel.vue';
import SaudePainelVideos from '../views/saude/SaudePainelVideos.vue';
import SaudePainelAmbulatorial from '../views/saude/SaudePainelAmbulatorial.vue';
import SaudePainelAssistencia from '../views/saude/SaudePainelAssistencia.vue';
import SaudeProducaoBPA from '../views/saude/SaudeProducaoBPA.vue';
import { permissionChecker } from '../utils/permission';

export default [{
    path: '/saude/exames',
    name: 'Exames',
    component: SaudeExames,
    meta: {
        requiresAuth: true,
    }
},{
    path: '/saude/dashboard',
    name: 'saudeepidemiologico',
    component: SaudeDashboard,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PAINEL_EPIDEMIOLOGICO')
    }
},{
    path: '/saude/dispensacao',
    name: 'Dispensacao',
    component: SaudeDispensacao,
    meta: {
        requiresAuth: true,
    }
},{
    path: '/saude/atendimento',
    name: 'Atendimentos',
    component: SaudeAtendimento,
    meta: {
        requiresAuth: true,
    }
},{
    path: '/saude/materiais',
    name: 'Materiais',
    component: SaudeMateriais,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_MATERIAIS|MATERIAS')
    }
},{
    path: '/saude/MinisterioSaude',
    name: 'Ministerio da saúde',
    component: SaudeMinisterio,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_MATERIAIS|MATERIAS')
    }
},{
    path: '/saude/monitor',
    name: 'Monitor',
    component: SaudeMonitor,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PAINEL_EMERGENCIAL')
    }
},{
    path: '/saude/saudeMonitorHospitalar',
    name: 'saudeMonitorHospitalar',
    component: SaudeMonitorHospitalar,
    meta: {
        requiresAuth: true,
    }
},{
    path: '/saude/atestado',
    name: 'Atestados',
    component: SaudeAtestados,
    meta: {
        requiresAuth: true,
    }
},{
    path: '/saude/indicadores',
    name: 'Indicadores',
    component: SaudeIndicador,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|ATENCAO_PRIMARIA')
    }
},{
    path: '/saude/medicos',
    name: 'medicos',
    component: SaudeProducaoMedicos,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PRODUCAO_MEDICO')
    }
},{
    path: '/saude/enfermeiros',
    name: 'enfermeiros',
    component: SaudeProducaoEnfermeiros,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PRODUCAO_ENFERMAGEM')
    }
},{
    path: '/saude/ambulatorial',
    name: 'ambulatorial',
    component: SaudeMonitorAmbulatorial,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PAINEL_AMBULATORIAL')
    }
},{
    path: '/saude/producaoBPA',
    name: 'produção BPA',
    component: SaudeProducaoBPA,
    meta: {
        requiresAuth: true,
        checker: () => permissionChecker.hasAll('PORTAL_SAUDE|PRODUCAO_BPA')
    }
},{
    path: '/saude/painel',
    name: 'painel',
    component: SaudePainel,
},{
    path: '/saude/painelAssistencia',
    name: 'painel assinstencia',
    component: SaudePainelAssistencia,
},{
    path: '/saude/painelVideos',
    name: 'painel de videos',
    component: SaudePainelVideos,
},{
    path: '/saude/painelAmbulatorial',
    name: 'painel Ambulatorial',
    component: SaudePainelAmbulatorial,
}
];