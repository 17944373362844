<template>
  <div class="page">
    <progress-system :isLoad="progress"></progress-system>
    <div class="container-fluid">
      <br>
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Dados Financeiros</h1>
      </div>
      <v-row>
        <v-col cols="2">
          <v-select
              v-model="IdMesSelecionado"
              :items="periodosList"
              item-text="Mes"
              item-value="IdMes"
              label="Mês"
              @change="[listaFinancias = [],dadosFinanceiros($event)]"
              hide-details
              outlined
              dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
              v-model="IdAnoSelecionado"
              :items="anos"
              item-text="Ano"
              item-value="Ano"
              label="Ano"
              @change="[listaFinancias = [],dadosFinanceiros($event)]"
              hide-details
              outlined
              dense
          ></v-select>
        </v-col>
        <v-col cols="2" v-if="IdMesSelecionado != null || IdAnoSelecionado != null">
          <v-btn color="primary" width="100%" @click="[limparFiltros()]"
                 dark>
            Resetar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['Matricula']"
              color="primary"
              label="Matricula"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['TipoVinculo']"
              color="primary"
              label="Tipo de Vínculo"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['TipoPrevidencia']"
              color="primary"
              label="Tipo de Previdência"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['CentroCusto']"
              color="primary"
              label="Centro de Custo"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['DataPosse']"
              color="primary"
              label="Admissão"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['Referencia']"
              color="primary"
              label="Ref.:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
              v-model="listaFinancias[0]['numJornada']"
              color="primary"
              label="CH:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="dynamicCol">
          <v-text-field
            v-model="listaFinancias[0]['Orgao']"
            color="primary"
            label="Orgão:"
            outlined
            readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['Setor']"
              color="primary"
              label="Setor:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field
              v-model="listaFinancias[0]['Cargo']"
              color="primary"
              label="Cargo:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
          :headers="headers"
          :items="listaFinancias"
          :items-per-page="10"
          class="elevation-1"
      >
        <template v-slot:[`item.VantagemDesconto`] = "{ item }">
          <span v-if="item.VantagemDesconto == 'V'"> Vantagem </span>
          <span v-else> Desconto </span>
        </template>

        <template v-slot:[`item.vlrRubrica`]=" { item } ">
          R$ {{formatPrice(item.vlrRubrica)}}
        </template>

      </v-data-table>
      <v-row>
        <v-col :cols="dynamicCol">
          <v-text-field class="vantagem"
              v-model="listaFinancias[0]['vantagens']"
              color="primary"
              label="Total de Vantagens:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field class="desconto"
              v-model="listaFinancias[0]['desvantagens']"
              color="primary"
              label="Total de Descontos:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="dynamicCol">
          <v-text-field class="vantagem"
              v-model="listaFinancias[0]['valorTotal']"
              color="primary"
              label="Valor Líquido:"
              outlined
              readonly>
          </v-text-field>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

import ProgressSystem from '../../components/ProgressSystem'
import moment from 'moment'

export default {
  name:'Dispensacao',
  components:{
    ProgressSystem
  },
  data: () => ({
    progress:true,
    listaFinancias:[],
    headers:[
      {text:'Tipo',value:'VantagemDesconto', align:'left'},
      {text:'Descrição',value:'DescricaoRubrica', align:'left'},
      {text:'Parâmetro',value:'VPARAM', align:'right'},
      {text:'Valor',value:'vlrRubrica', align:'right'},
    ],
    IdMesSelecionado: null,
    IdAnoSelecionado: null,
    anos:[],
    periodosList: [
      {
        IdMes: 1,
        Mes: "Janeiro",
      },
      {
        IdMes: 2,
        Mes: "Fevereiro",
      },
      {
        IdMes: 3,
        Mes: "Março",
      },
      {
        IdMes: 4,
        Mes: "Abril",
      },
      {
        IdMes: 5,
        Mes: "Maio",
      },
      {
        IdMes: 6,
        Mes: "Junho",
      },
      {
        IdMes: 7,
        Mes: "Julho",
      },
      {
        IdMes: 8,
        Mes: "Agosto",
      },
      {
        IdMes: 9,
        Mes: "Setembro",
      },
      {
        IdMes: 10,
        Mes: "Outubro",
      },
      {
        IdMes: 11,
        Mes: "Novembro",
      },
      {
        IdMes: 12,
        Mes: "Dezembro",
      },
    ],
  }),
  methods:{
    dadosFinanceiros(){
      this.axios.get('dadosfinanceiros', {
        params:{
          Mes: this.IdMesSelecionado,
          Ano: this.IdAnoSelecionado
        }
      }).then(res =>{
        this.listaFinancias = res.data;
        if(this.IdMesSelecionado==null) {
          this.IdMesSelecionado = res.data[0].Mes
          this.IdAnoSelecionado = res.data[0].Ano
        }
        this.listaFinancias[0]['vantagens'] = this.formatPrice(res.data[0].vantagens)
        this.listaFinancias[0]['desvantagens'] = this.formatPrice(res.data[0].desvantagens)
        this.listaFinancias[0]['valorTotal'] = this.formatPrice(res.data[0].valorTotal)
      }).catch( e => {
        console.log(e.response);
      }).finally(()=>{
        this.progress = false;
      })
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getAnos(){
      for(var a = 2014; a <= moment().format('Y'); a++){
        this.anos.push(a);
      }
    },
    limparFiltros(){
      this.listaFinancias = [];
      this.IdAnoSelecionado = null;
      this.IdMesSelecionado = null;
      this.dadosFinanceiros();
    },
  },
  mounted(){
    this.dadosFinanceiros();
    this.getAnos();
  }


}
</script>

<style>
.vantagem input{
  color: blue !important;
  text-align: right;
}
.desconto input{
  color: darkred !important;
  text-align: right;
}
</style>