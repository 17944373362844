<template>
    <v-container fluid>
        <br />
        <v-row class="mb-2">
            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                       EDUCACENSO
                    </h4>
                    <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> Gerenciar integrações com o Educacenso </h5>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
                <v-autocomplete
                    v-model="IdUnidade"
                    :items="unidadesList"
                    item-text="Unidade"
                    item-value="IdUnidade"
                    label="Selecione a unidade"
                    hide-details
                    :disabled="isDisabledAdmin"
                    outlined
                    dense
                ></v-autocomplete>
            </v-col>                
        </v-row>
        <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
                <v-select
                v-model="selectedOption"
                :items="options"
                label="Selecione a opção"
                hide-details
                :disabled="isDisabledAdmin"
                outlined
                dense
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6" v-if="selectedOption === 'matriculas' && IdUnidade > 0" class="action-button">
                <v-btn color="primary" style="font-size: 20px; padding: 30px 20px;" @click="getMatriculas">Download Matrículas</v-btn>
            </v-col>
            <v-col cols="6" v-if="selectedOption === 'identificacao' && IdUnidade > 0" class="action-button">
                <v-btn color="primary" style="font-size: 20px; padding: 30px 20px;" @click="getIdentificacao">Download Identificação</v-btn>
            </v-col>
            <v-col cols="6" v-if="selectedOption === 'importar' && IdUnidade > 0" class="action-button">
                <v-row>
                    <v-col cols="6">
                        <input type="file" accept=".txt" @change="handleFileUpload">
                    </v-col>
                </v-row>
                <v-row class="row-spacing">
                    <v-col cols="6">
                        <v-btn color="primary" style="margin-top: 20px; font-size: 16px; padding: 30px 20px;" @click="uploadEducacenso">Upload Educacenso (resultado certo)</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <system-snackbar :snackbar="snackbar"></system-snackbar>
    </v-container>
</template>

<script>

import SystemSnackbar from '../../components/SystemSnackbar'
// import ProgressSystem from '../components/ProgressSystem'
export default {
    components: {

    SystemSnackbar,
  },
    data () {
        
        return {
            snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
            },
            loading: false,
            selectedOption: '',
            unidadesList: [],
            IdUnidade: 0,
            file: null,
            options: [
                { text: '1 - Exportar identificação dos alunos', value: 'identificacao' },
                { text: '2 - Importar Educacenso (resultado certo)', value: 'importar' },
                { text: '3 - Exportar turmas e matrículas', value: 'matriculas' },
            ],
        }
    },
    computed: {
        isDisabledAdmin() {
            return this.acesso
        }
    },
    methods: {
        getUnidades(){
            this.axios.get('escola/user/escolas').then(res => {
                this.unidadesList = res.data.filter(unidade => {
                unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
                if(unidade.IdUnidadeTipo === 1) {
                    return unidade;
                }
                })
            })
        },
        getMatriculas() {
    const IdUnidade = this.IdUnidade;
    this.axios.get(`educacenso/exportarMatriculas`, {
        params: { IdUnidade },
        responseType: 'blob'
    }).then((res) => {
        // Verifique se o cabeçalho 'Content-Disposition' está presente
        const contentDisposition = res.headers['content-disposition'];
        console.log('Content-Disposition:', contentDisposition); // Adiciona log do cabeçalho

        let filename = 'downloaded_file.txt'; // Valor padrão caso não consiga obter o nome do arquivo

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) { 
                filename = matches[1].replace(/['"]/g, '');
            }
        } else {
            console.log('Cabeçalho Content-Disposition não encontrado ou não contém "attachment".');
        }

        console.log('Filename:', filename); // Adiciona log do nome do arquivo

        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/plain' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch((error) => {
        console.error("Erro ao baixar o arquivo:", error);
    });
},
        getIdentificacao(){
            const IdUnidade = this.IdUnidade
            this.axios.get(`educacenso/exportarIdentificacao`, {
                params: { IdUnidade },
                responseType: 'blob'
            }).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/plain' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "");
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                console.error("Erro ao baixar o arquivo:", error);
            }).finally(() => {

            });
        },
        uploadEducacenso() {
            if (!this.file) {
                alert("Por favor, selecione um arquivo primeiro.");
                return;
            }

            this.loading = true;
            let formData = new FormData();
            formData.append('file', this.file);

            this.axios.post('educacenso/upload-educacenso', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.snackbar = {
                ...this.snackbar,
                show: true,
                text: res.data,
                color: 'green'
                }
            }).catch((e) => {
                this.snackbar = {
                ...this.snackbar,
                show: true,
                text: e.response.data,
                color: 'red',
                }
            }).finally(() => {
                this.loading = false;
                this.file = null;
            });
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
    },
    mounted() {
        this.getUnidades()
        this.IdUnidade = this.unidadesList[0]
    }
}
</script>
<style>

.custom-radio .v-input--selection-controls__input {
    transform: scale(2);
}

.custom-radio .v-label {
    font-size: 30px;
}

.d-sm-flex {
    text-align: center;
    width: 100%;
}

.d-sm-flex h1 {
    margin: 0 auto;
}

input[type="file"] {
    font-size: 20px;
}

.action-button {
    margin-top: 20px;
}
</style>

