import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import VueMask from 'v-mask'
import { LMap, LTileLayer, LMarker, LCircleMarker, LGeoJson, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Vuex from "vuex";
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import VueScrollTo from 'vue-scrollto'
import VueParticles from 'vue-particles'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-geo-json', LGeoJson)
Vue.component('l-popup', LPopup)
Vue.component('l-circle-marker', LCircleMarker)

// Vue.use(require('vue-moment'));

const moment = require('moment')
require('moment/locale/pt')

Vue.use(require('vue-moment'), {
    moment
})
Vue.use(VueParticles)
Vue.use(VueApexCharts)
Vue.use(Vuex);
Vue.use(VueMask)
Vue.use(VueScrollTo)
Vue.component('apexchart', VueApexCharts)


Vue.mixin({
  methods:{
    prepareNameFilter: function replaceSpecialChars(str)	{
      if (!str) return '';
      str = str.replace(/[áÁàÀâÂäÄãÃåÅæÆ]/g, 'A');
      str = str.replace(/[çÇ]/g, 'C');
      str = str.replace(/[éÉèÈêÊëË]/g, 'E');
      str = str.replace(/[íÍìÌîÎïÏîĩĨĬĭ]/g, 'I');
      str = str.replace(/[óÓòÒôÔöÖœŒ]/g, 'O');
      str = str.replace(/[úÚùÙûÛüÜ]/g, 'U');
      return str;
    },
  }
})

Vue.use(vueNumeralFilterInstaller, { locale: 'pt-br' })

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
