<template>
    <v-row class="m-2">
        <v-col>
            <v-menu
            v-model="menuStart"
            :close-on-content-click="false"
            max-width="290"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                :value="computedDateFormattedStart"
                label="Data inicial"
                readonly
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="mutationStart"
                @change="menuStart = false"
            ></v-date-picker>
            </v-menu>
        </v-col>

        <v-col>
            <v-menu
            v-model="menuFinish"
            :close-on-content-click="false"
            max-width="290"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                :value="computedDateFormattedFinish"
                label="Data final"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="!mutationStart"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="mutationFinish"
                @change="menuFinish = false"
            ></v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
  </template>

<script>
import moment from 'moment'

  export default {
    data() {
      return {
        mutationStart: this.dateStart,
        mutationFinish: this.dateFinish,
        menuStart: null,
        menuFinish: null
      }
    },
    props: {
      dateStart: String,
      dateFinish: String
    },
    watch: {
      mutationStart() {
        this.$emit('date:start', this.mutationStart)
      },
      mutationFinish() {
        this.$emit('date:finish', this.mutationFinish)
      }
    },
    computed: {
      computedDateFormattedStart() {
        return moment(this.mutationStart).format('DD/MM/YYYY')
      },
      computedDateFormattedFinish() {
        return moment(this.mutationFinish).format('DD/MM/YYYY')
      }
    }
  }
</script>