<template>
  <v-container>
    <v-row class="justify-center pt-2 mt-10">
      <v-card width="95%" class="primary rounded">
        <v-card-title class="primary" style="color: white"> Minhas turmas </v-card-title>
        <v-data-table
            :headers="headersTurmas"
            :items="turmasList"
            class="elevation-1"
            :items-per-page="10"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    left
                    v-scroll-to="'#element'"
                    @click="[
                        boletimon = true,

                        getBoletim(item.IdMatricula),

                        getNome(item.Turma)
                        ]">
                        
                  <v-icon color="primary">
                    mdi-table-search
                  </v-icon>
                </v-btn>
              </template>
              <span> Ver boletim </span>
            </v-tooltip>
          </template>

        </v-data-table>
      </v-card>
    </v-row>

    <SectionOne id="element">
      <v-row class="justify-center pt-6">
        <v-alert v-if="boletimon==false"
            width="95%"
            class="black--text"
            color="amber lighten-2"
            dark
            dense
            border="left"
        >
          <v-icon color="black"> mdi-school-outline </v-icon>
          <span class="pl-2"> Por favor, selecione ao menos uma <strong> turma </strong> para visualizar seu boletim. </span>
        </v-alert>
        <v-card width="95%" class="primary rounded" v-if="boletimon==true">
          <v-card-title class="primary" style="color: white"> Boletim - {{ this.serienome }}
            <v-icon
            @click=[fecharModal()]
            color="white"
            class="ml-auto"
            >
              mdi-close-circle-outline
            </v-icon>

          </v-card-title>
    
          <v-data-table
              :headers="_headers"
              :items="disciplinasLista"
              class="elevation-1"
              style="width: 100%"
              items-per-page= "20"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-arrow-left',
                nextIcon: 'mdi-arrow-right'
              }"
          >
            <template v-slot:[`item.Nota1`] = "{ item }">
              <span class="red--text" v-if="item.Nota1 < 6"> {{ item.Nota1 }} </span>
              <span v-else> {{ item.Nota1 }} </span>
            </template>
            <template v-slot:[`item.Nota2`] = "{ item }">
              <span class="red--text" v-if="item.Nota1 < 6"> {{ item.Nota2 }} </span>
              <span v-else> {{ item.Nota2 }} </span>
            </template>
            <template v-slot:[`item.Nota3`] = "{ item }">
              <span class="red--text" v-if="item.Nota3 < 6"> {{ item.Nota3 }} </span>
              <span v-else> {{ item.Nota3 }} </span>
            </template>
            <template v-slot:[`item.Nota4`] = "{ item }">
              <span class="red--text" v-if="item.Nota4 < 6"> {{ item.Nota4 }} </span>
              <span v-else> {{ item.Nota4 }} </span>
            </template>
            <template v-slot:[`item.Ma`] = "{ item }">
              <span class="red--text" v-if="item.Ma < 6"> {{ item.Ma }} </span>
              <span v-else> {{ item.Ma }} </span>
            </template>

            <template v-slot:[`item.s`] = "{ item }">
              <v-chip v-if="item.Ma>=6" color="green" dark> APROVADO </v-chip>
              <v-chip v-else-if="item.Ma < 6 && item.r == null" color="orange" dark> RECUPERAÇÃO </v-chip>
              <v-chip v-else color="red" dark> REPROVADO </v-chip>
            </template>

          </v-data-table>
        </v-card>
      </v-row>
    </SectionOne>

    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        class="mx-2"
        fab
        dark
        fixed
        bottom
        small
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

  </v-container>
</template>

<script>
export default {
  name: 'AlunoBoletim',
  components: {
    //
  },
  data: () => ({
    fab: false,
    serienome: "",
    turmasList: [],
    disciplinasLista:[],
    frequenciaLista: [],
    boletimon:false,
    headersTurmas: [
      { text: 'Ano Letivo', value: 'AnoLetivo', align: 'center', width: '15%'},
      { text: 'Turma', value: 'Turma', align: 'left', width: '25%'},
      { text: 'Escola', value: 'Unidade', align: 'left', width: '50%'},
      { text: 'Ações', value: 'actions', align: 'left', width: '10%'},

    ],
    headers: [
      { text: 'Disciplina', value: 'Disciplina', align: 'center', show: true, width: '15%'},
      { text: '1º Bim', value: 'Nota1', show: true, align: 'center', width: '8%'},
      { text: 'Freq', value: 'frequencia1', show: true, align: 'center', width: '7%'},
      { text: '2º Bim', value: 'Nota2', show: true, align: 'center', width: '8%' },
      { text: 'Freq', value: 'frequencia2', show: true, align: 'center', width: '7%'},
      { text: '3º Bim', value: 'Nota3', show: true, align: 'center', width: '8%' },
      { text: 'Freq', value: 'frequencia3', show: true, align: 'center', width: '7%'},
      { text: '4º Bim', value: 'Nota4', show: true, align: 'center', width: '8%' },
      { text: 'Freq', value: 'frequencia4', show: true, align: 'center', width: '7%'},
      { text: 'MA', value: 'Ma', show: true, align: 'center', width: '8%' },
      { text: 'Recuperação', value: 'r', show: true, align: 'center', width: '10%' },
      { text: 'MF', value: 't', show: true, align: 'center', width: '7%' },
      { text: 'Status', value: 's', show: true, align: 'center', width: '5%' }
    ],
  }),
  computed : {
    _headers(){
      return this.headers.filter(x=>x.show)
    }
  },
  methods: {
    getNome(item){
        this.serienome = item
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    getTurmas(){
      this.axios.get('turma/aluno').then((res) => {
        this.turmasList = res.data.map(turma => {
          return {
            IdTurma: turma.IdTurma,
            Turma: turma.Serie+'-'+turma.TurmaDescricao ,
            AnoLetivo: turma.AnoLetivo,
            Unidade: turma.Unidade,
            IdMatricula: turma.IdMatricula,
          }
        })
      })
    },
    getBoletim(IdMatricula) {
      this.axios.get('aluno/turma/boletim', {
        params: {
          IdTurma: IdMatricula,
        }
      }).then((res) => {
        
        this.disciplinasLista = res.data.map(disciplina => {
          return {
            Disciplina: disciplina.Disciplina,
            Faltas: disciplina.Faltas,
            Divisao: disciplina.Divisao,
            Nota1: disciplina.nota1,
            Nota2: disciplina.nota2,
            Nota3: disciplina.nota3,
            Nota4: disciplina.nota4,
            frequencia1: disciplina.frequencia1,
            frequencia2: disciplina.frequencia2,
            frequencia3: disciplina.frequencia3,
            frequencia4: disciplina.frequencia4,
            Ma: disciplina.Ma,
          }
        })
      })
    },
    fecharModal(){
      this.boletimon = false
    }
  },
  mounted() {
    this.getTurmas()
  }
};
</script>
<style scoped>
</style>