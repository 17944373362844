export const resizeMixin = 
{
    data () {
        return {
            windowWidth: (window.innerWidth)
        }
    },
    watch: {
        windowWidth(newWidth) {
            this.windowWidth = newWidth
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },      
    },
    mounted() {
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize)
        })   
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },    
}