<template>
  <div class="container-fluid">

    <br>

    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
    </div>  
      
    <div class="row">
      
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Inscrições realizadas</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">400</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-calendar fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Alunos matriculados</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">380</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-chalkboard-teacher fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Turmas concluídas</div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">50</div>
                  </div>
                  <!-- <div class="col">
                    <div class="progress progress-sm mr-2">
                      <div class="progress-bar bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Alunos concluintes</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">320</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-graduation-cap fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <!-- Pie Chart -->
      <div class="col-xl-6 col-lg-6">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Inscrição por categoria</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="380" :options="pie.chartOptions" :series="pie.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Avaliação média dos cursos</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body" style="max-width: 760px;">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="bar" height="280" :options="ava.chartOptions" :series="ava.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Inscritos Concluintes x Desistentes</h6>
          </div>
                <!-- Card Body -->
          <div class="card-body" style="max-width: 1600px;">
            <apexchart type="area" height="350" :options="area.chartOptions" :series="area.series"></apexchart>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Consolidado de Inscrições</h6>
          </div>
          <div class="card-body">
            <apexchart type="bar" height="350" :options="stacked.chartOptions" :series="stacked.series"></apexchart>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Cursos com maior aderência</h6>
          </div>
                <!-- Card Body -->
          <div class="card-body" style="max-width: 1600px;">
            <apexchart type="bar" height="350" :options="column.chartOptions" :series="column.series"></apexchart>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'Home',
  data: () => {
    return {
      pie: {
        series: [10, 70, 20],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['Servidor', 'Comunidade', 'Indicação'],
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      column: {
        series: [{
          name: 'Servings',
          data: [50, 90, 60, 70, 85]
        }],
        chartOptions: {
          chart: {
            type: 'bar'
          },
          plotOptions: {
            bar: {
              columnWidth: '50%',
              endingShape: 'flat'  
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            width: 2
          },
          xaxis: {
            labels: {
              rotate: -45
            },
            categories: ['CT 884 (1203) - COMUNICAÇÃO / MARKETING', 'CT 885 (1204) - PREGOEIRO', 'CT 903 (1234) - PORTAL MODELO / SAPL', 'PP98 (1165) - MUDANÇAS CLIMÁTICAS', 'CT 886 (1210) - REDAÇÃO / LINGUAGEM'],
          },
          yaxis: {
            title: {
              text: 'Percentual',
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100]
            },
          }
        },
      },
      ava: {
        responsive: [
    {
      breakpoint: 1000,
      options: {
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        legend: {
          position: "bottom"
        }
      }
    }
  ],
        series: [{
          name: 'Inflation',
          data: [3.3, 3.1, 4.0, 4.7, 4.0, 4.8, 3.9, 4.3, 4.4, 3.8, 4.5, 3.2]
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              }
            }
          
          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        },
      },
      area: {
        series: [{
            name: 'Concluintes',
            data: [31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'Desistentes',
            data: [11, 32, 45, 32, 34, 52, 41]
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'date',
              categories: ["01/2019", "02/2019", "03/2019", "04/2019", "05/2019", "06/2019", "07/2019"]
            },
            tooltip: {
              x: {
                format: 'MM/yyyy'
              },
            },
          },
      },
      stacked: {
        series: [{
            name: 'PRODUCT A',
            data: [44, 55, 41, 67, 22, 43]
          }, {
            name: 'PRODUCT B',
            data: [13, 23, 20, 8, 13, 27]
          }, {
            name: 'PRODUCT C',
            data: [11, 17, 15, 15, 21, 14]
          }, {
            name: 'PRODUCT D',
            data: [21, 7, 25, 13, 22, 8]
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            xaxis: {
              type: 'datetime',
              categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT', '01/05/2011 GMT', '01/06/2011 GMT'],
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          }
      }
    }
  }
}
</script>