<template>
    <v-card v-if="show" height="408">
        <v-card-title class="align-start" v-if="!(windowWidth > 960 && windowWidth < 1360)">
        <span style="font-size: 0.9em; font-weight: normal;">Atendimentos por faixa etária no ano</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                mdi-information-outline
            </v-icon>
            </template>
            <span>Confira o total de atendimentos realizados a partir de <br> Janeiro do presente ano para as respectivas faixas etárias</span>
        </v-tooltip>
        </v-card-title>
        <v-card-text max-height="300"> 
            <apexchart type="bar" height="290"  :options="faixaetaria.chartOptions" :series="faixaetaria.series"></apexchart>
        </v-card-text>
    </v-card>
    <v-card v-else height="408">
        <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
            <cube-shadow class="text-center"></cube-shadow>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import { CubeShadow } from 'vue-loading-spinner'
export default {
    mixins: [resizeMixin],
    props: {
        items: Array,
        show: Boolean,
    },
    components: {
        CubeShadow
    },
    data () {
		return {
            faixaetaria: {
                series: [{
                    name: 'Atendimentos por faixa etária',
                    data: []
                }],
                chartOptions: {
                    colors: [
                        '#0E6BA8',
                    ],
                    noData: {
                        align: "center",
                        text: "Carregando...",
                        verticalAlign: "middle"
                    },
                    chart: {
                        toolbar: {
                            show: false
                        },
                        height: 350,
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        // offsetY: -20,
                        // offsetX: -50,
                        style: {
                            fontSize: '12px',
                            colors: ["#ffffff"]
                        }
                    },

                    xaxis: {
                        categories: [],
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                colorFrom: '#0E6BA8',
                                colorTo: '#A6E1FA',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return val;
                            }
                        }

                    },
                    title: {
                        text: '',
                        floating: true,
                        offsetY: 330,
                        align: 'center',
                        style: {
                        color: '#444'
                        }
                    }
                }
            },
        }
	},
    watch: {
        show(show){
            this.faixaetaria.series[0].data                 = []
            this.faixaetaria.chartOptions.xaxis.categories  = []
            if(show)
                this.items.forEach(faixaEtaria => {
                    this.faixaetaria.series[0].data.push(faixaEtaria.Quantidade)
                    this.faixaetaria.chartOptions.xaxis.categories.push(faixaEtaria.FaixaEtaria)
                })            
        }
    }
}
</script>
