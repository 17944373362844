<template>
  <div class="container-fluid">

    <br>

    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Inscrições</h1>
    </div>  


    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Alunos inscritos por categoria</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="800" height="250" :options="pie.chartOptions" :series="pie.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Alunos matriculados (Efetivados)</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="800" height="250" :options="pie2.chartOptions" :series="pie2.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Alunos concluintes/aprovados</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="800" height="250" :options="pie4.chartOptions" :series="pie4.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Estudantes desistentes/reprovados</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="800" height="250" :options="pie5.chartOptions" :series="pie5.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
        <!-- Pie Chart -->
      <div class="col-xl-12 col-lg-12">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Estudantes parceiros</h6>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <apexchart type="pie" width="800" height="250" :options="pie3.chartOptions" :series="pie3.series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => {
    return {
      pie: {
        series: [889, 6, 13, 102, 746, 90, 1015, 2],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['COMUNIDADE', 'DEPENDENTE DE SERVIDOR', 'ENCAMINHADO PELO PARLAMENTAR', 'ENCAMINHADO PELO SERVIDOR', 'PARCEIRO (PESSOA JURIDICA)', 'SERVIDOR INDICADO POR SUPERIOR DIRETO E TÉCNICA', 'SERVIDOR INICIATIVA PESSOAL', 'SERVIDOR INICIATIVA PESSOAL E TÉCNICA']
        },
      },
      pie2: {
        series: [859, 6, 12, 102, 745, 87, 988, 2],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['COMUNIDADE', 'DEPENDENTE DE SERVIDOR', 'ENCAMINHADO PELO PARLAMENTAR', 'ENCAMINHADO PELO SERVIDOR', 'PARCEIRO (PESSOA JURIDICA)', 'SERVIDOR INDICADO POR SUPERIOR DIRETO E TÉCNICA', 'SERVIDOR INICIATIVA PESSOAL', 'SERVIDOR INICIATIVA PESSOAL E TÉCNICA'],
          responsive: [{
            breakpoint: 600,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      pie3: {
        series: [663, 1, 1, 3, 1, 57, 14, 5],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['', 'CÂMARA MUNICIPAL DE ACARI', 'CÂMARA MUNICIPAL DE NATAL', 'CAMARA MUNICIPAL DE PARNAMIRIM', 'ESCOLA DE MAGISTRATURA FEDERAL DA 5a REGIÃO', 'FEDERAÇÃO DAS CÂMARAS MUNICIPAIS DO ESTADO DO RN', 'FEDERAÇÃO DOS MUNICÍPIOS DO RIO GRANDE DO NORTE', 'TRIBUNAL DE CONTAS DO ESTADO DO RIO G DO NORTE'],
          responsive: [{
            breakpoint: 600,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      pie4: {
        series: [414, 1, 7, 74, 509, 38, 580, 2],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['COMUNIDADE', 'DEPENDENTE DE SERVIDOR', 'ENCAMINHADO PELO PARLAMENTAR', 'ENCAMINHADO PELO SERVIDOR', 'PARCEIRO (PESSOA JURIDICA)', 'SERVIDOR INDICADO POR SUPERIOR DIRETO E TÉCNICA', 'SERVIDOR INICIATIVA PESSOAL', 'SERVIDOR INICIATIVA PESSOAL E TÉCNICA'],
          responsive: [{
            breakpoint: 600,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      pie5: {
        series: [445, 5, 5, 28, 236, 49, 408],
        chartOptions: {
          chart: {
            type: 'pie'
          },
          labels: ['COMUNIDADE', 'DEPENDENTE DE SERVIDOR', 'ENCAMINHADO PELO PARLAMENTAR', 'ENCAMINHADO PELO SERVIDOR', 'PARCEIRO (PESSOA JURIDICA)', 'SERVIDOR INDICADO POR SUPERIOR DIRETO E TÉCNICA', 'SERVIDOR INICIATIVA PESSOAL'],
          responsive: [{
            breakpoint: 600,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
    }
  }
}
</script>