<template>
	<div>
		<div class="container-fluid">
			<v-row>
				<v-col cols="12">
					<v-card shaped elevation="1">
						<h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
							PREFEITURA MUNICIPAL DE MACAÍBA - SIS GESTOR SAÚDE INFORMAÇÕES GERENCIAIS
						</h4>
						<h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">ATENDIMENTOS - UPA ALUÍZIO ALVES</h5>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<dashboard-statistics-card :items="fichaList" :fichaTotal="fichaToday" :show="showFichasStatisticas" :bool="true"></dashboard-statistics-card>
				</v-col>

				<v-col
					cols="12"
					md="3"
				>
					<dashboard-card-pie-risco :items="riscoList" :show="showPieRisco"></dashboard-card-pie-risco>

				</v-col>

				<v-col
					cols="12"
					sm="6"
					md="3"
				>
					<dashboard-card-trend-dias :items="ultimosDiasList" :line="ultimosDiasLine" :show="showUltimosDias"></dashboard-card-trend-dias>
				</v-col>

				<v-col
					cols="12"
					md="2"
					sm="6"
				>
					<v-card height="280">
							<dashboard-card-pie-genero :items="generoList" :show="showPieGenero"></dashboard-card-pie-genero>
					</v-card>
				</v-col>

				<v-col
					cols="12"
					md="3"
				>
					<dashboard-time-graphic :items="esperaListTratado" :show="showFichasChamadas && showChartTime" :waiting24="esperaMedia" :waiting6="esperaMedia6" :waitingClassificacao="tempoEsperaClassificacao"></dashboard-time-graphic>
				</v-col>
				<v-col
					cols="12"
					md="6"
				>
					<dashboard-datatable :items="UltimasFichasChamadas" :show="showFichasChamadas && showChartTime" :averageWaiting="esperaMedia"></dashboard-datatable>
				</v-col>

				<v-col
					cols="12"
					md="3"
					sm="6"
				>
					<dashboard-card-plantonistas :items="EscalaList" :show="showPlantonistas"></dashboard-card-plantonistas>
				</v-col>

				<v-col
					cols="12"
					md="3"
					sm="6"
				>
					<dashboard-card-total-list :items="municipioList" :amount="valueMunicipios" :show="showMunicipiosQtd"></dashboard-card-total-list>
				</v-col>

				<v-col
					cols="12"
					md="3"
					sm="6"
				>
					<dashboard-card-atendimentos-table
						atendimentosBy="bairro"
                        period="mês"
                        keyAtendimentosBy="Bairro"
                        keyAmount="Quantidade"
                        :items="atendimentosTotaisAnoBairro"
                        :show="showCardBairrosTable"
					/>
				</v-col>

				<v-col
					cols="12"
					md="3"
				>
					<dashboard-card-barras-etaria :items="FaixaEtariaList" :show="showFaixaEtaria"></dashboard-card-barras-etaria>
				</v-col>

				<v-col
					cols="12"
					md="3"
				>
					<dashboard-card-barras-doencas :items="doencasList" :show="showBarrasDoencas"></dashboard-card-barras-doencas>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import moment from 'moment'
import DashboardStatisticsCard from '@/components/painel/DashboardStatisticsCard.vue'
import DashboardCardTotalList from '@/components/painel/DashboardCardTotalList.vue'
import DashboardCardPlantonistas from '@/components/painel/DashboardCardPlantonistas.vue'
import DashboardDatatable from '@/components/painel/DashboardDatatable.vue'
import DashboardTimeGraphic from '@/components/painel/DashboardTimeGraphic.vue'
import DashboardCardPieRisco from '@/components/painel/DashboardCardPieRisco.vue'
import DashboardCardBarrasEtaria from '@/components/painel/DashboardCardBarrasEtaria.vue'
import DashboardCardTrendDias from '@/components/painel/DashboardCardTrendDias.vue'
import DashboardCardAtendimentosTable from '@/components/painel/DashboardCardAtendimentosTable'
import DashboardCardPieGenero from '@/components/painel/DashboardCardPieGenero.vue'
import DashboardCardBarrasDoencas from '@/components/painel/DashboardCardBarrasDoencas.vue'

export default {
	name: 'SaudeMonitor',
	mixins: [resizeMixin],
	components: {
		DashboardStatisticsCard,
		DashboardCardTotalList,
		DashboardCardPlantonistas,
		DashboardDatatable,
		DashboardTimeGraphic,
		DashboardCardPieRisco,
		DashboardCardPieGenero,
		DashboardCardBarrasDoencas,
		DashboardCardBarrasEtaria,
		DashboardCardTrendDias,
		DashboardCardAtendimentosTable
	},
	data: () => ({
		txt: '',
		loading: false,
		itemsPerRow: 2,
		riscoList:[],
		generoList: {Masculino: 0, Feminino: 0},
		doencasList:[],
		municipioList:[],
		municipiosListTotal: [],
		ultimosDiasList:[],
		ultimosDiasLine: 0,
		UltimosMesesList:[],
		FaixaEtariaList:[],
		EscalaList:[],
		fichaList:[],
		UltimasFichasChamadas:[],
		esperaList: [],
		esperaListTratado: [],
		tempoEsperaClassificacao: [],
        atendimentosTotaisAnoBairro: [],
        showCardBairrosTable: false,
		esperaMedia: null,
		esperaMedia6: null,
		classificacaoRiscoTratado: null,
		fichaToday: 0,
		showChart: false,
		showChartTime: false,
		showFichasChamadas: false,
		showFichasStatisticas: false,
		showMunicipiosQtd: false,
		showPlantonistas: false,
		showPieRisco: false,
		showFaixaEtaria: false,
		showUltimosDias: false,
		showPieGenero: false,
		showBarrasDoencas: false,
		showAnosBairros: false,
		
	}),
	computed: {
		valueMunicipios() {
			if (this.municipioList.length) return this.municipiosListTotal.reduce((x, y) => x + parseInt(y.Quantidade), 0)
			else return 0
		},
	},
	methods: {
		verificaCor(item){
			switch (item) {
				case 'NAO INFORMADO':
				return 'grey'
				case 'VERMELHO':
				return 'red'
				case 'AMARELO':
				return 'yellow'
				case 'VERDE':
				return 'green'
				case 'AZUL':
				return 'blue'
				case 'LARANJA':
				return 'orange'
			}
		},
		classificacaoRisco() {
			this.showPieRisco = false
			this.axios('atendimento/atendimentoclassificacaorisco').then(res => {
				this.riscoList = res.data
				this.showPieRisco = true
			})
		},
		classificacaoGenero() {
			this.showPieGenero = false
			this.axios('atendimento/atendimentoclassificacaogenero').then(res => {
				console.log(res.data)
				this.generoList = res.data
				this.showPieGenero = true
			})
		},
		classificacaoDoencas() {
			this.showBarrasDoencas = false
			this.axios('atendimento/atendimentoclassificacaodoencas').then(res => {
				this.doencasList = res.data
				this.showBarrasDoencas = true
			})
		},
		ClasssificacaoMunicipio(){
			this.showMunicipiosQtd = false
			this.axios('atendimento/atendimentoquantidademunicipio')
			.then(res => {
				const municipios = res.data.slice(0, 5)
				this.municipiosListTotal = res.data
				this.municipioList = municipios.map(mun => {
					return{
						Cidade: mun.Municipio,
						Quantidade: mun.Quantidade,
					}
				})
				this.showMunicipiosQtd = true
			})
		},
		getAtendimentoPorBairro(){
            this.showCardBairrosTable = false

			this.axios.get('atendimento/atendimentospormes')
			.then(res => {
				this.atendimentosTotaisAnoBairro = res.data
			}).finally(() => {
                this.showCardBairrosTable = true
            })
		},
		AtendimentoUltimosDias(){
			this.showUltimosDias = false
			this.axios('atendimento/ultimosatendimentosdias').then(res => {
				for (var i = 0; i < res.data.length; i++) {
					this.ultimosDiasLine = Math.trunc(res.data[0].Media)
					this.ultimosDiasList = res.data.map(mun => {
						return{
							Data: moment(mun.Data).format('DD/MM'),
							Quantidade: mun.Quantidade,
						}
					})
					this.showUltimosDias = true
				}
			})
		},
		AtendimentoUltimosMeses(){
			this.axios('atendimento/ultimosatendimentosdezmeses')
			.then(res => {
				this.UltimosMesesList = res.data.map(meses => {
					return{
						Data: meses.Mes + '/' + meses.Ano,
						Quantidade: meses.Quantidade
					}
				})
			})
		},
		AtendimentoFaixaEtaria(){
			this.showFaixaEtaria = false
			this.axios('atendimento/atendimentosfaixaetaria')
			.then(res => {
				this.FaixaEtariaList = res.data.map(etaria => {
					return{
						FaixaEtaria: etaria.FaixaEtaria,
						Quantidade: etaria.Quantidade,
					}
				})
				this.showFaixaEtaria = true
			})
		},

		getEscala(){
			var i = 0;
			this.showPlantonistas = false
			this.axios('escala').then(res => {
				this.EscalaList = res.data.map(escala => {
					return {
						id: i++,
						Nome: escala.Nome,
						Tipo: escala.TipoProfissional,
					}
				})

			}).finally(() => {
				this.showPlantonistas = true
			})
		},
		getAtendimentoQuantidadeFicha(){
			this.axios('atendimento/atendimentoquantidadeficha')
			.then(res => {
				this.showChart = false
				this.showFichasStatisticas = false

				let ficha = res.data[0]
				this.fichaToday = ficha.Fichas

				this.fichaList.push({
					title: 'Aguardando',
					total: ficha.Aguardando,
					icon: 'mdi-account-clock',
					color: '#001C55'
				})
				this.fichaList.push({
					title: 'Acolhimento',
					total: ficha.Acolhimento,
					icon: 'mdi-account-arrow-right-outline',
					color: '#0A2472'
				})
				this.fichaList.push({
					title: 'Classificação',
					total: ficha.Classificacao,
					icon: 'mdi-account-reactivate-outline',
					color: '#0E6BA8'
				})
				this.fichaList.push({
					title: 'Atendimento',
					total: ficha.Atendimento,
					icon: 'mdi-account-search-outline',
					color: '#A6E1FA'
				})
			}).finally(() => {
				this.showChart = true
				this.showFichasStatisticas = true
			})
		},
		getAtendimentoMediaEspera(){
			this.showChartTime = false
			this.axios('atendimento/atendimentomediaespera')
			.then((res) => {
				this.esperaList = res.data
				const espera = this.esperaList
				this.esperaListTratado.push({
					EsperaInicial24: espera.Mediaminutes24h * 60 + espera.Mediaseconds24h,
					EsperaFinal6: espera.Mediaminutes06h * 60 + espera.Mediaseconds06h,
					EsperaAcolhimento: espera.MediaminutesAcolhimento * 60 + espera.MediasecondsAcolhimento,
					EsperaAtendimento: espera.MediaminutesAtendimento * 60 + espera.MediasecondsAtendimento,
					EsperaClassificacao: espera.MediaminutesClassificacao * 60 + espera.MediasecondsClassificacao
				})
				this.esperaMedia = espera.Mediaminutes24h * 60 + espera.Mediaseconds24h,
				this.esperaMedia6 = espera.Mediaminutes06h * 60 + espera.Mediaseconds06h,
				this.showChartTime = true
			})

		},

		getAtendimentoUltimasFichasChamadas(){
			this.showFichasChamadas = false
			this.axios('atendimento/atendimentosenhachamadas')
			.then(res => {
				this.UltimasFichasChamadas = res.data.map(chamada => {
					return {
						Ficha: chamada.Senha,
						Nome: chamada.Nome,
						Risco: chamada.Risco,
						Local: chamada.Local,
						Profissioanl: chamada.Profissional,
						Tempo: chamada.Tempo,
						Data: chamada.Data,
						Hora: chamada.Hora,
					}
				})
				this.classificacaoRiscoTratado = this.groupBy(this.UltimasFichasChamadas, 'Risco');
				if (this.classificacaoRiscoTratado.VERDE) {
					this.tempoEsperaClassificacao.verde = moment.utc((this.classificacaoRiscoTratado.VERDE.reduce((x, y) => x + moment.duration(y.Tempo).asSeconds(), 0) / this.classificacaoRiscoTratado.VERDE.length)*1000).format('LTS')
				}
				if (this.classificacaoRiscoTratado.AZUL) {
					this.tempoEsperaClassificacao.azul = moment.utc((this.classificacaoRiscoTratado.AZUL.reduce((x, y) => x + moment.duration(y.Tempo).asSeconds(), 0) / this.classificacaoRiscoTratado.AZUL.length)*1000).format('LTS')
				}
				if (this.classificacaoRiscoTratado.AMARELO) {
					this.tempoEsperaClassificacao.amarelo = moment.utc((this.classificacaoRiscoTratado.AMARELO.reduce((x, y) => x + moment.duration(y.Tempo).asSeconds(), 0) / this.classificacaoRiscoTratado.AMARELO.length)*1000).format('LTS')
				}
				if (this.classificacaoRiscoTratado.VERMELHO) {
					this.tempoEsperaClassificacao.vermelho = moment.utc((this.classificacaoRiscoTratado.VERMELHO.reduce((x, y) => x + moment.duration(y.Tempo).asSeconds(), 0) / this.classificacaoRiscoTratado.VERMELHO.length)*1000).format('LTS')
				}
				if (this.classificacaoRiscoTratado.LARANJA) {
					this.tempoEsperaClassificacao.laranja = moment.utc((this.classificacaoRiscoTratado.LARANJA.reduce((x, y) => x + moment.duration(y.Tempo).asSeconds(), 0) / this.classificacaoRiscoTratado.LARANJA.length)*1000).format('LTS')
				}
			}).finally(() => {
				this.showFichasChamadas = true
			})

		},
		groupBy(array, key) {
			return array.reduce((result, currentValue) => {(
				result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
				return result;
			}, {}); //
		}
	},
	mounted() {
        window.scrollTo(0, 80);
		this.getEscala()
		this.classificacaoRisco()
		this.classificacaoGenero()
		this.classificacaoDoencas()
		this.AtendimentoFaixaEtaria()
		this.getAtendimentoPorBairro()
		this.AtendimentoUltimosDias()
		this.getAtendimentoMediaEspera()
		this.getAtendimentoQuantidadeFicha()
		this.getAtendimentoUltimasFichasChamadas()
		this.ClasssificacaoMunicipio()
		this.atendimentosAno()
	},

}
</script>
<style lang="scss">
.blink{
	animation: blink 4s linear infinite;
}
@keyframes blink{
    0%{ opacity: .1; }
    50%{ opacity: .99; }
    100%{ opacity: .99; }
}
.container-fluid {
	position: absolute;
}
#particles-js {
	width: 100%;
	height: 100vh;
}
</style>
