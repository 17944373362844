import Requerimento from '../views/Requerimento.vue'
import ValidarRequerimento from '../views/ValidarRequerimento.vue'
import { permissionChecker } from '../utils/permission';

export default [
    {
        path: '/requerimentos/cadastrar',
        name: 'Requerimentos',
        component: Requerimento,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/requerimentos/validar',
        name: 'Validar Requerimentos',
        component: ValidarRequerimento,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_ADMINISTRATIVO|ALL')
        }
    }
];