<template>
	<div>
		<div class="container-fluid">
			
			<v-row>
				<v-col cols="12">
					<v-card shaped elevation="1">
						<h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
							HOSPITAL GERAL DE NABOO - GESTÃO
						</h4>
						<h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> PAINEL DE INFORMAÇÕES DA UNIDADE</h5>
					</v-card>
				</v-col>

					<v-col
					cols="12"
					md="4"
					>
					<v-card id="chartmes">
							<apexchart type="area" height="350" :options="chartOptionsAtendimentosMensais" :series="seriesAtendimentosMensais"></apexchart>
						</v-card>
						<!-- <v-card cols="12">
							<v-row cols="6" align-center>
							<i cols="3">mdi-school</i>
							<i cols="3">mdi-school</i>
							</v-row>
							<v-row cols="6" align-center>
							<i cols="3">mdi-school</i>
							<i cols="3">mdi-school</i>
							</v-row>
						</v-card> -->
					</v-col>
	
					<v-col
						cols="4"
						md="4"
					>
						<v-card id="chartidade">
							<apexchart type="bar" height="350" :options="chartOptionsFaixaEtaria" :series="seriesFaixaEtaria"></apexchart>
						</v-card>
					</v-col>

					<v-col
						cols="4"
						md="4"
					>
						<v-card id="chartmes">
							<apexchart type="area" height="350" :options="chartOptionsAtendimentosMensais" :series="seriesAtendimentosMensais"></apexchart>
						</v-card>
					</v-col>
				</v-row>
				<v-row>

			</v-row>
		</div>
	</div>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
// import moment from 'moment'
export default {
	name: 'SaudeMonitorHospitalar',
	mixins: [resizeMixin],
	data: () => ({

		fichaList:[],
		showChart: false,
		showFichasStatisticas: false,
		txt: '',
		seriesFaixaEtaria: [{
            name: 'Atendidos',
            data: [10, 12, 5, 18, 90, 30, 55, 26, 2, 3]
          }],
		chartOptionsFaixaEtaria: {
            chart: {
              height: 350,
              type: 'bar',
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val;
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            
            xaxis: {
              categories: ["0 a 3", "4 a 7", "8 a 12", "13 a 18", "19 a 25", "26 a 40", "41 a 60", "61 a 80", "81 a 100", "100+"],
              position: 'bottom',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val;
                }
              }
            
            },
            title: {
              text: 'Atendimentos por faixa etária',
              floating: true,
              offsetY: 330,
              align: 'center',
              style: {
                color: '#444'
              }
            }
          },

		seriesAtendimentosMensais: [{
            name: "Atendimentos",
            data: [31, 40, 28, 51, 42, 62, 53]
          }],
          chartOptionsAtendimentosMensais: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ["2018-09-19", "2018-09-20", "2018-09-21", "2018-09-22", "2018-09-23", "2018-09-24", "2018-09-25"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy'
              },
            },
          },
        


	}),
	computed: {
	},
	methods: {
		verificaCor(item){
			switch (item) {
				case 'NAO INFORMADO':
				return 'grey'
				case 'VERMELHO':
				return 'red'
				case 'AMARELO':
				return 'yellow'
				case 'VERDE':
				return 'green'
				case 'AZUL':
				return 'blue'
			}
		},
	},
	mounted() {
	},
}
</script>
<style lang="scss">
.blink{
	animation: blink 4s linear infinite;
}
@keyframes blink{
    0%{ opacity: .1; }
    50%{ opacity: .99; }
    100%{ opacity: .99; }
}
.container-fluid {
	position: absolute;
}
#particles-js {
	width: 100%;
	height: 100vh;
}
</style>
