<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-lg-12 mb-2' : 'col-xl-3 col-md-3 mb-2'">
    <div class="card elevation-5 h-100 py-2 " :class="'border-left-' + color">
      <div class="card-body " >
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div
              class="text-xs font-weight-bold text-uppercase mb-1"
              :class="'text-' + color"
            >
              {{ label }}
            </div>
            <div class="h5 mb-0 font-weight-bold text-gray-800">{{ data }}</div>
          </div>
          <div class="col-auto">
            <i class="fas fa-2x text-gray-300" :class="'fa-' + icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "school",
    },
    label: {
      type: String,
      default: "Quantidade de escolas",
    },
    data: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
