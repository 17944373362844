<template>
    <div>
        <div class="page">
            <progress-system :isLoad="progress"></progress-system>
            <div class="container-fluid">
             <br>
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                     <h1 class="h3 mb-0 text-gray-800">Meus medicamentos</h1>
                </div>
            <v-row>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-text-field
                        dense
                        outlined
                        clearable
                        hide-details
                        append-icon="mdi-magnify"
                        label="Pesquisa"
                        v-model="search"
                    ></v-text-field>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-autocomplete
                        hide-details
                        outlined
                        dense
                        clearable
                        :items="datas"
                        v-model="searchAno"
                        label="Filtrar pelo ano"
                        item-text="ano"
                        @change="getDispensacao(), this.searchAno = null"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-autocomplete
                        hide-details
                        outlined
                        dense
                        clearable
                        v-model="searchUnidade"
                        :items="listamedicamentos"
                        label="Filtrar pela unidade"
                        item-text="Unidade"
                        
                        @change="getDispensacao()"
                    ></v-autocomplete>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
                    <v-autocomplete
                        hide-details
                        outlined
                        dense
                        clearable
                        v-model="searchItem"
                        :items="listamedicamentos"
                        label="Filtrar por item"
                        item-text="NomeComercial"
                        @change="getDispensacao()"
                    ></v-autocomplete>
                </v-col>
                <!-- <span>Botão retirado para padronização de filtros</span> -->
                <!-- <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol" v-if="search != null || searchAno != null || searchUnidade != null || searchItem != null">
                    <v-btn append-icon="mdi-autorenew" width="100%" @click.native="clearFilters(), getDispensacao()"
                        >
                        Limpar Filtros
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-data-table
                :headers="headers"
                :items="listamedicamentos"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
            >
            <!-- :custom-filter="search" -->
            <template v-slot:[`item.DataIns`]="{ item }">
                <span>{{$moment(item.DataIns).format('LTS')}}</span>
            </template>
            </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressSystem from "../../components/ProgressSystem";


export default {
  name: "Dispensacao",
  components: {
    ProgressSystem,
  },
  data: () => ({
    dynamicCol: "",
    unidade: null,
    ano: null,
    nome: '',
    progress: true,
    listamedicamentos: [],
    Unidades: [],
    headers: [
      { text: "Requisição", value: "Requisicao" },
      { text: "Data", value: "DataIns" },
      { text: "Unidade", value: "Unidade" },
      { text: "Item", value: "NomeComercial" },
      { text: "Quantidade", value: "QuantidadeItem" },
    ],

    search: null,
    searchAno: null,
    searchUnidade: null,
    searchItem: null,
    datas: [],
  }),
  methods: {
    getDispensacao() {
      this.axios
        .get("pessoa/dispensacao", {
          params: {
            idUnidade: this.searchUnidade,
            ano: this.searchAno,
            nomeComercial: this.searchItem,
          },
        })
        .then((res) => {
          this.listamedicamentos = res.data;

          this.listamedicamentos.sort(function (a, b) {
            if (a.NomeComercial < b.NomeComercial) {
              return -1;
            }
            if (a.NomeComercial > b.NomeComercial) {
              return 1;
            } else return 0;
          });
        })
        .finally(() => {
          this.progress = false;
        });
    },
    gerarAnos() {
      let max = new Date().getFullYear();
      let min = max - 19;
      let anos = [];

      for (let i = max; i >= min; i--) {
        anos.push({
          id: i,
          ano: i.toString(),
        });
      }
      this.datas = anos;
    },
    verificarFiltros(){
        if(this.search != null || this.searchAno != null || this.searchUnidade != null || this.searchItem != null){
            this.Unidade = this.searchUnidade
            this.IdAno = this.searchAno
            this.NomeComercial = this.searchItem
            this.getDispensacao()
        }
    },
    clearFilters() {
      this.search = null;
      this.searchAno = null;
      this.searchUnidade = null;
      this.searchItem = null;
    },
  },

  mounted() {
    this.gerarAnos();
    this.getDispensacao();
  },
};
</script>