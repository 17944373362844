<template>
    <v-card elevation="5" class="mb-5" style="width: 100%">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Controle do estoque mínimo</h6>
            </div>
        <v-row class="m-2">
        </v-row>
        <v-row class="m-2">
            <v-col cols="12" sm="8" md="8">
                <v-text-field
                    v-model="search"
                    prepend-icon="mdi-feature-search-outline"
                    label="Pesquisar"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-select
                    v-model="filters.situacao"
                    :items="situacaoItems"
                    label="Situação"
                    item-text="text"
                    item-value="value"
                    clearable
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
            v-model="expanded"
            :headers="headers"
            :items="filteredItems"
            :search="search"
            item-value="name"
            class="elevation-1"
            :loading="loading"
            loading-text="Carregando..."
            >

            <template v-slot:[`item.statusCode`]="{ item }">
                <v-chip v-if="item.qtdAtual < item.qtdCritica" color="red" dark> crítico </v-chip>
                <v-chip v-else-if="item.qtdAtual < item.QtdMinimaEstoque" color="yellow"> estoque minimo </v-chip>
                <v-chip v-else> Confortavel </v-chip>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>

export default {
data () {
    return {
        situacaoItems: [
            { text: 'Confortável', value: 'Confortável' },
            { text: 'Estoque Mínimo', value: 'Estoque Mínimo' },
            { text: 'Crítico', value: 'Crítico' }
        ],
        estoqueMinimo: [],
        estoqueCritico: [],
        estoqueMerged: [],
        requisicoesItens: [],
        expanded: [],
        loading: false,
        search: null,
        qntRegistros: 0,
        filters: {
            situacao: null,
        },
        headers: [
            { text:'Situação', value:'statusCode', width:'10%', align: 'center' },
            { text:'item', value:'item', width:'30%', align: 'center' },
            { text:'unidade', value:'unidade', width:'30%', align: 'center' },
            { text:'qnt atual', value:'qtdAtual', width:'10%', align: 'right' },
            { text:'qnt mínima', value:'QtdMinimaEstoque', width:'10%', align: 'right' },
            { text:'qnt crítica', value:'qtdCritica', width:'10%', align: 'right' },
        ],
    }
},
computed: {
    filteredItems() {
        return this.estoqueMerged.filter((item) => {
            const status = this.getItemStatus(item);
            if (this.filters.situacao) {
                return status === this.filters.situacao;
            }
            return true; // Exibe todos os itens se nenhum filtro estiver selecionado
        });
    }
},
methods: {
    getItemStatus(item) {
        if (item.qtdAtual < item.qtdCritica) {
            return 'Crítico';
        } else if (item.qtdAtual < item.QtdMinimaEstoque) {
            return 'Estoque Mínimo';
        } else {
            return 'Confortável';
        }
    },
    refreshSituacao(situacao){
        this.filters.situacao = situacao,
        this.getEstoqueMinimo()
    },
    getEstoqueMinimo() {
        
        this.loading = true;
        this.axios.get(`/estoque/minimo`, {params: this.filters})
        .then((res) => {
            this.estoqueMinimo = res.data;
        }).finally(() => {
            this.loading = false;
            this.getEstoqueCritico();
        });
    },
    getEstoqueCritico() {
        this.loading = true;
        this.axios.get(`/estoque/critico`, {params: this.filters})
        .then((res) => {
            this.estoqueCritico = res.data;
        }).finally(() => {
            this.loading = false;
            this.mergeEstoque();
        });
    },
    mergeEstoque(){
        const mergedEstoque = this.estoqueMinimo.map(min => {
            const critico = this.estoqueCritico.find(crit => crit.unidade === min.unidade && crit.item === min.item);
            
            return {
                unidade: min.unidade,
                item: min.item,
                qtdAtual: min.qtdAtual,
                QtdMinimaEstoque: min.QtdMinimaEstoque,
                qtdCritica: critico ? critico.qtdCritica : null
            };
        });
        this.estoqueMerged = mergedEstoque;
    },
    limparFiltros() {
        this.situacao = null;
        this.getEstoqueMinimo();
    },
},
mounted() {
    this.getEstoqueMinimo();
}
}
</script>