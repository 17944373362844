<template>
    <v-container class="d-flex justify-center align-center fill-height">
      <v-card class="pa-5" color="success lighten-1" dark>
        <v-card-title class="headline">
          <v-icon large class="mr-2">mdi-check-circle</v-icon>
          Sucesso!
        </v-card-title>
        <v-card-text>
          <p>Seu pré-cadastro habitacional foi realizado com sucesso.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="white" text @click="goHome">Retornar ao Formulario</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'SuccessScreen',
    methods: {
      goHome() {
        this.$router.push('/termsform');
      }
    }
  }
  </script>
  
  <style scoped>
  .v-container {
    height: 100vh;
  }
  .v-card {
    max-width: 500px;
    width: 100%;
  }
  .v-icon {
    font-size: 48px;
  }
  .v-card-title {
    align-items: center;
    display: flex;
  }
  p{
    font-weight: bold;
    font-size: 20px;
  }
  </style>