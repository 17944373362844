<template>
  <div class="body" style="height: 100vh !important; display: flex; justify-content: center;">
    <v-container
      class="fill-height content-box"
      fluid
      v-if="verificarLogado">
      <v-row align="center" justify="center">
          <v-card>
            <v-layout row wrap justify-center>
              <div
                class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                style="display: flex !important; justify-content: center !important;"
                :style="$vuetify.breakpoint.xsOnly ? 'height: 100px !important;' : 'height: 400px !important;'"
                >
                <v-img
                  v-if="imageMounted"
                  class="animated fadeInLeft"
                  :src="require('./../../public/img/logo/macaiba.png')"
                  max-width="350"
                  contain
                  style="cursor: pointer;"
                  @click="redirectMacaiba('https://macaiba.rn.gov.br/')">
                </v-img>
              </div>
              <div 
                class="col-lg-6 col-md-6 col-sm-6 col-xs-12 login-box"
                :style="$vuetify.breakpoint.xsOnly ? 'height: 500px !important;' : 'height: 400px !important;'"
              >
                <div class="col-lg-12 login-title">
                    PORTAL DE SERVIÇOS DE MACAÍBA
                </div>
                <div class="col-lg-12 login-form">
                  <div class="col-lg-12 login-form">
                    <v-form>
                      <v-text-field
                        v-mask="['###.###.###-##']"
                        type="tel"
                        v-model="user.Login"
                        label="CPF"
                        class="text-white"
                        color="white">
                      </v-text-field>
                      <v-text-field
                        required
                        @keyup.enter="login()"
                        v-model="user.Senha"
                        label="Senha"
                        :type="flag ? 'text' : 'password'"
                        class="text-white"
                        :append-icon="flag ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        color="white"
                        @click:append="visible()">
                      </v-text-field>
                    </v-form>
                    <div class="submit-buttons">
                      <input
                        type="button"
                        value="RECUPERAR SENHA"
                        @click='recoverPassDialog = true'
                      />
                      <input v-if="!loadingLogin" type="submit" value="CONECTAR" @keyup.enter="login()" @click="login()"/>
                      <button v-if="loadingLogin">

                      </button>
                    </div>
                    <!-- <v-snackbar v-model="snackbar.show" :color="snackbar.color" >{{snackbar.text}}</v-snackbar> -->
                  </div>
                </div>
              </div>
            </v-layout>
          </v-card>
      </v-row>

        <v-dialog
            persistent
            v-model='recoverPassDialog'
            :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
        >
            <v-card dark color='#0E6BA8'>
                <v-card-title>
                    Recuperar senha
                    <v-spacer/>
                    <v-btn
                        icon
                        @click='recoverPassDialog = false'
                    >
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    Informe seu CPF para receber um e-mail com o link para recuperação da sua senha.
                    <v-form class='mt-2' ref='recover'>
                        <v-text-field
                            v-mask="'###.###.###-##'"
                            label='CPF'
                            v-model='cpfToRecover'
                        />
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        outlined
                        class='pa-4'
                    >
                        ENVIAR E-MAIL
                        <v-icon class='ml-2'> mdi-email-lock-outline </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <system-snackbar :snackbar="snackbar"/>
    </v-container>
  </div>
</template>

<script>
import SystemSnackbar from './../components/SystemSnackbar'

export default {
  components:{
    SystemSnackbar
  },
  data () {
    return {
      rules:{
        required(value){
          if(!value) {
            return 'Campo obrigatório'
          }
        }
      },
      flag: false,
      snackbar: {
        show : false,
        color: '',
        text: ''
      },
      loadingLogin: false,
      user: {
        Login: '',
        Senha: ''
      },
      verificarLogado: false,

      recoverPassDialog: false,
      cpfToRecover: ''
    }
  },
  methods: {
    visible(){
      this.flag ? this.flag = false : this.flag = true
    },
    redirectMacaiba(value) {
      window.open(value, '_blank')
    },
    login () {
      this.loadingLogin = true
      let dataUser = {
        Login: this.user.Login.replace(/[^0-9,]*/g, ''),
        Senha: this.user.Senha
      }
      this.axios.post('/login', dataUser).then( res => {
        sessionStorage.setItem('acesso', res.data.acesso)
        sessionStorage.setItem('token', res.data.token)
        location.href = "/Home"
      }).catch(e => {
        this.snackbar = {
          show: true,
          text: e.response.data[Object.keys(e.response.data)[0]][0],
          color: '#FF5252',
          timeout: 6000,
        }
      }).finally(() => {
        setTimeout(() => (
          this.loadingLogin = false
        ), 2000)
      })
    }
  },
  mounted(){
    // this.getDadosUsuario()
    if (sessionStorage.token){
      // this.verificarLogado = false
      // location.href = "/Home"
    }
    this.imageMounted = true
    this.verificarLogado = true
  }
};
</script>
<style lang="scss">
.body {
  background: -webkit-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: -o-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: -moz-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
}
  // salvando pq gostei dessas cores também
  // background: -webkit-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  // background: -o-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  // background: -moz-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  // background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
.login-box {
  height: auto;
  max-width: 100vw;
  background: #0E6BA8;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: bold;
  color: #ECF0F5;
}
.login-form {
  text-align: left;
}
.btn-outline-primary {
  border-color: #A6E1FA;
  color: #A6E1FA;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.btn-outline-primary:hover {
  background-color: #A6E1FA;
  right: 0px;
}
.login-btm {
  float: left;
}
.login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
}
.loginbttm {
  padding: 0px;
}
.text-white .v-label {
  color: white;
  opacity: 1;
}
.text-white input{
  color: white!important;
}
.submit-buttons{
  margin-top: 10px;
  display: flex;
}
.submit-buttons input{
  height: 40px;
  width: 50%;
  text-align: center;
  transition: .2s;
}
.submit-buttons button{
  height: 40px;
  width: 50%;
  text-align: center;
  transition: .2s;
}
.submit-buttons input:hover{
  opacity: .7;
}
.submit-buttons input:nth-of-type(1){
  border-radius: 5px 0 0 5px;
  background: #0E6BA8;
  visibility: hidden; // to-do: recuperar senha
  color: white;
}
.submit-buttons input:nth-of-type(2){
  border-radius: 0 5px 5px 0;
  background: white;
  color: #0E6BA8;
}
.submit-buttons button:nth-of-type(1){
  border-radius: 0 5px 5px 0;
  background: url(https://mir-s3-cdn-cf.behance.net/project_modules/disp/dae67631234507.564a1d230a290.gif) no-repeat center center;
  color: #0E6BA8;
  background-size: contain;
}
.content-box {
  animation: 0.9s ease-out 0s 0.9 slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.animated {
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.required label::after {
    content: "*";
    color: white;
}
</style>
