<template>
  <v-card :height="height" v-if="show">
    <v-card-title class="align-start">
        <span style="font-size: 0.9em; font-weight: normal;">Fluxo de usuários na unidade</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
          mdi-information-outline
        </v-icon>
      </template>
      <span>Acompanhe o fluxo de atendimento <br> em tempo real</span>
    </v-tooltip>
    </v-card-title>

    <v-card-subtitle class="pb-3">
      <span class="font-weight-semibold text--primary me-1">Total de {{ fichaTotal }} fichas entregues hoje</span>
    </v-card-subtitle>

    <v-card-text>
      <v-layout row justify-center wrap>
        <v-col
          v-for="data in items"
          :key="data.title"
          cols="6"
          class="d-flex align-center pl-8"
        >
          <v-avatar
            size="44"
            :color="data.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-layout>
    </v-card-text>
  </v-card>
  <v-card v-else :height="height">
    <v-container fill-height fluid>
      <v-row align="center"
          justify="center">
          <rotate-square5 class="text-center"></rotate-square5>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {RotateSquare5} from 'vue-loading-spinner'
export default {
  props: {
    items: Array,
    fichaTotal: Number,
    show: Boolean,
    height: {
      type: Number,
      default: 280
    }
  },
  components: {
    RotateSquare5
  }
}
</script>
