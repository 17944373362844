<template>
    <v-card v-if="show" height="280">
        <v-card-title class="align-start">
            <span style="font-size: 0.9em; font-weight: normal;">Classificação de risco</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-information-outline
                    </v-icon>
                </template>
                <span>Veja o total de atendimentos por classificação de risco. <br>
                    Você pode ver apenas um período especifico pausando ou <br> selecionando o período e, depois, aguardar o processamento.</span>
            </v-tooltip>
        </v-card-title>
        <v-card flat class="flexcard mb-0 pb-0" height="170" style="max-height: 170px !important">
            <apexchart type="donut" class="grafico mb-0 pb-0" height="110%" :options="classificacaorisco.chartOptions" :series="classificacaorisco.series"></apexchart>
        </v-card>
        <v-flex xs12 v-if="!(windowWidth < 1300 && windowWidth >= 1264)">
            <v-layout row wrap justify-center>
                <v-col cols="2">
                    <v-btn
                        style="max-width: 50px !important;width: 50px !important"
                        text
                        :class="selected === 'ano' ? 'primary--text' : ''"
                        @click="appendAno(1)">
                        Ano
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        text
                        :class="selected === 'mes' ? 'primary--text' : ''"
                        @click="appendMes(1)">
                        Mês
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn
                        text
                        :class="selected === 'ontem' ? 'primary--text' : ''"
                        @click="appendOntem(1)">
                        Ontem
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn
                        text
                        :class="selected === 'hoje' ? 'primary--text' : ''"
                        @click="appendHoje(1)">
                        Hoje
                    </v-btn>
                </v-col>
                <v-col cols="2" v-if="!((windowWidth >= 960 && windowWidth <= 974) || (windowWidth < 1360 && windowWidth >= 1264))">
                    <div v-if="loading == false && !$vuetify.breakpoint.mdOnly">
                        <v-btn
                            style="margin-left: -10px"
                            icon
                            @click="pauseIntervals()">
                            <v-icon>
                                {{ computedIcon }}
                            </v-icon>
                        </v-btn>
                    </div>
                    <div v-else-if="!$vuetify.breakpoint.mdOnly">
                        <v-progress-circular
                            class="mt-1"
                            :width="2"
                            :size="25"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                </v-col>
            </v-layout>
        </v-flex>
    </v-card>
    <v-card v-else height="280">
        <v-container fill-height fluid>
            <v-row align="center"
                justify="center">
                <circle3 class="text-center"></circle3>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import { Circle3 } from 'vue-loading-spinner'
export default {
    props: {
        items: Array,
        show: Boolean,
    },
    mixins: [resizeMixin],
    components: {
        Circle3
    },
    data () {
		return {
            qtdAno: [],
            qtdMes: [],
            qtdOntem: [],
            qtdHoje: [],
            labels: [],
            loading: false,
            trava: false,
            selected: 'ano',
            intervalAno: undefined,
            intervalMes: undefined,
            intervalOntem: undefined,
            intervalHoje: undefined,
            classificacaorisco: {
                series: [],
                chartOptions: {
                    chart: {
                        type: 'donut'
                    },
                    noData: {
                        align: "center",
                        text: "Nenhum dado disponível até o momento",
                    },
                    colors: [
                        '#808080',
                        '#FF5252',
                        '#FFC107',
                        '#41B883',
                        '#1976D2',
                        '#ffA500',
                    ],
                    labels: [],
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        show: false
                    },
                },
            },
        }
	},
    computed: {
        computedIcon () {
            return this.trava ? 'mdi-undo' : 'mdi-pause'
        }
    },
    watch:{
        show(){
            if(this.show == true){
                for (var i = 0; i < this.items.length; i++) {

                    this.qtdAno.push(this.items[i].QuantidadeAno)
                    this.qtdMes.push(this.items[i].QuantidadeMes)
                    this.qtdOntem.push(this.items[i].QuantidadeOntem)
                    this.qtdHoje.push(this.items[i].QuantidadeHoje)
                    this.labels.push(this.items[i].classificacao)

                }
                this.classificacaorisco.chartOptions.labels = []
                this.classificacaorisco.chartOptions.labels = this.labels
                this.appendAno(0)
            }
        },
        trava() {
            if(this.trava) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                }, 2000)
            }
        }
    },
    methods: {
        appendAno (value) {
            if (value) this.trava = true
            this.classificacaorisco.series = []
            this.classificacaorisco.series = this.qtdAno
            this.selected = 'ano'
            if (!this.trava && !value) {
                setTimeout(() => (
                    this.appendMes(0)
                ), 2000)
            }
        },

        appendMes (value) {
            if (value) this.trava = true
            this.classificacaorisco.series = []
            this.classificacaorisco.series = this.qtdMes
            this.selected = 'mes'
            if (!this.trava && !value) {
                setTimeout(() => (
                    this.appendOntem(0)
                ), 2000)
            }
        },

        appendOntem (value) {
            if (value) this.trava = true
            this.classificacaorisco.series = []
            this.classificacaorisco.series = this.qtdOntem
            this.selected = 'ontem'
            if (!this.trava && !value) {
                setTimeout(() => (
                    this.appendHoje(0)
                ), 2000)
            }
        },

        appendHoje (value) {
            if (value) this.trava = true
            this.classificacaorisco.series = []
            this.classificacaorisco.series = this.qtdHoje
            this.selected = 'hoje'
            if (!this.trava && !value) {
                setTimeout(() => (
                    this.appendAno(0)
                ), 2000)
            }
        },
        pauseIntervals() {
            if (this.trava) {
                this.trava = false
                this.appendAno(0)
            } else this.trava = true
        }
    },
    mounted() {

    }
}
</script>
<style>
.flexcard {
  display: flex;
  flex-direction: column;
}
.flexcard .grafico {
  flex: 0;
}
</style>
