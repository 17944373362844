<template>
  <v-card v-if="show" height="408" style="overflow-y: auto">
    <v-card-title class="pb-0">
      <span style="font-size: 0.9em; font-weight: normal">
        Últimas senhas chamadas
      </span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>Consulte as últimas senhas chamadas</span>
      </v-tooltip>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="NomePaciente"
      class="table-rounded"
      hide-default-footer
      disable-sort
    >
      <template #[`item.Numero`]="{ item }">
        <v-chip
          small
          class="font-weight-medium white--text"
          :color="statusColor(item.Tipo.charAt(0))"
        >
          {{ String(item.Numero).padStart(3, "0") }}
        </v-chip>
      </template>
      <template #[`item.DataConsulta`]="{ item }">
        <v-tooltip bottom>  
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $moment(item.DataIns, 'YYYY-MM-DD LTS').startOf('minute').fromNow() }}
                </span>
            </template>
            {{ $moment(item.DataIns, 'YYYY-MM-DD LTS').format('llll')}}
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>

  <v-card v-else height="408">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <jumper class="text-center"></jumper>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { Jumper } from "vue-loading-spinner";

export default {
  props: {
    items: Array,
    show: Boolean,
  },
  components: {
    Jumper,
  },
  data() {
    return {
      headers: [
        { text: "Número", value: "Numero", align: "center" },
        { text: "Paciente", value: "Nome" },
        { text: "Procedimento", value: "Procedimento" },
        { text: "Turno", value: "Turno" },
        { text: "Tempo decorrido", value: "DataConsulta" },
      ],
    };
  },
  methods: {
    statusColor(type) {
      return type === 'N' ? 'green' : 'red'
    }
  },
};
</script>
