import AlunoBoletim from '../views/aluno/AlunoBoletim.vue'
import AlunoAulas from "../views/aluno/AlunoAulas";
import AlunoFrequencia from "../views/aluno/AlunoFrequencia";
import { permissionChecker } from '../utils/permission';

export default [
    {
        path: '/aluno/boletim',
        name: '/alunoboletim',
        component: AlunoBoletim,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|BOLETIM')
        }
    },
    {
        path: '/aluno/aulas',
        name: '/alunoaulas',
        component: AlunoAulas,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|MINHAS_AULAS')
        }
    },
    {
        path: '/aluno/frequencia',
        name: '/alunofrequencia',
        component: AlunoFrequencia,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|FREQUENCIA')
        }
    },

];
