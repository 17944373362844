<template>
    <div>
    <div :class="$vuetify.breakpoint.mdAndDown ? 'container' : 'container-fluid'">
        <v-row>

            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                        PREFEITURA MUNICIPAL DE MACAÍBA - FOLHA DE PAGAMENTO
                    </h4>
                    <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> PAINEL DE INFORMAÇÕES FINANCEIRAS </h5>
                </v-card>
            </v-col>
        
        </v-row>
        <v-row class="col-lg-12">
            <div :class="filtroOrgao != null ? 'col-xl-12 col-md-12 mb-2 col-lg-4' : 'col-xl-12 col-md-12 mb-2 col-lg-6'">
                <filtroAno @messageFromChild="filtrarPorAno"></filtroAno>
            </div>
            <div :class="filtroOrgao != null ? 'col-xl-12 col-md-12 mb-2 col-lg-4' : 'col-xl-12 col-md-12 mb-2 col-lg-6'">
                <filtroOrgao @messageFromChild="filtrarPorOrgao"></filtroOrgao>
            </div>
            <div v-if="filtroOrgao != null" class="col-xl-12 col-md-12 mb-2 col-lg-4">
                <filtroSetor @messageFromChild="filtrarPorSetor" :codOrgao="filtroOrgao"></filtroSetor>
            </div>
        </v-row>
        
        <div v-if="filtroSetor != null && filtroOrgao != null">
        <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Evolução da folha anual por setor</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>  Veja a evolução da folha anual para os últimos <br> cinco anos dentro do seu respectivo setor.</span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <linha :cor="'#47bcee'" :dados="dadosfolhaAnualSetor" :key="evolucaofolhasetorlongodosanoskey"/>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Evolução da folha mensal por setor</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Acompanhe o progresso da folha de pagamento <br> para o setor selecionado no decorrer dos meses. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <linha :cor="'#F28683'" :dados="dadosfolhaAnoSetor" :key="evolucaofolhasetorlongodosmeseskey"></linha>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Folha de pagamento completa anual</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Veja a folha de pagamento completa ao longo dos últimos cinco anos. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <linha :cor="'#3bdb7d'" :dados="dadosPagamento" :key="pagamentokey"></linha>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Folha de pagamento completa mensal</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Confira a folha de pagamento completa <br> para os últimos meses no decorrer do ano. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <linha :cor="'#8e81db'" :dados="dadosAnoEspecifico" :key="evolucaofolhalongodosmeseskey"></linha>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="d-flex align-center pl-8 md-12 lg-6">
                <div class="col-lg-12 col-xl-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Centro de custo</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Aqui é possível verificar o centro de custo e o valor <br> utilizado até o mês atual dentro do ano selecionado. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body py-7">
                        <tabela :dados="dadosCentroCusto" :key="centrocustokey"></tabela>  
                        </div>
                    </div>
                </div>
            </v-col>

            <v-col class="d-flex align-center pl-8 md-12 lg-6">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Remuneração por cargo</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Neste campo podemos observar a remuneração <br>por cargo até o mês atual para o ano selecionado. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <tabela :dados="dadosCargo" :key="porcargokey"></tabela>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Colaboradores por faixa etária | Total de funcionários: {{ this.faixaetariaTotal }}</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Veja aqui o total de colaboradores do respectivo órgão <br> e dentro do ano selecionado de acordo com cada faixa etária. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <barra1 :cor="'#269ffb'" :dados="faixaetariaDados" :key="faixaetariaKey"></barra1>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Tempo de casa | Total de funcionários: {{ this.tempoTotal }}</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Confira o total de colaboradores do respectivo órgão <br> e dentro do ano selecionado  de acordo com o tempo de serviço prestado. Observação: alguns servidores apresentam <br> mais de um vínculo de trabalho. </span>
                            </v-tooltip>
                        </div>
                        <div class="card-body">
                            <barra1 :cor="'#17E2E3'" :dados="tempoDados" :key="tempodecasakey"></barra1>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        
        <v-row>

            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Folha de pagamento por vínculo empregatício</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>  Acompanhe a folha de pagamento por vínculo empregatício dentro do ano selecionado. </span>
                            </v-tooltip>
                        </div>
                        <div id="barraVinculo" class="card-body">
                            <barra1 :cor="'#3181b5'" :dados="vinculoDados" :key="vinculoempregaticiokey"></barra1>  
                        </div>
                    </div>
                </div>
            </v-col>
            
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Percentual de colaboradores por sexo | Total de funcionários: {{ this.sexoTotal }}</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Observe o percentual de colaboradores segundo a sua classificação por sexo. </span>
                            </v-tooltip>
                        </div>
                        <div id="pizzaSexo" class="card-body">
                            <pizza3 @messageFromChild="getSexoTotal" :dados="dadosSexo" :key="sexokey"></pizza3>
                        </div>
                    </div>
                </div>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="d-flex align-center pl-8">
                <div class="col-xl-12 col-lg-12">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Quantitativo de colaboradores por níveis de escolaridade</h6>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    mdi-information-outline
                                    </v-icon>
                                </template>
                                <span> Aqui é possível observar o quantitativo de colaboradores <br> dentro de seu respectivo nível escolar.</span>
                            </v-tooltip>
                        </div>
                        <div id="barraEscolaridade" class="card-body">
                            <barra1 :cor="'#3181b5'" :dados="escolaridadeDados" :key="escolaridadekey"></barra1>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
        </div>
    </div>
    </div>
</template>

<script>
import pizza3 from '@/components/folha/distribuicaoFuncionariosPorSexo.vue'
import barra1 from '@/components/folha/valorTotalAoLongoDosAnos.vue'
import tabela from '@/components/folha/valoresTotaisNoAnoPorCargo.vue'
import linha from '@/components/folha/FolhaGraficoLinha.vue'
import filtroAno from '@/components/folha/filtroFolhaPagamento.vue'
import filtroSetor from '@/components/folha/filtroSetor.vue'
import filtroOrgao from '@/components/folha/filtroOrgao.vue'

export default {
    components: {
        linha,
        pizza3,
        barra1,
        tabela,
        filtroAno,
        filtroSetor,
        filtroOrgao
    },
    data () {
        return {
            filtroAno: new Date().getFullYear(),
            filtroSetor: null,
            filtroOrgao: null,

            porcargokey: null,
            centrocustokey: null,

            valor: [],
            ano: [],

            dadosfolhaAnoSetor: [],
            evolucaofolhasetorlongodosmeseskey: 0,

            dadosPagamento: [],
            pagamentokey: 0,

            dadosfolhaAnualSetor: [],
            evolucaofolhasetorlongodosanoskey: 0,
            
            evolucaofolhalongodosmeseskey: 0,
            dadosAnoEspecifico: [],
            
            folhaAno: [],
            folhaCodSetor: [],
            folhaSetor: [],
            folhaValor: [],

            setorAno: [],
            setorCodSetor: [],
            setorSetor: [],
            setorValor: [],

            vinculoDados: [],
            vinculoempregaticiokey: 0,

            dadosCentroCusto: [],

            dadosSexo: [],
            sexoTotal: null,
            sexokey: null,

            escolaridadeDados: [],
            escolaridadekey: null,

            faixaetariaDados: [],
            faixaetariaKey: 0,
            faixaetariaTotal: null,

            tempoDados: [],
            tempodecasakey: 0,
            tempoTotal: null,

            dadosCargo: []
        }
    },
    methods: {

        async distribuicaoFuncionariosPorSexoPizza(){
            await this.axios.get('folha/sexo', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.dadosSexo = res.data
            }).finally(() => {
                this.sexokey++
            })
        },

        async pagamentoAoLongoDosAnosBarra(){
            await this.axios.get('folha/pagamento', {
                params: {
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.dadosPagamento = res.data
            }).finally(() => {
                this.pagamentokey++
            })
        },

        async valorTotalPorCargoTabela(){
            await this.axios.get('folha/distribuicao', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.dadosCargo = res.data
            })
            .finally(() => {
                this.porcargokey++
            })
        },
        
        async valorTotalAnoEspecificoBarra(){
            await this.axios.get('folha/anoespecifico', {
                params:{
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.dadosAnoEspecifico = res.data
            })
            .finally(() => {
                this.evolucaofolhalongodosmeseskey++
            })
        },
        async valorPorSetorDaFolhaDePagamentoBarra(){
            await this.axios.get('folha/folha', {
                params: {
                    codOrgao: this.filtroOrgao,
                    codSetor: this.filtroSetor
                }
            }).then(res => {
                this.dadosfolhaAnualSetor = res.data
            })
            .finally(() => {
                this.evolucaofolhasetorlongodosanoskey++
            })
        },
        async valoresPorSetorAoLongoDoAnoBarra(){
            await this.axios.get('folha/setor', {
                params: {
                    codOrgao: this.filtroOrgao,
                    codSetor: this.filtroSetor,
                    ano: this.filtroAno
                }
            }).then(res => {
                this.dadosfolhaAnoSetor = res.data
            })
            .finally(() => {
                this.evolucaofolhasetorlongodosmeseskey++
            })
        },
        async valoresTotaisNoAnoPorVinculoPizza(){
            await this.axios.get('folha/vinculo', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.vinculoDados = res.data
            })
            .finally(() => {
                this.vinculoempregaticiokey++
            })
        },
        async valoresTotaisNoAnoPorCentroDeCustoPizza(){
            await this.axios.get('folha/centrocusto', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.dadosCentroCusto = res.data
            })
            .finally(() => {
                this.centrocustokey++
            })
        },
        
        async distribuicaoFuncionariosPorEscolaridadePizza(){
            await this.axios.get('folha/escolaridade', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.escolaridadeDados = res.data
            })
            .finally(() => {
                this.escolaridadekey++
            })
        },
        async distribuicaoFuncionariosPorFaixaEtariaPizza(){
            await this.axios.get('folha/faixaetaria', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.faixaetariaDados = res.data
                this.faixaetariaTotal = null
                
                for(let i = 0; i < res.data.length; i++)
                    this.faixaetariaTotal += res.data[i].Quantidade
            })
            .finally(() => {
                this.faixaetariaKey++
            })
        },
        async distribuicaoFuncionariosPorTempoDeCasaPizza(){
            await this.axios.get('folha/tempo', {
                params: {
                    ano: this.filtroAno,
                    codOrgao: this.filtroOrgao
                }
            }).then(res => {
                this.tempoDados = res.data
                this.tempoTotal = null
                
                for(let i = 0; i < res.data.length; i++)
                    this.tempoTotal += res.data[i].Quantidade
            })
            .finally(() => {
                this.tempodecasakey++
            })
        },

        getSexoTotal(value){
            this.sexoTotal = value
        },

        filtrarPorAno(value){
            this.filtroAno = value

            this.valoresPorSetorAoLongoDoAnoBarra()
            this.distribuicaoFuncionariosPorTempoDeCasaPizza()
            this.valorTotalAnoEspecificoBarra()
            this.distribuicaoFuncionariosPorSexoPizza()
            this.distribuicaoFuncionariosPorEscolaridadePizza()
            this.distribuicaoFuncionariosPorFaixaEtariaPizza()
            this.valorTotalPorCargoTabela()
            this.valoresTotaisNoAnoPorCentroDeCustoPizza()
            this.valoresTotaisNoAnoPorVinculoPizza()
        },
        filtrarPorSetor(value){
            if(value != null)
            {
                this.filtroSetor = value

                this.valorPorSetorDaFolhaDePagamentoBarra()
                this.valoresPorSetorAoLongoDoAnoBarra()
                this.distribuicaoFuncionariosPorTempoDeCasaPizza()
                this.valorTotalAnoEspecificoBarra()
                this.distribuicaoFuncionariosPorSexoPizza()
                this.distribuicaoFuncionariosPorEscolaridadePizza()
                this.pagamentoAoLongoDosAnosBarra()
                this.valoresPorSetorAoLongoDoAnoBarra()
                this.distribuicaoFuncionariosPorFaixaEtariaPizza()
                this.valorTotalPorCargoTabela()
                this.valoresTotaisNoAnoPorCentroDeCustoPizza()
                this.valoresTotaisNoAnoPorVinculoPizza()
            }
        },
        filtrarPorOrgao(value) {
            this.filtroOrgao = value
        },
    }
}
</script>

<style>
    #particles-js {
        width: 100%;
        height: 30000px;
    }
    @media screen and (min-width: 1264px) and (max-width: 1366px) {
        #pizzaSexo {
            height: 21rem;
            width: 18rem;
            margin-left: auto; 
            margin-right: auto;
            margin-bottom: -9rem;
            margin-top: -1rem;
        }
        #barraVinculo {
            height: 25rem;
            width: 18rem;
            margin-left: -1rem; 
            margin-right: auto;
            margin-bottom: -13rem;
            margin-top: -1rem;
        }
        #barraEscolaridade {
            height: 25rem;
            width: 19rem;
            margin-left: -2rem; 
            margin-right: auto;
            margin-bottom: -13rem;
            margin-top: -1rem;
        }
    }
    @media screen and (min-width: 1367px) and (max-width: 1667px) {
        #pizzaSexo {
            height: 16rem;
            width: 16rem;
            padding: 0rem;
            margin-left: auto; 
            margin-right: auto;
            margin-bottom: -5rem;
            margin-top: 1rem;
        }
        #barraVinculo {
            height: 18rem;
            width: 21rem;
            margin-left: -1.3rem;
            margin-right: auto;
            margin-bottom: -5rem;
            margin-top: -1rem;
        }
        #barraEscolaridade {
            height: 19rem;
            width: 22rem;
            margin-left: -1.9rem; 
            margin-right: auto;
            margin-bottom: -5rem;
            margin-top: -1rem;
        }
    }
    @media screen and (min-width: 1668px) and (max-width: 1920px) {
        #pizzaSexo {
            height: 18rem;
            width: 20rem;
            padding: 0rem;
            margin-left: auto; 
            margin-right: auto;
            margin-bottom: -3rem;
            margin-top: 1rem;
        }
        #barraVinculo {
            height: 18rem;
            width: 27rem;
            margin-left: -1.3rem;
            margin-right: auto;
            margin-bottom: -1rem;
            margin-top: -1rem;
        }
        #barraEscolaridade {
            height: 19rem;
            width: 28rem;
            margin-left: -1.9rem; 
            margin-right: auto;
            margin-bottom: -1rem;
            margin-top: -1rem;
        }
    }
</style>