<template>
    <div id="chart">
        <apexchart type="area" ref="chart" :options="chartOptions" :series="series" :key='$vnode.key'></apexchart>
    </div> 
</template>

<script>

export default {
    props: {
        dados: Array,
        cor: String
    },
    data() {
        return {
            series: [
                {
                    name: 'Valor',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (val) { return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) }
                    }
                },
                colors: [this.$props.cor],
                dataLabels: {
                    offsetY: -10,
                    enabled: true,
                    formatter: function (val) {return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})},
                    textAnchor: 'end',
                    style: {fontSize: '10px'},
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: this.$props.dados[0].Mes ? ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] : [new Date().getFullYear() - 5, new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()],
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (val) { return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) }
                    },
                }
            },      
        }
    },
    mounted(){
        for(let i = 0; this.$props.dados.length; i++)
            this.series[0].data.push(this.$props.dados[i].Valor)
    }
}
</script>
