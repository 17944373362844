<template>
    <div id="chartPercentual">
        <apexchart type="bar" height="350" :options="chartOptionsPercentual" :series="seriesPercentual"></apexchart>
    </div>
</template>

<script>

import pt from "apexcharts/dist/locales/pt-br.json"

export default {
    props: {
        idUnidade: Number,
        idAno: Number,
        idSerie: Number,
    },
    data() {
        return {
            obj: {
                ano: this.$props.idAno,
                idUnidade: this.$props.idUnidade,
                idserie: this.$props.idSerie
            },
            seriesPercentual: [{
                name: 'Matriculados',
                data: []
            }, {
                name: 'Vagas disponíveis',
                data: []
            }],
            chartOptionsPercentual: {
                chart: {
                    locales: [pt],
                    defaultLocale: "pt-br",
                    type: 'bar',
                    height: 600,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: "center",
                    text: "Nenhum dado disponível até o momento",
                    verticalAlign: "middle"
                },
            },
        }
    },
    methods: {
        getChartPercentual(){
            this.axios.get('escola/escolas/percentual', {
                params: {
                    idUnidade: this.$props.idUnidade,
                    ano: this.$props.idAno
                }
            }).then((res) => {
                
                this.resetSeries()

                for(var i=0; i < res.data.length; i++){
                    this.seriesPercentual[0].data.push(res.data[i].Matriculados)
                    this.seriesPercentual[1].data.push(res.data[i].Vagas)
                    this.chartOptionsPercentual.xaxis.categories.push(res.data[i].Serie)
                }
            })
        },
        resetSeries(){
            this.seriesPercentual = [
                    {
                        ...this.seriesPercentual[0],
                        ...{
                            data: []
                        }
                    },
                    {
                        ...this.seriesPercentual[1], 
                        ...{
                            data: []
                        }
                    }
                ]
            this.chartOptionsPercentual = {
                xaxis: {
                    categories: []
                }
            }
        }
    },
    mounted(){
        this.getChartPercentual()
    }
}
</script>
