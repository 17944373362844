<template>
    <div id="chart">
        <apexchart type="bar" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div> 
</template>

<script>

export default {
    props: {
        dados: Array,
        cor: String
    },
    data() {
        return {
            months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            series: [
                {
                    name: this.$props.dados[0].Quantidade ? 'Quantidade' : 'Valor',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 600,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: this.$props.dados[0].Valor ? function (val) { return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}) } : function (val) { return val + ' Colaboradores' }
                    }
                },
                colors: [this.$props.cor],
                dataLabels: {
                    enabled: true,
                    formatter: this.$props.dados[0].Valor ? function (val) {return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} : function(val){return val},
                    offsetX: this.$props.dados[0].FaixaEtaria ? 0 : 85,
                    offsetY: this.$props.dados[0].FaixaEtaria ? -40 : 0,
                    style: this.$props.dados[0].FaixaEtaria ? {fontSize: '10px', colors: ["#000000"]} : {fontSize: '12px', colors: ["#000000"]}
                },
                plotOptions: {
                    bar: {
                        horizontal: this.$props.dados[0].FaixaEtaria ? false : true,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                    labels:{
                        show: this.$props.dados[0].FaixaEtaria ? true : false
                    }
                },
                yaxis: {
                    labels: {
                        show: this.$props.dados[0].FaixaEtaria ? false : true
                    },
                }
            },      
        }
    },
    methods: {
        getTruth(array, size, index){
            let month = []

            for(let i = 0; i < size; i++)
                month[i] = this.months[i]

            if(array.Mes)
                this.chartOptions.xaxis.categories.push(month[index])
            else if(array.Ano && array.FaixaEtaria)
                this.chartOptions.xaxis.categories.push(array.FaixaEtaria)
            else if(array.Ano && array.TipoVinculo)
                this.chartOptions.xaxis.categories.push(array.TipoVinculo)
            else if(array.Ano && array.Escolaridade)
                this.chartOptions.xaxis.categories.push(array.Escolaridade)
            else if(array.Ano)
                this.chartOptions.xaxis.categories.push(array.Ano.toString())
        }
    },
    mounted(){
        for(let i = 0; this.$props.dados.length; i++){
            this.$props.dados[i].Quantidade ? this.series[0].data.push(this.$props.dados[i].Quantidade) : this.series[0].data.push(this.$props.dados[i].Valor)
            this.getTruth(this.$props.dados[i], this.$props.dados.length, i)
        }
    }
}
</script>
