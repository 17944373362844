<template>
  <div>
    <div class="page">
      <div class="container-fluid">
        <br>
        <v-row>
  
            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                        PREFEITURA MUNICIPAL DE MACAÍBA - AVALIAÇÕES
                    </h4>
                    <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> PAINEL DE INFORMAÇÕES DAS AVALIAÇÕES </h5>
                </v-card>
            </v-col>
        
        </v-row>
  
        <v-row>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol">
            <v-select
                v-model="IdAno"
                :items="anos"
                label="Filtrar pelo ano letivo"
                @change="[getChartFreq($event)]"
                hide-details
                outlined
                dense
            ></v-select>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : '6'">
            <v-autocomplete
                style="position: relative; z-index: 3"
                v-model="IdEscola"
                :items="escolas"
                item-text="Unidade"
                item-value="IdUnidade"
                label="Filtrar pela escola"
                @change="[getChartFreq($event)]"
                hide-details
                outlined
                dense
            ></v-autocomplete>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : dynamicCol" v-if="IdEscola != null || IdCodis != null || IdAno != null">
            <v-btn color="primary" width="100%" v-bind:id="dynamicCol='3'" @click="[limparFiltros()]"
                  dark>
              Limpar Filtros
            </v-btn>
          </v-col>
        </v-row>
  
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card shadow mb-4">
              <!-- Card Header - Dropdown -->
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Notas e frequências por segmento e disciplina</h6>
              </div>
              <!-- Card Body -->
              <div class="card-body" style="max-width: 1600px;z-index: 0;position:relative;">
                <apexchart v-if="showChartFreq" type="line" height="350" :options="freq.chartOptions" :series="freq.series"></apexchart>
                <div v-else class="loading-spinner">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  Carregando dados...
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card shadow mb-4">
              <!-- Card Header - Dropdown -->
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Notas e frequências por segmento e ano</h6>
              </div>
              <!-- Card Body -->
              <div class="card-body" style="max-width: 1600px;">
                <apexchart v-if="showChartFreq2" type="line" height="350" :options="freq2.chartOptions" :series="freq2.series"></apexchart>
                <div v-else class="loading-spinner">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  Carregando dados...
                </div>
              </div>
            </div>
          </div>
        </div>
             <!--
             <div class="row">
               <div class="col-xl-12 col-lg-12">
                 <div class="card shadow mb-4">
                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Avaliações gerais por segmento</h6>
                   </div>
                   <div class="card-body" style="max-width: 1600px;">
                     <apexchart type="bar" height="350" :options="column.chartOptions" :series="column.series"></apexchart>
                   </div>
                 </div>
               </div>
             </div>
  
             <div class="row">
  
               <div class="col-xl-6 col-lg-6">
                 <div class="card shadow mb-4">
                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Percentual de alunos/seguimento abaixo da média</h6>
                   </div>
                   <div class="card-body">
                     <div class="pt-4 pb-2">
                       <apexchart type="pie" width="380" :options="pie.chartOptions" :series="pie.series"></apexchart>
                     </div>
                   </div>
                 </div>
               </div>
  
               <div class="col-xl-6 col-lg-6">
                 <div class="card shadow mb-4">
                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Avaliação média</h6>
                   </div>
                   <div class="card-body" style="max-width: 760px;">
                     <div class="pt-4 pb-2">
                        <apexchart type="bar"  :options="chartOptions" :series="series"></apexchart> 
                       <apexchart type="bar" height="350" :options="ava.chartOptions" :series="ava.series"></apexchart>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           -->  
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import pt from "apexcharts/dist/locales/pt-br.json"
  
  export default {
    name: 'Home',
    data: () => {
      return {
        showChartFreq: false,
        showChartFreq2: false,
        codis: [],
        IdCodis: null,
        escolas: [],
        usuario:[],
        IdEscola: null,
        DisciplinaSerie: null,
        dynamicCol: 4,
        anos: [],
        IdAno: new Date().getFullYear(),
        freq: {
          series: [{
            name: 'Notas',
            type: 'column',
            data: []
          }, {
            name: 'Frequências',
            type: 'line',
            data: []
          }],
          chartOptions: {
            chart: {
              locales: [pt],
              defaultLocale: "pt-br",
              height: 350,
              type: 'line',
            },
            stroke: {
              width: [0, 4]
            },
            title: {
              text: 'Notas e frequências das disciplinas'
            },
            dataLabels: {
              // enabledOnSeries: [1],
              enabled: true,
              formatter: function (val) {
                if(val>10){
                  return val + '%';
                }else{
                  return val;
                }
              },
            },
            labels: [],
            xaxis: {
              type: 'string'
            },
            yaxis: [{
              labels: {
                formatter: function (val) {
                  return val
                },
              },
              title: {
                text: 'Notas',
              },
            }, {
              opposite: true,
              title: {
                text: 'Frequências'
              }
            }]
          },
        },
        freq2: {
          series: [{
            name: 'Notas',
            type: 'column',
            data: []
          }, {
            name: 'Frequências',
            type: 'line',
            data: []
          }],
          chartOptions: {
            plotOptions: {
              columnWidth: '55%',
            },
            chart: {
              locales: [pt],
              defaultLocale: "pt-br",
              height: 350,
              type: 'line',
            },
            stroke: {
              width: [0, 4]
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom'
                }
              }
            }],
            title: {
              text: 'Notas e frequências dos anos'
            },
            dataLabels: {
              // enabledOnSeries: [1],
              enabled: true,
              formatter: function (val) {
                if(val>10){
                  return val + '%';
                }else{
                  return val;
                }
              },
            },
            labels: [],
            xaxis: {
              type: 'string'
            },
            yaxis: [{
              labels: {
                formatter: function (val) {
                  return val
                }
              },
              title: {
                text: 'Notas',
              },
            }, {
              opposite: true,
              title: {
                text: 'Frequências'
              }
            }]
          },
        },
        pie: {
          series: [20, 50, 30],
          chartOptions: {
            chart: {
              locales: [pt],
              defaultLocale: "pt-br",
              type: 'pie'
            },
            labels: ['Servidor', 'Professor', 'Serviço'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        },
        column: {
          series: [{
            name: 'Conteúdo',
            data: [4, 5, 4.8, 3, 4, 4.3]
          }, {
            name: 'Professor',
            data: [3.5, 4.2, 4.1, 3.8, 5, 4.4]
          }, {
            name: 'Serviço',
            data: [3.5, 4.1, 3.6, 2.6, 4.5, 4.6]
          }],
          chartOptions: {
            chart: {
              locales: [pt],
              defaultLocale: "pt-br",
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
            },
            yaxis: {
              title: {
                text: 'Média'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "Média " + val
                }
              }
            }
          },
        },
        ava: {
          series: [{
            name: 'Inflation',
            data: [4.0, 4.7, 4.0, 4.8, 3.9, 4.3]
          }],
          chartOptions: {
            chart: {
              locales: [pt],
              defaultLocale: "pt-br",
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val;
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            xaxis: {
              categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun"],
              position: 'top',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val;
                }
              }
            },
            title: {
              text: '',
              floating: true,
              offsetY: 330,
              align: 'center',
              style: {
                color: '#444'
              }
            }
          },
        },
      }
    },
    methods: {
      getAnos(){
        for(let a = new Date().getFullYear(); a >= new Date().getFullYear() - 8; a--){
          this.anos.push(a);
        }
      },
      getCodis(){
        this.axios.get('escola/escolas/codis').then(res=>{
          this.codis = res.data
        })
      },
      getEscolas(){
        this.axios.get('escola/user/escolas', {
          params: {
            IdCodis: this.IdCodis
          }
        }).then(res=>{
          this.escolas = res.data.filter(unidade => {
            unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
            if(unidade.IdUnidadeTipo === 1) {
              return unidade;
            }
          })
        })
      },
      getChartFreq(){
        if(sessionStorage.getItem('professor') === 'public'){
          this.axios.get('escola/escolas/notafrequenciaprofessorDisciplina', {
            params: {
              IdCodis: this.IdCodis,
              IdEscola: this.IdEscola,
              IdAno: this.IdAno
            }
          }).then(res => {
            if(this.showChartFreq == true){
              this.freq.series = [{...this.freq.series[0], ...{
                  data: []
                }
              },{...this.freq.series[1], ...{
                  data: []
                }
              }]
              this.freq.chartOptions = {
                  labels: []
              }
            }
            for (var i = 0; i < res.data.length; i++) {
              this.freq.series[0].data.push(res.data[i].MediaFinal)
              this.freq.series[1].data.push(res.data[i].PercentualFrequencia)
              this.freq.chartOptions.labels.push(res.data[i].Disciplina)
            }
          })
          this.axios.get('escola/escolas/notafrequenciaprofessorSerie', {
            params: {
              IdCodis: this.IdCodis,
              IdEscola: this.IdEscola,
              IdAno: this.IdAno
            }
          }).then(res => {
            if(this.showChartFreq2 == true) {
              console.log(this.freq2.chartOptions.labels)
              this.freq2.series = [{
                ...this.freq2.series[0], ...{
                  data: []
                }
              }, {
                ...this.freq2.series[1], ...{
                  data: []
                }
              }]
              this.freq2.chartOptions = {
                labels: []
              }
            }
            for (var i = 0; i < res.data.length; i++) {
              this.freq2.series[0].data.push(res.data[i].MediaFinal)
              this.freq2.series[1].data.push(res.data[i].PercentualFrequencia)
              this.freq2.chartOptions.labels.push(res.data[i].Serie)
            }
          })
              .finally(() => {
                this.showChartFreq = true
                this.showChartFreq2 = true
              })
        }else {
          this.axios.get('escola/escolas/notafrequenciadisciplina', {
            params: {
              IdCodis: this.IdCodis,
              IdEscola: this.IdEscola,
              IdAno: this.IdAno
            }
          }).then(res => {
            if (this.showChartFreq == true) {
              this.freq.series = [{
                ...this.freq.series[0], ...{
                  data: []
                }
              }, {
                ...this.freq.series[1], ...{
                  data: []
                }
              }]
              this.freq.chartOptions = {
                labels: []
              }
            }
            for (var i = 0; i < res.data.length; i++) {
              this.freq.series[0].data.push(res.data[i].MediaFinal)
              this.freq.series[1].data.push(res.data[i].PercentualFrequencia)
              this.freq.chartOptions.labels.push(res.data[i].Disciplina)
            }
          })
          this.axios.get('escola/escolas/notafrequenciaserie', {
            params: {
              IdCodis: this.IdCodis,
              IdEscola: this.IdEscola,
              IdAno: this.IdAno
            }
          }).then(res => {
            if (this.showChartFreq2 == true) {
              this.freq2.series = [{
                ...this.freq2.series[0], ...{
                  data: []
                }
              }, {
                ...this.freq2.series[1], ...{
                  data: []
                }
              }]
              this.freq2.chartOptions = {
                labels: []
              }
            }
            for (var i = 0; i < res.data.length; i++) {
              this.freq2.series[0].data.push(res.data[i].MediaFinal)
              this.freq2.series[1].data.push(res.data[i].PercentualFrequencia)
              this.freq2.chartOptions.labels.push(res.data[i].Serie)
            }
          })
              .finally(() => {
                this.showChartFreq = true
                this.showChartFreq2 = true
              })
        }
      },
      limparFiltros(){
        this.IdAno = null
        this.IdCodis = null
        this.IdEscola = null
        this.dynamicCol = 4
        this.getEscolas()
        this.getChartFreq()
      }
    },
    mounted() {
      this.getAnos()
      this.getCodis()
      this.getEscolas()
      this.getChartFreq()
    }
  }
  </script>