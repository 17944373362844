<template>
    <div class="page">
        <br />
        <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Relação de diretores e vice-diretores</h1>
            </div>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <v-text-field
                    v-model="search"
                    prepend-icon="mdi-feature-search-outline"
                    label="Pesquisar"
                    clearable
                ></v-text-field> 
            </div>
            <v-data-table
                    :headers="headersDiretores"
                    :items="diretores"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando...">
            </v-data-table>
        </div>
    </div>
</template>

<script>
import latinize from 'latinize'
export default {
    data () {
        return {
            anos: [],
            diretores:[],
            nomeDiretor:null,
            nomeViceDiretor: null,
            IdAno: 2019,
            IdUnidade: null,
            unidadesList: null,
            unidadesList2:null,
            loading: false,
            search: null,
            headersDiretores:
            [
                {text:'Nome da escola', value:'Unidade', width:'40%'},
                {text:'Nome do diretor', value:'Diretor', width:'30%'},
                {text:'Nome do vice', value:'Vice', width:'30%'},
            ]
        }
    },
    computed: {
        isDisabledAdmin() {
            return this.acesso
        }
    },
    methods: {
        getUnidades(){
            this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
            this.axios.get('escola/user/escolas').then((res) => {
                this.unidadesList = res.data.filter(unidade => {
                unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
                if(unidade.IdUnidadeTipo === 1) {
                    return unidade;
                }
                })
            }).finally(()=>{
                this.getFuncionarios()
            })
        },
        getDiretores(){
            this.axios.get('/escola/escolas/diretores').then((res) => {
                
                for(let i = 0; i < res.data.length; i++)
                {

                    let obj = 
                    {
                        Diretor: '',
                        IdUnidade: null,
                        Unidade: '',
                        Vice: ''
                    }

                    obj.Diretor = latinize(res.data[i].Diretor);
                    obj.IdUnidade = res.data[i].IdUnidade;
                    obj.Unidade = latinize(res.data[i].Unidade);
                    obj.Vice = latinize(res.data[i].Vice);

                    this.diretores.push(obj);
                }

            }).finally(() => {
                this.loading = false
            })
        },
        limparFiltros(){
            this.IdAno = 2019
            this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
            this.dynamicCol = 6
            this.getFuncionarios()
        }
    },
    mounted() {
        this.getDiretores()
    }
}
</script>
