import FinanceiroFolhaPagamento from '../views/financeiro/FinanceiroFolhaPagamento.vue'
import FinanceiroTopDown from '../views/financeiro/FinanceiroTopDown.vue'
import { permissionChecker } from '../utils/permission';

export default [
    {
        path: '/financeiro/folha',
        name: 'Folha de Pagamento na Educação',
        component: FinanceiroFolhaPagamento,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_FINANCEIRO|FOLHA_PAGAMENTO')
        }
    },
    {
        path: '/financeiro/topdown',
        name: 'Dados financeiros da topdown',
        component: FinanceiroTopDown,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_FINANCEIRO|DESPESAS_RECEITAS')
        }
    }
]