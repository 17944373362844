import FormularioHabitacional from '../views/assistencia/FormularioHabitacional.vue'
import { permissionChecker } from '../utils/permission';

export default [ 
    {
        path: '/assistencia/formularios',
        name: 'formulariohabitacional',
        component: FormularioHabitacional,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_MATERIAIS|MATERIAS')
        }
    }    
];
