<template>
    <v-autocomplete
        v-model="id"
        :items="orgaos"
        item-text="name"
        item-value="id"
        label="Filtrar por orgão"
        hide-details
        outlined
        dense
    />
</template>

<script>
export default {
    data() {
        return {
            id: null,
            orgaos: []
        }
    },

    watch: {
        id(newValue) {
            this.$emit('messageFromChild', newValue);
        }
    },

    mounted() {
        this.axios.get('folha/orgaos')
        .then(res => {
            this.orgaos = res.data.map(item => (
                {
                    id: item.codOrgao,
                    name: item.Orgao
                }
            ))
        })
    }
}
</script>
