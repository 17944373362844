<template>
    <div>
        <div class="container-fluid">
            <v-row>
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                            PREFEITURA MUNICIPAL DE MACAÍBA - DADOS BPA
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">PAINEL DE INFORMAÇÕES BPA</h5>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-autocomplete
                    v-model="IdAno"
                    :items="anos"
                    class="ml-3 mr-3"
                    label='Ano'
                    dense
                    solo
                ></v-autocomplete>
                <v-autocomplete
                    v-model="idMes"
                    :items='meses'
                    class="ml-3 mr-3"
                    label='Mês'
                    item-value='number'
                    item-text='name'
                    dense
                    solo
                ></v-autocomplete>
                <v-autocomplete
                    v-model="IdUnidade"
                    :items="unidades"
                    class="ml-3 mr-3"
                    item-text="Unidade"
                    item-value="Id"
                    label="Filtrar pela unidade"
                    clearable
                    dense
                    solo
                ></v-autocomplete>
            </v-row>
            <v-row>
                <v-col cols=12 lg=4>
                    <v-card>
                        <v-card-title class='card-header py-3 d-flex text-primary'>
                            <span>Relação dos serviços contabilizados no BPA</span>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>Veja o total de serviços realizados e que são<br> 
                                    contabilizados no BPA; Tanto os atendimentos <br>
                                    agendados quanto os procedimentos realizados <br>
                                    durante o atendimento.
                                    </span>
                            </v-tooltip>
                        </v-card-title>                        
                        <v-card-text class='scrollable-chart'>
                            <apexchart 
                                type="bar"
                                :height='quantidadeExamesSolicitadosPorProcedimentoChartHeight'
                                :key="keyExamesPorProcedimento"
                                :options="quantidadeExamesSolicitadosPorProcedimentoOptions"
                                :series="quantidadeExamesSolicitadosPorProcedimentoSeries"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols=12 lg=4>
                    <v-card>
                        <v-card-title class='card-header py-3 d-flex text-primary'>
                            <span>BPA: estimativa de faturamento por procedimento</span>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>Veja a estimativa do faturamento, <br>
                                    conforme o código do BPA configurado <br>
                                    para cada procedimento.
                                    </span>
                            </v-tooltip>                            
                        </v-card-title>
                        <v-card-text class='scrollable-chart'>
                            <apexchart
                                type='bar'
                                :height='valoresExamesSolicitadosPorBPAChartHeight'
                                :key='keyValoresPorBPA'
                                :options='valoresExamesSolicitadosPorBPAOptions'
                                :series='valoresExamesSolicitadosPorBPASeries'
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols=12 lg=4>
                    <v-card>
                        <v-card-title class='card-header py-3 d-flex text-primary'>
                            <span>Atendimentos das áreas de medicina e enfermagem</span>
                            <v-spacer/>
                            <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>Veja os atendimento realizados de acordo com <br>cada área profissional na saúde.</span>
                            </v-tooltip>                            
                        </v-card-title>                        
                        <v-card-text>
                            <apexchart
                                type="bar"
                                height="340"
                                :key="keyAtendimentosMedicosEnfermeiros"
                                :options="quantidadeAtendimentosMedicosEnfermeirosOptions"
                                :series="quantidadeAtendimentosMedicosEnfermeirosSeries"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols=12>
                    <v-card>
                        <v-card-title class='card-header py-3 d-flex text-primary'>
                            <span>Número de atendimentos realizados nos últimos 12 meses</span>
                            <v-spacer></v-spacer>
                            <v-tooltip bottoms>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>Gráfico com o número de atendimentos de pessoas (CPF) <br>
                                    ao longo dos últimos meses, com dupla contagem.
                                    </span>
                            </v-tooltip>                            
                        </v-card-title>
                        <v-card-text>
                            <apexchart
                                type="line"
                                height="320"
                                :key="keyUrgencia24Horas"
                                :options="quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaOptions"
                                :series="quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaSeries"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols=12>
                    <v-card>
                        <v-card-title class='card-header py-3 d-flex text-primary'>
                            <span>BPA: estimativa de faturamento ao decorrer do ano</span>
                            <v-spacer></v-spacer>
                            <v-tooltip
                                bottom
                                class='text-justify'
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                Estimativa de faturamento do BPA durante o ano.<br/>
                                O levantamento de dados desconsidera os atendimentos com inconsistências de acordo com os critérios do Ministério da Saúde.
                            </v-tooltip>                            
                        </v-card-title>                        
                        <v-card-text>
                            <apexchart
                                type='line'
                                height='320'
                                :key='keyEvolucaoValoresExamesAno'
                                :series='evolucaoValoresExamesAnoSeries'
                                :options='evolucaoValoresExamesAnoOptions'
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import formatter from '../../utils/formatter'

export default {
    name:'SaudeProducaoBPA',

    data: () => ({
        unidades: [],

        idMes: new Date().getMonth() + (new Date().getMonth() == 0),
        IdAno: new Date().getFullYear(),
        IdUnidade: null,

        keyExamesPorProcedimento: 0,
        quantidadeExamesSolicitadosPorProcedimentoSeries: [{
            name: 'Quantidade',
            data: []
        }],
        quantidadeExamesSolicitadosPorProcedimentoOptions: {
            chart: {
                type: 'bar'
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    dataLabels: {
                        orientation: 'horizontal',
                        position: 'top'
                    }
                }
            },
            tooltip: {
                intersect: false,
                shared: true,
            },
            dataLabels: {
                enabled: true,
                offsetX: 25,
                style: {
                    colors: ['#000000']
                }
            },
            
            xaxis: {
                categories: []
            },
            yaxis: {
                labels: {
                    maxWidth: 300
              }
            }
        },

        keyValoresPorBPA: 0,
        valoresExamesSolicitadosPorBPASeries: [{
            name: 'Valor total',
            data: []
        }],
        valoresExamesSolicitadosPorBPAOptions: {
            chart: {
                type: 'bar'
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    dataLabels: {
                        orientation: 'horizontal',
                        position: 'top'
                    }
                }
            },
            tooltip: {
                intersect: false,
                shared: true,
                y : {
                    formatter: value => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: 35,
                formatter: value => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})),
                style: {
                    colors: ['#000000']
                }
            },
            xaxis: {
                categories: []
            },
        },

        keyAtendimentosMedicosEnfermeiros: 0,
        quantidadeAtendimentosMedicosEnfermeirosSeries: [
            {
                name: "Médicos",
                data: []
            },
            {
                name: "Enfermeiros",
                data: []
            }
        ],
        quantidadeAtendimentosMedicosEnfermeirosOptions: {
            chart: {
                type: 'bar',
                stacked: true,
                stackType:'100%'
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legends: {
                        position: 'bottom'
                    }
                }
            }],
            xaxis: {
                categories: ['Total'],
                labels: {
                    formatter: value => value + '%'
                }
            },
            yaxis: {
                labels: {
                    show: false
                }
            }
        },

        keyUrgencia24Horas: 0,
        quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaSeries: [{
            name: 'Quantidade',
            data: []
        }],
        quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaOptions: {
            chart: {
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', '#ffffff'],
                },
            },
            xaxis: {
                labels: {
                    formatter: formatter.numberToMonth
                },
                categories: [],
            },
        },

        keyEvolucaoValoresExamesAno: 0,
        evolucaoValoresExamesAnoSeries: [{
            name: 'Valor total',
            data: []
        }],
        evolucaoValoresExamesAnoOptions: {
            chart: {
                type: 'line',
                zoom: {
                enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', '#ffffff'],
                },
            },
            tooltip: {
                y : {
                    formatter: value => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
                }
            },
            yaxis: {
                labels: {
                    formatter: value => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'}))
                }
            },
            xaxis: {
                labels: {
                    formatter: formatter.numberToMonth
                },
                categories: [],
            },
        },
    }),

    computed: {
        anos() {
            const currYear = new Date().getFullYear();
            return [...Array(9).keys()].map(i => currYear - i);
        },
        meses() {
            return [...Array(12).keys()].map(i => ({
                number: i + 1,
                name: formatter.numberToMonth(i + 1)
            }))
        },
        
        quantidadeExamesSolicitadosPorProcedimentoChartHeight() {
            return 320 + 30*(this.quantidadeExamesSolicitadosPorProcedimentoSeries[0].data.length - 1);
        },
        valoresExamesSolicitadosPorBPAChartHeight() {
            return 320 + 30*(this.valoresExamesSolicitadosPorBPASeries[0].data.length - 1);
        },
        combinatedAnoUnidade() {
            return this.IdAno + this.IdUnidade;
        },
        combinatedMesAnoUnidade() {
            return this.idMes + this.combinatedAnoUnidade;
        }
    },

    watch: {
        combinatedMesAnoUnidade()
        {
            this.getQuantidadeAtendimentosMedicosEEnfermagem();
            this.getQuantidadeExamesSolicitadosPorProcedimento()
            this.getValoresExamesSolicitadosPorBPA()
        },
        combinatedAnoUnidade()
        {            
            this.getQuanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializada()
            this.getEvolucaoValoresExamesAno()
        }
    },

    methods: {
        async getUnidades() {
            this.unidades = (
                await this.axios.get('unidade', {
                    params: { 
                        idUnidadeSetor: 2 }
                    })
            ).data
        },
        getQuantidadeExamesSolicitadosPorProcedimento()
        {
            this.axios.get('producaobpa/quantidadeexamessolicitadosporprocedimento', {
                params: {
                    ano: this.IdAno,
                    mes: this.idMes,
                    IdUnidade: this.IdUnidade
                }
            })
            .then(res => {
                this.quantidadeExamesSolicitadosPorProcedimentoSeries[0].data = [];
                this.quantidadeExamesSolicitadosPorProcedimentoOptions.xaxis.categories = [];

                res.data.forEach(item => {
                    this.quantidadeExamesSolicitadosPorProcedimentoSeries[0].data.push(item.Quantidade)
                    this.quantidadeExamesSolicitadosPorProcedimentoOptions.xaxis.categories.push(item.Procedimento)
                })
            })
            .finally(() => {
                this.keyExamesPorProcedimento++;
            })  
        },
        getValoresExamesSolicitadosPorBPA()
        {
            this.axios.get('producaobpa/quantidadeExamesSolicitadosporcodigoBPA', {
                params: {
                    ano: this.IdAno,
                    mes: this.idMes,
                    IdUnidade: this.IdUnidade
                }
            })
            .then(res => {
                this.valoresExamesSolicitadosPorBPASeries[0].data = [];
                this.valoresExamesSolicitadosPorBPAOptions.xaxis.categories = [];

                res.data.forEach(item => {
                    this.valoresExamesSolicitadosPorBPASeries[0].data.push(item.valorTotal)
                    this.valoresExamesSolicitadosPorBPAOptions.xaxis.categories.push(item.descricao)
                })
            })
            .finally(() => {
                this.keyValoresPorBPA++;
            })  
        },
        getQuanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializada()
        {
            this.axios.get('producaobpa/quantidadeatendimentosurgenciaate24horasatencaoespecializada', {
                params: {
                    ano: this.IdAno,
                    IdUnidade: this.IdUnidade,
                }
            })
            .then(res => {
                this.quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaSeries[0].data = []
                this.quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaOptions.xaxis.categories = []

                res.data.forEach(element => {
                    this.quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaSeries[0].data.push(element.Quantidade)
                    this.quanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializadaOptions.xaxis.categories.push(element.MONTH)
                });
            })
            .finally(() => {
                this.keyUrgencia24Horas++;
            })
        },
        getQuantidadeAtendimentosMedicosEEnfermagem()
        {
            Promise.all([
                this.axios.get('producaobpa/quantidadeatendimentosmedicosupa', {
                    params: {
                        ano: this.IdAno,
                        mes: this.idMes,
                        IdUnidade: this.IdUnidade
                    }
                }),
                this.axios.get('producaobpa/quantidadeatendimentosenfermagem', {
                    params: {
                        ano: this.IdAno,
                        mes: this.idMes,
                        IdUnidade: this.IdUnidade
                    }
                })
            ]).then(responses => {
                this.quantidadeAtendimentosMedicosEnfermeirosSeries[0].data = [];
                this.quantidadeAtendimentosMedicosEnfermeirosSeries[1].data = [];

                responses.forEach((res, i) => {
                    res.data.forEach(item => {
                        this.quantidadeAtendimentosMedicosEnfermeirosSeries[i].data.push(item.Quantidade) 
                    })
                })
            })
            .finally(() => {
                this.keyAtendimentosMedicosEnfermeiros++;
            })
        },  
        getEvolucaoValoresExamesAno()
        {
            this.axios.get('producaobpa/valoresTotaisMesesBPA', {
                params: {
                    ano: this.IdAno,
                    IdUnidade: this.IdUnidade
                }
            })
            .then(res => {
                this.evolucaoValoresExamesAnoSeries[0].data = [];
                this.evolucaoValoresExamesAnoOptions.xaxis.categories = [];

                res.data.forEach(item => {
                    this.evolucaoValoresExamesAnoSeries[0].data.push(item.valorTotal)
                    this.evolucaoValoresExamesAnoOptions.xaxis.categories.push(item.Mes)
                });
            })
            .finally(() => {
                this.keyEvolucaoValoresExamesAno++;
            })
        },
    },

    async mounted()
    {
        await this.getUnidades()

        this.getQuantidadeAtendimentosMedicosEEnfermagem();
        this.getQuantidadeExamesSolicitadosPorProcedimento()
        this.getValoresExamesSolicitadosPorBPA()
        this.getQuanitdadeAtendimentosUrgenciaAte24HorasAtencaoEspecializada()
        this.getEvolucaoValoresExamesAno()
    }
}
</script>

<style scoped>
    .scrollable-chart {
        min-height: 370px;
        max-height: 370px;
        overflow-y: auto;
    }
</style>