import { render, staticRenderFns } from "./DashboardCardTotalList.vue?vue&type=template&id=755c55e2"
import script from "./DashboardCardTotalList.vue?vue&type=script&lang=js"
export * from "./DashboardCardTotalList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports