<template>
    <div class="page">
        <br />
        <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Formularios habitacional</h1>
            </div>
            <v-row>

                <v-col>
                    <div class="card border-left-primary shadow h-100">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <v-row>
                                <v-col cols="12" style="text-align: center;">
                                    QUANTIDADE DE PRE-CADASTROS REALIZADOS
                                    <v-container style="font-size: 30px;">
                                    {{ qntForm }}
                                    </v-container> 
                                </v-col>
                                </v-row>                            
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
              <v-text-field
                  v-model="search"
                  prepend-icon="mdi-feature-search-outline"
                  label="Pesquisar"
                  clearable
              ></v-text-field> 
            </div>
            <v-data-table
                :headers="headersFuncionarios"
                :items="filtradoHabitacaoList"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando..."
                :server-items-length="qntRegistros"
                @update:page="updatePagination"
                @update:items-per-page="updateRowsPagination"
                >
                <template #[`item.datains`]="{ item }">
                    {{ $moment(item.datains, 'YYYY-MM-DD').format('DD/MM/YYYY')}}    
                </template>
                <template #[`item.responsavel.data_nasc`]="{ item }">
                    {{ $moment(item.responsavel.data_nasc, 'YYYY-MM-DD').format('DD/MM/YYYY')}}    
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="[getHabitacaoById(item.id)]"
                        >
                            <v-icon color="blue">mdi-list-box-outline</v-icon>
                        </v-btn>
                        </template>
                        <span>Detalhes</span>
                    </v-tooltip>  
                </template>
            </v-data-table>
            <v-dialog v-model="dialog" max-width="80%">
                <v-card>
                    <v-card-title>
                      <h1>Detalhes do Fomulário Habitacional</h1>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="formularioById">
                            <h3>Responsável Familiar</h3>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>CPF:</strong> {{ formularioById.responsavel.cpf }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Nome:</strong> {{ formularioById.responsavel.nome }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Nome Social:</strong> {{ formularioById.responsavel.nome_social }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Sexo:</strong> {{ formularioById.responsavel.sexo == 'F'  ? 'Feminino' : 'Masculino'  }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Gênero:</strong> {{ formularioById.responsavel.genero }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Data de Nascimento:</strong> {{ $moment(formularioById.responsavel.data_nasc,'YYYY-MM-DD').format('DD/MM/YYYY') }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>RG:</strong> {{ formularioById.responsavel.rg }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>NIS:</strong> {{ formularioById.responsavel.nis }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Celular:</strong> {{ formularioById.responsavel.celular }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Título de Eleitor:</strong> {{ formularioById.responsavel.titulo_eleitor }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <div v-if="formularioById.responsavel.cbo"><strong>CBO:</strong> {{ formularioById.responsavel.cbo.Descricao }}</div>
                                    <div v-else><strong>CBO:</strong> N/A</div>
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <div v-if="formularioById.responsavel.estado_civil"><strong>Estado Civil:</strong> {{ formularioById.responsavel.estado_civil.EstadoCivil }}</div>
                                    <div v-else><strong>Estado Civil:</strong> N/A</div>
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <div v-if="formularioById.responsavel.situacao_mercado"><strong>Situação de Mercado:</strong> {{ formularioById.responsavel.situacao_mercado.situacaomercado }}</div>
                                    <div v-else><strong>Situação de Mercado:</strong> N/A</div>
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>É pessoa com deficiência – PCD? </strong> {{ formularioById.responsavel.pcd == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Possui câncer, doença rara crônica ou degenerativa? </strong> {{ formularioById.responsavel.doenca_rara == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>

                                <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Renda pessoal:</strong> {{ formatPrice(formularioById.responsavel.renda_pessoal) }}
                                    </v-sheet>
                                  </v-col>
                    
                            </v-row>
                            
                            <br>
                            <div v-if="formularioById.conjuge">
                              <h3>Companheiro(a) Familiar</h3>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>CPF:</strong> {{ formularioById.conjuge.cpf }}
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Nome:</strong> {{ formularioById.conjuge.nome }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Nome Social:</strong> {{ formularioById.conjuge.nome_social }}
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Sexo:</strong> {{ formularioById.conjuge.sexo == 'F'  ? 'Feminino' : 'Masculino'  }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Gênero:</strong> {{ formularioById.conjuge.genero }}
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Data de Nascimento:</strong> {{ $moment(formularioById.conjuge.data_nasc,'YYYY-MM-DD').format('DD/MM/YYYY') }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>RG:</strong> {{ formularioById.conjuge.rg }}
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>NIS:</strong> {{ formularioById.conjuge.nis }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Celular:</strong> {{ formularioById.conjuge.celular }}
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Título de Eleitor:</strong> {{ formularioById.conjuge.titulo_eleitor }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <div v-if="formularioById.conjuge.cbo"><strong>CBO:</strong> {{ formularioById.conjuge.cbo.Descricao }}</div>
                                      <div v-else><strong>CBO:</strong> N/A</div>
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <div v-if="formularioById.conjuge.estado_civil"><strong>Estado Civil:</strong> {{ formularioById.conjuge.estado_civil.EstadoCivil }}</div>
                                      <div v-else><strong>Estado Civil:</strong> N/A</div>
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <div v-if="formularioById.conjuge.situacao_mercado"><strong>Situação de Mercado:</strong> {{ formularioById.conjuge.situacao_mercado.situacaomercado }}</div>
                                      <div v-else><strong>Situação de Mercado:</strong> N/A</div>
                                    </v-sheet>
                                  </v-col>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>É pessoa com deficiência – PCD? </strong> {{ formularioById.conjuge.pcd == 'nao' ? 'NÃO' : 'SIM' }}
                                    </v-sheet>
                                  </v-col>
                              </v-row>
                              <v-row no-gutters>
                                  <v-col>
                                    <v-sheet class="pa-2 ma-2">
                                      <strong>Possui câncer, doença rara crônica ou degenerativa? </strong> {{ formularioById.conjuge.doenca_rara == 'nao' ? 'NÃO' : 'SIM' }}
                                    </v-sheet>
                                  </v-col>

                                  <v-col>
                                      <v-sheet class="pa-2 ma-2">
                                        <strong>Renda pessoal:</strong> {{ formatPrice(formularioById.conjuge.renda_pessoal) }}
                                      </v-sheet>
                                    </v-col>
                              </v-row>
                            </div>
                            
                            <br>
                            <div v-if="formularioById.dados_membros">
                              <h3 >Membros Familiar</h3>
                              <div v-for="membro in formularioById.dados_membros" :key="membro.id">
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>CPF:</strong> {{ membro.cpf }}
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Nome:</strong> {{ membro.nome }}
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Nome Social:</strong> {{ membro.nome_social }}
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Sexo:</strong> {{ membro.sexo == 'F'  ? 'Feminino' : 'Masculino' }}
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Gênero:</strong> {{ membro.genero }}
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Data de Nascimento:</strong> {{ $moment(membro.data_nasc,'YYYY-MM-DD').format('DD/MM/YYYY') }}
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>RG:</strong> {{ membro.rg }}
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>NIS:</strong> {{ membro.nis }}
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <div v-if="membro.cbo"><strong>CBO:</strong> {{ membro.cbo.Descricao }}</div>
                                          <div v-else><strong>CBO:</strong> N/A</div>
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <div v-if="membro.estado_civil"><strong>Estado Civil:</strong> {{ membro.estado_civil.EstadoCivil}}</div>
                                          <div v-else><strong>Estado Civil:</strong> N/A</div>
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <div v-if="membro.situacao_mercado"><strong>Situação de Mercado:</strong> {{ membro.situacao_mercado.situacaomercado }}</div>
                                          <div v-else><strong>Situação de Mercado:</strong> N/A</div>
                                        </v-sheet>
                                      </v-col>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Renda Pessoal:</strong> {{ formatPrice(membro.renda_pessoal) }}
                                        </v-sheet>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col>
                                        <v-sheet class="pa-2 ma-2">
                                          <strong>Grau de Parentesco:</strong> {{ membro.grau_parentesco }}
                                        </v-sheet>
                                      </v-col>
                                    
                                  </v-row>
                              </div>
                            </div>
                       
                            <br>
                            <h3>Dados da Moradia</h3>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Quantidade de Pessoas:</strong> {{ formularioById.dados_moradia.qtd_pessoas }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Renda familiar total sem contar com o valor do programa bolsa familia:</strong> {{ formularioById.dados_moradia.renda_familiar_sem_beneficio }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Recebe Bolsa Família</strong> {{ formularioById.dados_moradia.bolsa_familia == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Algum dos membros da família recebe Benefício de Prestação Continuada – BPC
                                    :</strong> {{ formularioById.dados_moradia.beneficio_bpc == 0 ?  'Não se Aplica' 
                                    : formularioById.dados_moradia.beneficio_bpc == 1 ? 'A pessoa com deficiência' : 'A pessoa idosa'  }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>A família possui membro familiar com deficiência?</strong> {{ formularioById.dados_moradia.pessoas_pcd == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Das pessoas do seu grupo familiar e que irão morar com você, caso seja contemplado com o Programa Minha Casa Minha Vida, alguma delas é uma mulher em situação de violência doméstica ou familiar?</strong> {{ formularioById.dados_moradia.violencia_domestica == 'nao' ?  'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Integrantes de povos tradicionais ou quilombolas na família?
                                    </strong> {{ formularioById.dados_moradia.povos_tradicionais == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Idosos na família?
                                    </strong> {{ formularioById.dados_moradia.idoso == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Possui filhos?
                                    </strong> {{ formularioById.dados_moradia.filhos == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Quantos?
                                    </strong> {{ formularioById.dados_moradia.faixa_etaria_filhos }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Tempo moradia?
                                    </strong> {{ formularioById.dados_moradia.tempo_moradia }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Situação de moradia?
                                    </strong>  {{ formularioById.dados_moradia.situacao_moradia.situacaomoradia }}
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Tipo de Moradia:</strong> {{ formularioById.dados_moradia.tipo_moradia.tipomoradia }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <p><strong>Mora com Familiares:</strong> {{ formularioById.dados_moradia.mora_familiares == 'nao' ? 'NÃO' : 'SIM' }}</p>
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <strong>Possui algum imóvel no seu nome?</strong> {{ formularioById.dados_moradia.possui_imovel == 'nao' ? 'NÃO' : 'SIM' }}
                                  </v-sheet>
                                </v-col>
                                <v-col>
                                  <v-sheet class="pa-2 ma-2">
                                    <p><strong>O imóvel precisará de adaptações para pessoa com deficiência e/ou idosos?</strong> {{ formularioById.dados_moradia.moradia_acessibilidade == 'nao' ? 'NÃO' : 'SIM' }}</p>
                                  </v-sheet>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col> 
                                    <v-sheet class="pa-1 ma-1"> 
                                        <strong>Descrição da Situação:</strong> {{ formularioById.dados_moradia.descricao_situacao }}
                                    </v-sheet>    
                                </v-col>
                            </v-row>

                            <br>
                            <h3>Critérios</h3>
                            <div v-for="criterio in formularioById.criterios" :key="criterio.id">
                                <v-row no-gutters>
                                    <v-col> 
                                        <v-sheet class="pa-1 ma-1"> 
                                        <strong>Descrição:</strong> {{ criterio.criterio.descricao }}
                                        </v-sheet>
                                    </v-col>    
                                </v-row>
                            </div>

                            <br>
                            <h3>Anexos</h3>
                            <div v-for="anexo in formularioById.anexos" :key="anexo.id">
                                <p><strong>tipo documento ID:</strong> {{ anexo.tipo_documento.Documento }}</p>
                                <v-btn @click="downloadAnexo(anexo.id)" color="primary" outlined>
                                    <v-icon left>mdi-download</v-icon>
                                    Download Anexo
                                </v-btn>
                            </div>
            
                        </div>
                        <div v-else>
                            Carregando...
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            anos: [],
            HabitacaoList:[],
            estadoCivilOptions: [],
            formularioById: null,
            filtradoHabitacaoList: [],
            nomeFiltro: null,
            cpfFiltro: null,
            nomes: [],
            cpfs: [],
            qntForm:null,
            search: '',
            loading: false,
            dialog: false,
            qntRegistros: 0,
            filters: {
                page: 1,
                per_page: 10,
            },
            headersFuncionarios:
            [
                {text:'Responsável', value:'responsavel.nome', width:'25%'},
                {text:'CPF', value:'responsavel.cpf', width:'10%'},
                {text:'NIS', value:'responsavel.nis', width:'10%'},
                {text:'Celular', value:'responsavel.celular', width:'10%'},
                {text:'Data de Nascimento', value:'responsavel.data_nasc', width:'10%'},
                {text:'Data de Inscrição', value:'datains', width:'10%'},
                {text: "Ações", value: "actions", width: "10%", align: "center" },
            ],
            dynamicCol: 6,
            funcionarios: [],
            acesso: false,
        }
    },
    computed: {
        isDisabledAdmin() {
            return this.acesso
        }
    },
    methods: {
        updatePagination(page){
          this.filters.page = page
          this.getHabitacao()
        },
        updateRowsPagination(per_page){
          this.filters.per_page = per_page
          this.getHabitacao()
        },
        async fetchEstadoCivil() {
            try {
                const response = await this.axios.get('/habitacao/estadocivil');
                this.estadoCivilOptions = response.data;
            } catch (error) {
                console.error('Erro ao buscar estado civil:', error);
            }
        },  
        getAnos(){
            for(let a = new Date().getFullYear(); a >= new Date().getFullYear() - 8; a--){
                this.anos.push(a);
            }
        },
        getHabitacao(){
          this.loading = true;
            this.axios.get(`/habitacao/getallhabitacao`, { params: this.filters })
            .then((res) => {
                this.HabitacaoList = res.data.data;
                this.filtradoHabitacaoList = res.data.data;
                this.qntRegistros = res.data.total
                this.extrairFiltros(res.data.data);
            }).finally(() => {
                this.loading = false
            })
        },
        extrairFiltros(habitacoes) {
            this.nomes = [...new Set(habitacoes.map(h => ({ nome: h.responsavel.nome })))];
            this.cpfs = [...new Set(habitacoes.map(h => ({ cpf: h.responsavel.cpf })))];
        },
        getCountForm(){
            this.axios.get('/habitacao/countforms').then((res) => {
                this.qntForm = res.data.total_forms;
            }).finally(() => {
            })
        },
        getHabitacaoById(id){
            this.axios.get(`/habitacao/getbyidhabitacional/${id}`).then((res) => {
                this.formularioById = res.data;
                this.dialog = true;
            }).finally(() => {
                this.loading = false
            })
        },
        downloadAnexo(anexoId) {
            this.axios.get(`/habitacao/downloadanexo/${anexoId}`, { responseType: 'blob' })
                .then((response) => {
                    const contentType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: contentType });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = this.getFileNameFromContentDisposition(response.headers['content-disposition']) || `${anexoId}`;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);  // Liberar URL após o download
                })
                .catch((e) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    };
                });
        },
        getFileNameFromContentDisposition(contentDisposition) {
            if (!contentDisposition) return null;
            const filename = contentDisposition
                .split(';')
                .find(part => part.trim().startsWith('filename'))
                .split('=')[1]
                .trim();
            return filename.replace(/"/g, '');
        },
        limparFiltros(){
            this.nomeFiltro = null;
            this.cpfFiltro = null;
            this.filtradoHabitacaoList = this.HabitacaoList;
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },
    mounted() {
        this.getHabitacao();
        this.getCountForm();
        this.fetchEstadoCivil();
        this.IdAno = new Date().getFullYear();
    }
}
</script>
