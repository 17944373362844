<template>
    <div
        v-if='anyPermissionInside'
        :class="{'pl-4': !first}"
    >
        <v-list-group
            v-if="hasChildren"
            :prepend-icon="`mdi-${node.icon || 'circle-small'}`"
            color="white"
        >
            <template #activator>
                <v-list-item-title class="text-wrap">
                    {{ node.title }}
                </v-list-item-title>
            </template>

            <list-tree 
                v-for="(child, i) in node.children" 
                :key="i" 
                :node="child"
            />
        </v-list-group>

        <v-list-item
            v-else
            class='text-decoration-none'
            :to="node.path"
        >
            <v-list-item-icon v-if="node.icon">
                <v-icon>
                    mdi-{{ node.icon }}
                </v-icon>
            </v-list-item-icon>
            
            <v-list-item-content :class="{'pl-4': !node.icon}">
                <v-list-item-title class="text-wrap">
                    {{ node.title }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
export default {
    name: "ListTree",

    props: {
        node: {
            type: Object,
            required: true
        },
        first: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasChildren()
        {
            const { children } = this.node;
            return children && children.length > 0;
        },
        anyPermissionInside()
        {
            return this.deepthPermissionCheck(this.node);
        }
    },

    methods: {
        deepthPermissionCheck(node)
        {
            if (node.children)
            {
                return node.children.some(
                    child => this.deepthPermissionCheck(child)
                );
            }
            else
            {
                const route = this.$router.options.routes.find(
                    route => route.path == node.path
                )

                return !route.meta?.checker || route.meta.checker();
            }

        }
    },
}
</script>