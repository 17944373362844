<template>
	<v-card v-if="show" height="432">
		<v-card-title class="align-start">
			<span style="font-size: 0.9em; font-weight: normal;">Tempo médio de espera</span>
			<v-spacer></v-spacer>
			<v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly && !(windowWidth < 1330 && windowWidth >= 1264)">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-information-outline
                    </v-icon>
                </template>
                <span>Acompanhe o tempo médio de atendimento no decorrer do <br> fluxo do usuário/paciente no processo de cuidado em saúde</span>
            </v-tooltip>
		</v-card-title>
		<apexchart type="rangeBar" height="170" style="margin-bottom: -10px !important; padding-bottom: 0px !important" :options="chartOptions" :series="series"></apexchart>
		<v-container>
			<v-layout column justify-center>
				<v-row justify="center">
					<span style="font-size: 0.9em; font-weight: bold; text-align: center">Por classificação de risco</span>
				</v-row>
				<v-row
					class="mt-1"
					justify="center"
					align="center"
				>
					<v-tooltip bottom v-if="waitingClassificacao.vermelho">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="error"
							>
								<span>
									{{ waitingClassificacao.vermelho }}
								</span>
							</v-btn>
							</template>
						<span>Tempo médio de espera na classificação vermelha</span>
					</v-tooltip>
					<v-tooltip bottom v-if="waitingClassificacao.amarelo">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="warning"
							>
								<span>
									{{ waitingClassificacao.amarelo }}
								</span>
							</v-btn>
							</template>
						<span>Tempo médio de espera na classificação amarela</span>
					</v-tooltip>
					<v-tooltip bottom v-if="waitingClassificacao.verde">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="success"
							>
								<span>
									{{ waitingClassificacao.verde }}
								</span>
							</v-btn>
						</template>
						<span>Tempo médio de espera na classificação verde</span>
					</v-tooltip>
					<v-tooltip bottom v-if="waitingClassificacao.azul">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="primary"
							>
								<span>
									{{ waitingClassificacao.azul }}
								</span>
							</v-btn>
							</template>
						<span>Tempo médio de espera na classificação azul</span>
					</v-tooltip>
					<v-tooltip bottom v-if="waitingClassificacao.laranja">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="orange"
							>
								<span>
									{{ waitingClassificacao.laranja }}
								</span>
							</v-btn>
							</template>
						<span>Tempo médio de espera na classificação laranja</span>
					</v-tooltip>
				</v-row>
				<v-row justify="center" class="mt-1" v-if="!(windowWidth >= 960 && windowWidth < 1040)">
					<span style="font-size: 0.9em; font-weight: bold;">Nas últimas horas</span>
				</v-row>
				<v-row
					v-if="!(windowWidth >= 960 && windowWidth < 1040)"
					class="mt-1"
					justify="center"
					align="center"
				>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								tile
								color="#0A2472"
							>
								<v-icon
									left
								>
									mdi-history
								</v-icon>
								{{ espera24horas }}
							</v-btn>
						</template>
						<span>Tempo médio de espera nas últimas 24 horas</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								text
								color="#0E6BA8"
							>
								<v-icon
									left
								>
									mdi-progress-clock
								</v-icon>
								{{ espera6horas }}
							</v-btn>
						</template>
						<span>Tempo médio de espera nas últimas 6 horas</span>
					</v-tooltip>
				</v-row>
			</v-layout>
		</v-container>
	</v-card>
	<v-card v-else height="432">
		<v-container fill-height fluid>
			<v-row align="center"
				justify="center">
				<cube-shadow class="text-center"></cube-shadow>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import {CubeShadow} from 'vue-loading-spinner'
import moment from 'moment'
export default {
	mixins: [resizeMixin],
	props: {
		items: Array,
		show: Boolean,
		waiting24: Number,
		waiting6: Number,
		waitingClassificacao: Array,
	},
	components: {
		CubeShadow
	},
	computed: {
		espera24horas () {
			return moment.utc(this.waiting24*1000).format('LT')
		},
		espera6horas () {
			return moment.utc(this.waiting6*1000).format('LT')
		}
	},
	data () {
		return {
			series: [
				{
					data: [
						{
							x: 'Acolhimento',
							y: [],
							fillColor: '#0A2472'
						},
						{
							x: 'Classificação',
							y: [],
							fillColor: '#0E6BA8'
						},
						{
							x: 'Atendimento',
							y: [],
							fillColor: '#A6E1FA'
						}
					]
				}
			],
			chartOptions: {
				stroke: {
					colors: ['transparent'],
					width: 2,
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val*1000).format('LT')
						}
					},
					enabled: true,
				},
				dataLabels: {
					style: {
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: ['#000000']
					},
					enabled: true,
					offsetY: -25,
					format: 'mm:ss',
					type:'time',
					formatter: function (val) {
						return moment((val[1]-val[0])*1000).format('LT')
					}
				},
				chart: {
					height: 150,
					type: 'rangeBar'
				},
				plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '40%',
            distributed: true,
            borderRadius: 4,
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
				},
				xaxis: {
					type: 'time',
					labels: {
						format: 'mm',
						formatter: function (value) {
							return moment.utc(value*1000).format('LT')
						},
					}
				}
			},
		}
	},
  watch:{
    show(){
      if(this.show == true){
        // this.computedItems = this.items

				this.series[0].data[0].y.push(0)
				this.series[0].data[0].y.push(this.items[0].EsperaAcolhimento)
				this.series[0].data[1].y.push(this.items[0].EsperaAcolhimento)
				this.series[0].data[1].y.push(this.items[0].EsperaClassificacao + this.items[0].EsperaAcolhimento)
				this.series[0].data[2].y.push(this.items[0].EsperaClassificacao + this.items[0].EsperaAcolhimento)
				this.series[0].data[2].y.push(this.items[0].EsperaAtendimento + this.items[0].EsperaClassificacao+ this.items[0].EsperaAcolhimento)

        // for(let j = 0; j < this.computedItems.length; j++) {
        //   this.chartOptions.xaxis.categories.push(this.computedItems[j].title)
        //   console.log(this.computedItems[j].title)
        // }
        // for(let i = 0; i < this.computedItems.length; i++) {
        //   this.chartData.series[0].data.push(this.computedItems[i].total)
        // }
      }
    }
  },
  mounted() {

  }
}
</script>
