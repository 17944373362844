<template>
    <div v-if="!loading" id="chartPercentual">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
    <div v-else>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        Carregando dados...
    </div>
</template>

<script>

import pt from 'apexcharts/dist/locales/pt-br.json'

export default {
    props: {
        idAno: Number,
        idUnidade: Number
    },
    data() {
        return {
            loading: false,
            series: [
                {
                    name: 'Fora de Faixa:',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    locales: [pt],
                    defaultLocale: "pt-br",
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: "center",
                    text: "Nenhum dado disponível até o momento",
                    verticalAlign: "middle"
                },
            },
        }       
    },
    methods: {
        getAlunosForaDeFaixa(){
            this.loading = true
            this.axios.get('turma/foradefaixa', {
                params: {
                    ano: this.$props.idAno,
                    idUnidade: this.$props.idUnidade
                }
            }).then(res => {
                
                this.resetSeries()

                res.data.filter(array => {
                    this.series[0].data.push(array.quantidade)
                    this.chartOptions.xaxis.categories.push(array.Serie)
                })
                this.loading = false
            })
        },
        resetSeries(){
            this.series = [
                {
                    ...this.series[0], 
                    ...{
                        data: []
                    }
                }
            ]
            this.chartOptions = {
                xaxis: {
                    categories: []
                }
            }
        }
    },
    mounted() {
        this.getAlunosForaDeFaixa()
    }
}
</script>