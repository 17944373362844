<template>
    <v-card v-if="show" :height="height">
        <v-card-title class="align-start">
            <span style="font-size: 0.9em; font-weight: normal;">Classificação por sexo</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="!$vuetify.breakpoint.mdOnly">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-information-outline
                    </v-icon>
                </template>
                <span>Veja o total de atendimentos por sexo. <br>
                    Você pode ver a quantidade de atendimentos <br> para os sexos masculino e feminino na data atual</span>
            </v-tooltip>
        </v-card-title>
        <v-card flat class="flexcard mb-0 pb-0" :height="height*0.7">
            <apexchart type="pie" class="grafico mb-0 pb-0" height="110%" :options="classificacaogenero.chartOptions" :series="classificacaogenero.series"></apexchart>
        </v-card>
    </v-card>
    <v-card v-else :height="height">
        <v-container fill-height fluid>
            <v-row align="center"
                justify="center">
                <circle3 class="text-center"></circle3>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { resizeMixin } from '../../mixins/resize'
import { Circle3 } from 'vue-loading-spinner'
export default {
    props: {
        items: Object,
        show: Boolean,
        height: {
            type: Number,
            default: 280
        }
    },
    mixins: [resizeMixin],
    components: {
        Circle3
    },
    data () {
		return {
            loading: false,
            trava: false,
            selected: 'ano',
            intervalAno: undefined,
            intervalMes: undefined,
            intervalOntem: undefined,
            intervalHoje: undefined,
            classificacaogenero: {
                series: [],
                chartOptions: {
                    labels: ["Masculino", "Feminino"],
                    chart: {
                        type: 'pie'
                    },
                    noData: {
                        align: "center",
                        text: "Nenhum dado disponível até o momento",
                    },
                    colors: [
                        '#001C55',
                        '#0E6BA8',
                        '#808080',
                    ],

                    legend: {
                        position: 'bottom',
                        show: false
                    },
                
                    dataLabels: {
                        enabled: true,
                        style: {
                            colors: ["#FFFFFF"]
                        }
                    },
                   
                },
            },
        }
	},
    watch:{
        show(show){
            this.classificacaogenero.series = []
            if(show){
                if(this.items.Masculino)
                    this.classificacaogenero.series.push(this.items.Masculino)
                if(this.items.Feminino)
                    this.classificacaogenero.series.push(this.items.Feminino)
                if(this.classificacaogenero.series.length > 0)
                    this.classificacaogenero.chartOptions.legend.show = true
                else
                    this.classificacaogenero.chartOptions.legend.show = false
            }
        }
    },
    methods: {

    },
    mounted() {
    
    },
}
</script>
<style>
.flexcard {
  display: flex;
  flex-direction: column;
}
.flexcard .grafico {
  flex: 0;
}
</style>
