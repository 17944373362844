<template>
<div class="body">
  <!-- <v-container class="text-center">
    <v-card flat class="pa-4">
      <v-row>
        <v-col cols="12" sm="4" md="4">
      <v-img
        src="@/assets/bandeira_macaiba.png"
        max-width="75%"
        contain
        style="margin-left: auto;"
      ></v-img>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <div class="divider"></div>
        </v-col>
        <v-col cols="12" sm="7" md="7" style=" display: flex; flex-direction: column; justify-content: center;">
          <h3 class="mb-1">Secretaria Municipal de</h3>
          <h2 class="mb-2">Trabalho, Habitação e Assistência Social</h2>
          <h2>Coordenação de Vigilância Socioassistencial e Planejamento</h2>
          <h2>Departamento de Habitação e Regularização Fundiária</h2>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        
      </v-col>
    </v-row>
    </v-card>
  </v-container>
  <v-container>
    <v-card>
      <div class="user-terms">
        <h1>Termos de Usuário</h1>
        <p>
          Para participar do Cadastro é necessário que você conheça e concorde com alguns aspectos descritos a seguir, e só assim, possa preencher com as informações necessárias para a efetivação do mesmo. Leia ATENTAMENTE as informações abaixo e, se você estiver de acordo, preencha o cadastro.
        </p>
        <ul>
          <li>Os critérios federais estipulam que, para ser atendido nos programas habitacionais, você não possua ou tenha possuído qualquer tipo de imóvel em seu nome.</li>
          <li>A veracidade das informações aqui prestadas e informadas no formulário de cadastramento é de sua responsabilidade.</li>
          <li>Ao ler e concordar com as informações acima, você fica ciente que a falsidade das informações ora solicitadas configura crime previsto no Código Penal Brasileiro, sendo passível de apuração na forma da Lei e a exclusão do cadastro.</li>
        </ul>
        <h2>Legislação/norma que regulamenta o Programa Minha Casa Minha Vida:</h2>
        <ol>
          <li>Lei n° 11.977/2009 e alterações posteriores.</li>
          <li>Medida Provisória n° 1.162, de 14 de fevereiro de 2023, convertida na Lei n° 14.620, de 13 de julho de 2023, com adoção de novas práticas.</li>
          <li>Público-alvo do Programa Conforme estabelecido pela Medida Provisória 1.162, de 14 de fevereiro de 2023, serão priorizados o atendimento de famílias:
            <ul>
              <li>Em situação de rua;</li>
              <li>Famílias que tenham a mulher como responsável pela unidade familiar;</li>
              <li>Famílias de que façam parte pessoas com deficiência, idosos, crianças e adolescentes;</li>
              <li>Famílias em situação de risco e vulnerabilidade;</li>
              <li>Em situação de emergência ou calamidade;</li>
              <li>Em deslocamento involuntário em razão de obras públicas federais, sem prejuízo de outros critérios e prioridades que podem ser definidos pelos Estados, Distrito Federal, Municípios e entidades, adequando a cobertura de situações de vulnerabilidade social e econômica locais.</li>
            </ul>
          </li>
        </ol>
        <h2>Para a etapa de comprovação de informações o candidato deverá estar inscrito no CADÚNICO.</h2>
        <p>
          Se já inscrito, deverá atualizar suas informações, as quais deverão ser as mesmas da sua ficha de inscrição no PMCMV – FAIXA 1, conforme orientações abaixo:
        </p>
        <ul>
          <li>Tenha residência fixa, devidamente comprovada, no Município de Macaíba/RN;</li>
          <li>Possua renda familiar mensal bruta de R$ 0,00 (zero) a R$ 2.640,00;</li>
          <li>Possua cadastro e identificação no CADÚNICO.</li>
          <li>Não tenha recebido benefício de natureza habitacional anterior;</li>
          <li>Não possua imóvel próprio registrado em nome de qualquer membro familiar;</li>
          <li>Não esteja inscrita no CADIN – cadastro informativo de créditos não quitados do setor público federal;</li>
          <li>Não esteja cadastrado no CADMUT – cadastro nacional de mutuários e/ou no SIACI – sistema integrado de administração da carteira imobiliária;</li>
        </ul>
        <h2>Documentos necessários para realização da inscrição</h2>
        <ul>
          <li>Carteira de identidade (RG) do responsável familiar e da inscrição;</li>
          <li>CPF;</li>
          <li>Carteira de identidade (RG) ou registro de nascimento do companheiro (a) e dos demais membros familiares;</li>
          <li>Se casado (a), certidão de casamento;</li>
          <li>Comprovante de residência;</li>
          <li>Número de Identificação Social – NIS (Comprovação através de folha resumo do Cadastro Único).</li>
        </ul>
        <h2>Guia dos beneficiários</h2>
        <p>
          PROGRAMA MINHA CASA, MINHA VIDA (PMCMV) Faixa 1, Órgão responsável: Secretaria Municipal de Trabalho Habitação e Assistência Social de Macaíba/RN.
        </p>
        <div class="agreement">
          <input type="checkbox" id="agree" v-model="agreed" />&nbsp;&nbsp;
          <label for="agree">DECLARO QUE LI E CONCORDO COM O EXPOSTO</label>
        </div>
        <v-btn @click="continueToForm" class="primary" :disabled="!agreed">CONTINUAR</v-btn>
      </div>
    </v-card>
  </v-container> -->
  <v-app>
    <v-container
      class="fill-height d-flex justify-center align-center text-center"
    >
      <v-card
        class="pa-8"
        elevation="2"
      >
        <v-card-title class="text-h4">O cadastro no Formulário Habitacional foi encerrado!</v-card-title>

        <v-card-text>
          <v-icon large color="primary">mdi-tools</v-icon>
          <p class="mt-4">
            Para mais informações, entre em contato com a Secretaria Municipal de Trabalho, Habitação e Assistência Social.
          </p>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="goHome">Voltar para o Início</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</div>  
</template>

<script>
export default {
  data() {
    return {
      agreed: false,
    };
  },
  methods: {
    continueToForm() {
      if (this.agreed) {
        this.$router.push('/form');
      }
    },
    goHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.user-terms {
  margin: 20px;
}
.agreement {
  margin-top: 20px;
}
.primary {
  margin-bottom: 20px;
  text-align: right;
}
.divider {
  border-right: 4px solid #0E6BA8;
  height: 100%;
}
h3{
  font-size: 30px;
  color: #2e6eb8;
}
h2{
  color: #0E6BA8;
}
v-btn {
  margin-top: 20px;
}
v-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}

#app {
  height: 100%;
  margin: 0;
  background: -webkit-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: -o-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: -moz-linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
  background: linear-gradient(110deg, #0E6BA8 60%, #2196F3 60%);
}
</style>
