
<template>
    <v-select
        :items="etapas"
        item-text="name"
        item-value="id"
        label="Filtrar pela etapa"
        @change="sendMessageToParent($event)"
        hide-details
        outlined
        dense
        clearable
    ></v-select>
</template>

<script>
export default {
    data() {
        return {
            etapas: [
                {
                    id: 1,
                    name: 'CRECHE'
                },
                {
                    id: 2,
                    name: 'PRE-ESCOLA'
                },
                {
                    id: 3,
                    name: 'ANOS INICIAIS'
                },
                {
                    id: 4,
                    name: 'ANOS FINAIS'
                },
                {
                    id: 5,
                    name: 'EJA'
                }
            ],
        }
    },
    methods: {
        sendMessageToParent(value){
            this.$emit('messageFromChild', value)
        }
    },
}
</script>