<template>
    <v-autocomplete
        v-model="id"
        :items="setores"
        item-text="name"
        item-value="id"
        label="Filtrar por setor"
        hide-details
        outlined
        dense
    />
</template>

<script>
export default {
    props: {
        codOrgao: Number
    },

    data() {
        return {
            id: null,
            setores: []
        }
    },

    watch: {
        codOrgao: {
            immediate: true,
            handler(newValue)
            {
                this.axios.get('folha/setores', {
                    params: {
                        codOrgao: newValue
                    }
                })
                .then(res => {
                    this.setores = res.data.map(item => (
                        {
                            id: item.codSetor,
                            name: item.Setor
                        }
                    ))
                })
            }
        },
        id(newValue) {
            this.$emit('messageFromChild', newValue)
        }
    }
}
</script>
