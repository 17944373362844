<template>
  <div>
    <div class="page">
      <br />
      <div class="container-fluid">
        <v-row>
          <v-col cols="12">
            <v-card shaped elevation="1">
              <h4
                class="primary--text font-weight-bold pt-4 mt-0"
                style="text-align: center !important"
              >
                PREFEITURA MUNICIPAL DE MACAÍBA - MATERIAIS
              </h4>
              <h5
                class="accent--text font-weight-bold pb-4 mb-0"
                style="text-align: center"
              >
                PAINEL DE INFORMAÇÕES DOS MATERIAIS
              </h5>
            </v-card>
          </v-col>
        </v-row>
        
        <v-row class="col-xl-12 col-lg-12">
          <v-col>
            <v-select
              v-model="idUnidadeTipo"
              :items="setor"
              item-text="UnidadeTipo"
              item-value="Id"
              label="Filtrar pelo setor"
              @input="
                [
                  
                  limparFiltros(),
                  filtroSetorUnidade($event),
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getAVencer($event),
                  getConsumoUnidade($event),
                  mudarHeaders($event),
                  getResumoSolicitacoesGrafico($event),
                  getConsumoItemUnidade($event),
                ]
              "
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="IdAno"
              :items="anos"
              label="Filtrar pelo ano"
              @input="
                [
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getDispensacaoMateriais($event),
                  getConsumoUnidade($event),
                  getResumoSolicitacoesGrafico($event),
                  getConsumoItemUnidade($event),
                ]
              "
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="mes_inicial"
              :items="meses"
              item-value="id"
              item-text="mes"
              label="Mês inicial"
              @input="
                [
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getDispensacaoMateriais($event),
                  getConsumoUnidade($event),
                  getResumoSolicitacoesGrafico($event),
                  getConsumoItemUnidade($event),
                ]
              "
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="mes_final"
              :items="meses"
              item-value="id"
              item-text="mes"
              label="Mês final"
              @input="
                [
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getDispensacaoMateriais($event),
                  getConsumoUnidade($event),
                  getResumoSolicitacoesGrafico($event),
                  getConsumoItemUnidade($event),
                ]
              "
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-autocomplete
              style="position: relative; z-index: 3"
              v-model="IdUnidade"
              :items="unidadesList"
              item-text="Unidade"
              item-value="Id"
              label="Filtrar pela unidade"
              @input="
                [
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getAVencer($event),
                  getConsumoUnidade($event),
                  getDispensacaoMateriais($event),
                ]
              "
              hide-details
              outlined
              dense
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              v-model="idGrupo"
              :items="grupos"
              item-text="GrupoMed"
              item-value="idGrupoMed"
              label="Filtrar por grupo"
              @change="
                [
                  getChartMedicamentos($event),
                  getChartVencidos($event),
                  getAVencer($event),
                  getDispensacaoMateriais($event),
                  getConsumoUnidade($event),
                ]
              "
              hide-details
              outlined
              dense
              clearable
            ></v-select>
          </v-col>
          <v-col v-if="IdUnidade != null || IdAno != null || idGrupo != null">
            <v-btn
              color="primary"
              width="100%"
              v-bind:id="(dynamicCol = '4')"
              @click="[limparFiltros()]"
              dark
            >
              Limpar Filtros
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-xl-6 col-lg-6">
            <div class="card shadow">
              <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
              >
                <h6 class="m-0 font-weight-bold text-primary">
                  Itens mais consumidos
                </h6>
              </div>

              <div class="card-body">
                <div id="chartMedicamentos">
                  <apexchart
                    type="line"
                    v-if="showChartMedicamentos"
                    height="400"
                    :options="chartOptionsMedicamentos"
                    :series="seriesMedicamentos"
                    :key="chartMedicamentosKey"
                  ></apexchart>
                </div>
              </div>
            </div>
          </v-col>

          <v-col class="col-xl-6 col-lg-6">
            <div class="card shadow mb-4">
              <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
              >
                <h6 class="m-0 font-weight-bold text-primary">
                  Itens com maior índice de vencimento
                </h6>
              </div>

              <div class="card-body">
                <div id="chartVencidos">
                  <apexchart
                    v-if="showChartVencidos"
                    type="bar"
                    height="400"
                    :options="chartOptionsVencidos"
                    :series="seriesVencidos"
                  ></apexchart>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row >
          <saudeEstoque />
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              class="ml-3 mr-3"
              v-model="idItem"
              label="Informe o item"
              item-text="NomeComercial"
              item-value="IdMedicamento"
              :items="itemList"
              @change="[getConsumoItemUnidade($event)]"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card elevation="5" class="mb-5">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              Consumo do item por unidade
            </h6>
          </div>

          <div class="card-body">
            <div id="chartConsumoUnidade">
              <apexchart
                type="bar"
                height="400"
                :options="chartOptionsConsumoUnidade"
                :series="seriesConsumoUnidade"
              ></apexchart>
            </div>
          </div>
        </v-card>

        <v-card elevation="5" class="mb-5">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              Maiores movimentações das unidades
            </h6>
          </div>

          <div class="card-body">
            <div id="chartSolicitados">
              <apexchart
                type="bar"
                height="400"
                :options="chartOptionsSolicitacoes"
                :series="seriesSolicitacoes"
              ></apexchart>
            </div>
          </div>
        </v-card>

        <v-card elevation="5" class="mb-5">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              Itens próximos a vencer
            </h6>
          </div>
          <v-row class="ml-3 mr-3 mt-3">
            <v-text-field
              class="ml-3 mr-3"
              v-model="search"
              prepend-icon="mdi-feature-search-outline"
              label="Pesquisar"
              clearable
            ></v-text-field>
            <v-autocomplete
              class="ml-3 mr-3"
              v-model="filtroDiasAvencer"
              label="Tempo até vencer"
              item-text="NomeFiltro"
              item-value="filtroDiasAvencer"
              :items="diasvencimentoarray"
              @input="[getAVencer($event)]"
            >
            </v-autocomplete>
          </v-row>
          <v-data-table
            :search="search"
            :headers="headersTableItens"
            :items="aVencerList"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 40, 50, -1],
            }"
            :items-per-page="5"
          >
            <template v-slot:[`item.ValorUnitario`]="{ item }">
              <span>
                {{
                  item.ValorUnitario.toLocaleString('pt-br', {
                    maximumFractionDigits: 4,
                    style: 'currency',
                    currency: 'BRL',
                  })
                }}</span
              >
            </template>
            <template v-slot:[`item.ValorTotal`]="{ item }">
              <span>
                {{
                  item.ValorTotal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                }}</span
              >
            </template>
          </v-data-table>
        </v-card>

        <v-card elevation="5" class="mb-5">
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              Resumo de movimentação das unidades
            </h6>
          </div>
          <v-row class="ml-3 mr-3 mt-3">
            <v-text-field
              class="ml-3 mr-3"
              v-model="searchTransferencia"
              prepend-icon="mdi-feature-search-outline"
              label="Pesquisar"
              clearable
            ></v-text-field>
          </v-row>
          <v-data-table
            :search="searchTransferencia"
            :headers="headersTransferencia"
            :items="itemsTransferencia"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 40, 50, -1],
            }"
            :items-per-page="5"
          >
            <template v-slot:[`item.dataDispensacao`]="{ item }">
              <span> {{ $moment(item.dataDispensacao).format('L') }}</span>
            </template>
          </v-data-table>
        </v-card>

        <v-card
          v-if="idUnidadeTipo == 2 && IdUnidade != null"
          elevation="5"
          class="mb-5"
        >
          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 font-weight-bold text-primary">
              Dispensação de materiais das unidades de saude
            </h6>
          </div>
          <v-row class="ml-3 mr-3 mt-3">
            <v-text-field
              class="ml-3 mr-3"
              v-model="searchDispensacao"
              prepend-icon="mdi-feature-search-outline"
              label="Pesquisar"
              clearable
            ></v-text-field>
          </v-row>
          <v-data-table
            :search="searchDispensacao"
            :headers="headersTableDispensacoes"
            :items="dispensacaoMateriais"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 40, 50, -1],
            }"
            :items-per-page="5"
          >
            <template v-slot:[`item.dataDispensacao`]="{ item }">
              <span> {{ $moment(item.dataDispensacao).format('L') }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import pt from 'apexcharts/dist/locales/pt-br.json';
import formatter from '@/utils/formatter';
import saudeEstoque from '@/components/saude/SaudeEstoque.vue'

export default {
  name: 'SaudeMateriais',
  components: {
    saudeEstoque,
  },
  computed: {
    meses() {
      return [...Array(12).keys()].map((i) => ({
        id: i + 1,
        mes: formatter.numberToMonth(i + 1),
      }));
    },
  },
  data: () => {
    return {
      mes_inicial: 1,
      mes_final: new Date().getMonth() + 1,
      dispensacaoMateriais: [],
      itemsTransferencia: [],
      itemConsumoUnidade: [],
      anos: [],
      showCardDispensacao: true,
      setor: [],
      idUnidadeTipo: 1,
      idSetor: null,
      aVencerList: [],
      grupos: [],
      diasvencimentoarray: [
        {
          NomeFiltro: '30 dias',
          filtroDiasAvencer: 30,
        },
        {
          NomeFiltro: '60 dias',
          filtroDiasAvencer: 60,
        },
        {
          NomeFiltro: '90 dias',
          filtroDiasAvencer: 90,
        },
        {
          NomeFiltro: '120 dias',
          filtroDiasAvencer: 120,
        },
        {
          NomeFiltro: '150 dias',
          filtroDiasAvencer: 150,
        },
        {
          NomeFiltro: '180 dias',
          filtroDiasAvencer: 180,
        },
      ],
      idGrupo: null,
      idItem: null,
      itemList: [],
      IdAno: new Date().getFullYear(),
      IdUnidade: null,
      nomeComercial: null,
      filtroDiasAvencer: 180,
      valorTotal: null,
      searchItemDispensacao: '',
      search: '',
      searchDispensacao: '',
      searchTransferencia: '',
      unidadesList: [],
      dynamicCol: 6,
      chartMedicamentosKey: 0,
      showChartMedicamentos: false,
      showChartVencidos: false,
      showChartConsumoUnidade: false,
      headersTableItens: [
        {
          text: 'Nome da unidade',
          value: 'Sigla',
          width: '15%',
          align: 'left',
        },
        {
          text: 'Nome do almoxarifado',
          value: 'Almoxarifado',
          width: '15%',
          align: 'left',
        },
        {
          text: 'Nome do item',
          value: 'NomeComercial',
          width: '25%',
          align: 'left',
        },
        {
          text: 'Validade',
          value: 'ValidadeLote',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Dias à vencer',
          value: 'QtdDiasVencer',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Quantidade',
          value: 'Quantidade',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Valor unitário',
          value: 'ValorUnitario',
          width: '5%',
          align: 'right',
        },
        {
          text: 'Valor total',
          value: 'ValorTotal',
          width: '5%',
          align: 'right',
        },
      ],
      headersTableDispensacoes: [
        {
          text: 'Nome do paciente',
          value: 'nomePaciente',
          width: '20%',
          align: 'left',
        },
        { text: 'CPF', value: 'CPF', width: '15%', align: 'center' },
        { text: 'Item', value: 'NomeComercial', width: '20%', align: 'left' },
        {
          text: 'Validade',
          value: 'ValidadeLote',
          width: '5%',
          align: 'center',
        },
        {
          text: 'Data da dispensação',
          value: 'dataDispensacao',
          width: '5%',
          align: 'center',
        },
        {
          text: 'Quantidade',
          value: 'QuantidadeItem',
          width: '5%',
          align: 'center',
        },
        { text: 'Lote', value: 'Lote', width: '5%', align: 'center' },
        {
          text: 'Requisição',
          value: 'Requisicao',
          width: '5%',
          align: 'center',
        },
      ],
      headersTransferencia: [
        {
          text: 'Nome da unidade',
          value: 'Unidade',
          width: '60%',
          align: 'left',
        },
        {
          text: 'Solicitações',
          value: 'Solicitacoes',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Transferências',
          value: 'Tranferencias',
          width: '10%',
          align: 'center',
        },
        { text: 'Consumo', value: 'Consumo', width: '10%', align: 'center' },
        {
          text: 'Dispensações',
          value: 'Dispensacoes',
          width: '10%',
          align: 'center',
        },
      ],
      arraySolicitacoes: [],
      seriesSolicitacoes: [
        {
          name: 'Quantidade',
          data: [],
        },
      ],
      chartOptionsSolicitacoes: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 30,
          style: {
            fontSize: '12px',
            colors: ['#000000'],
          },
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
      },
      seriesMedicamentos: [
        {
          name: 'Consumo total de Itens',
          type: 'column',
          data: [],
        },
        {
          name: 'Preço total',
          type: 'line',
          data: [],
        },
      ],
      chartOptionsMedicamentos: {
        chart: {
          zoom: {
            enabled: false,
          },
          locales: [pt],
          defaultLocale: 'pt-br',
          height: 350,
          type: 'bar',
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 4],
        },
        noData: {
          align: 'center',
          text: 'Nenhum dado disponível até o momento',
          verticalAlign: 'middle',
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            show: true,
            rotate: -60,
            rotateAlways: true,
          },
        },
        yaxis: [
          {
            floating: false,
            decimalsInFloat: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            title: {
              text: 'Consumo total de itens',
              style: {
                color: '#008FFB',
              },
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString('pt-BR');
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: 'Preço',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00E396',
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
              },
              style: {
                colors: '#00E396',
              },
            },
            title: {
              text: 'Preço total',
              style: {
                color: '#00E396',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: false,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'center',
          offsetX: 40,
        },
      },
      seriesVencidos: [
        {
          name: 'Quantidade de itens vencidos',
          data: [],
        },
      ],
      chartOptionsVencidos: {
        chart: {
          locales: [pt],
          defaultLocale: 'pt-br',
          type: 'bar',
          height: 350,
        },
        noData: {
          align: 'center',
          text: 'Nenhum dado disponível até o momento',
          verticalAlign: 'middle',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ['#000000'],
          },
        },
        xaxis: {
          categories: [],
        },
      },
      seriesConsumoUnidade: [
        {
          name: 'Consumo do item por unidade',
          data: [],
        },
      ],
      chartOptionsConsumoUnidade: {
        chart: {
          locales: [pt],
          defaultLocale: 'pt-br',
          type: 'bar',
          height: 350,
        },
        noData: {
          align: 'center',
          text: 'Nenhum dado disponível até o momento',
          verticalAlign: 'middle',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ['#000000'],
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    showCardDispensacaoFuncao(id) {
      if (id == 1) {
        this.showCardDispensacao = true;
      }
      if (id == 2) {
        this.showCardDispensacao = false;
      }
      if (id == 3) {
        this.showCardDispensacao = false;
      }
    },
    getItens() {
      this.axios.get('item').then((res) => {
        this.itemList = res.data;
      });
    },
    getAnos() {
      for (
        let a = new Date().getFullYear();
        a >= new Date().getFullYear() - 8;
        a--
      ) {
        this.anos.push(a);
      }
    },
    filtroSetorUnidade(value) {
      this.idSetor = value;
      this.getUnidades();
    },
    getSetor() {
      this.axios.get('unidade/unidadetipo').then((res) => {
        this.setor = res.data;
      });
    },
    getConsumoUnidade() {
      this.axios
        .get('materiais/resumosolicitacoes', {
          params: {
            ano: this.IdAno,
            idUnidade: this.IdUnidade,
            idUnidadeTipo: this.idUnidadeTipo,
            idGrupo: this.idGrupo,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
          },
        })
        .then((res) => {
          this.itemsTransferencia = res.data;
        });
    },
    getAVencer() {
      this.axios
        .get('materiais/itensavencer', {
          params: {
            diasVencer: this.filtroDiasAvencer,
            idUnidade: this.IdUnidade,
            idGrupo: this.idGrupo,
            idUnidadeTipo: this.idUnidadeTipo,
          },
        })
        .then((res) => {
          this.aVencerList = res.data;
        });
    },
    getUnidades() {
      this.axios
        .get('unidade', {
          params: {
            idUnidadeSetor: this.idUnidadeTipo,
          },
        })
        .then((res) => {
          this.unidadesList = res.data;
        });
    },
    getChartMedicamentos() {
      this.axios.get('materiais/consumounidade', {
          params: {
            idUnidade: this.IdUnidade,
            ano: this.IdAno,
            idGrupo: this.idGrupo,
            idUnidadeTipo: this.idUnidadeTipo,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
          },
        })
        .then((res) => {
          if (this.showChartMedicamentos == true) {
            this.seriesMedicamentos = [
              {
                ...this.seriesMedicamentos[0],
                ...{
                  data: [],
                },
              },
              {
                ...this.seriesMedicamentos[1],
                ...{
                  data: [],
                },
              },
            ];
            this.chartOptionsMedicamentos = {
              xaxis: {
                categories: [],
              },
            };
          }
          for (var i = 0; i < res.data.length; i++) {

            this.seriesMedicamentos[0].data.push(res.data[i].Consumo);

            this.seriesMedicamentos[1].data.push(res.data[i].ConsumoValor);
            this.chartOptionsMedicamentos.xaxis.categories.push(
              res.data[i].NomeComercial
            );
          }
        })
        .finally(() => {
          this.chartMedicamentosKey++;
          this.showChartMedicamentos = true;
        });
    },
    getChartVencidos() {
      this.axios
        .get('materiais/itemvencido', {
          params: {
            idUnidade: this.IdUnidade,
            ano: this.IdAno,
            idGrupo: this.idGrupo,
            idUnidadeTipo: this.idUnidadeTipo,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
          },
        })
        .then((res) => {
          if (this.showChartVencidos == true) {
            this.seriesVencidos = [
              {
                ...this.seriesMedicamentos[0],
                ...{
                  data: [],
                },
              },
            ];
            this.chartOptionsVencidos = {
              xaxis: {
                categories: [],
              },
            };
          }
          for (var i = 0; i < res.data.length; i++) {
            this.seriesVencidos[0].data.push(res.data[i].Quantidade);
            this.chartOptionsVencidos.xaxis.categories.push(
              res.data[i].NomeComercial
            );
          }
        })
        .finally(() => {
          this.showChartVencidos = true;
        });
    },
    getConsumoItemUnidade() {
      if (!this.idItem) return false;

      this.showChartConsumoUnidade = true;
      this.axios
        .get('materiais/consumoitemunidade', {
          params: {
            ano: this.IdAno,
            idUnidadeTipo: this.idUnidadeTipo,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
            idItem: this.idItem,
          },
        })
        .then((res) => {
          if (this.showChartConsumoUnidade == true) {
            this.seriesConsumoUnidade = [
              {
                ...this.seriesConsumoUnidade[0],
                ...{
                  data: [],
                },
              },
            ];
            this.chartOptionsConsumoUnidade = {
              xaxis: {
                categories: [],
              },
            };
          }
          for (var i = 0; i < res.data.length; i++) {
            this.seriesConsumoUnidade[0].data.push(res.data[i].Consumo);
            this.chartOptionsConsumoUnidade.xaxis.categories.push(
              res.data[i].Unidade
            );
          }
        })
        .finally(() => {
          this.showChartConsumoUnidade = true;
        });
    },
    getGrupoDeItensConsumidos() {
      this.axios.get('materiais/grupos').then((res) => {
        this.grupos = res.data;
      });
    },
    getDispensacaoMateriais() {
      this.axios
        .get('materiais/dispensacaomateriais', {
          params: {
            idUnidadeTipo: this.idUnidadeTipo,
            idUnidade: this.IdUnidade,
            ano: this.IdAno,
            item: this.nomeComercial,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
          },
        })
        .then((res) => {
          this.dispensacaoMateriais = res.data;
        });
    },
    getResumoSolicitacoesGrafico() {
      this.axios
        .get('materiais/graficoresumosolicitacoes', {
          params: {
            idUnidadeTipo: this.idUnidadeTipo,
            ano: this.IdAno,
            mes_inicial: this.mes_inicial,
            mes_final: this.mes_final,
          },
        })
        .then((res) => {
          this.arraySolicitacoes = res.data;

          // resetar o series
          this.seriesSolicitacoes = [
            { ...this.seriesSolicitacoes[0], ...{ data: [] } },
          ];

          this.chartOptionsSolicitacoes = {
            xaxis: {
              categories: [],
            },
          };

          this.arraySolicitacoes.forEach((i) => {
            this.seriesSolicitacoes[0].data.push(i.Tranferencias);
            this.chartOptionsSolicitacoes.xaxis.categories.push(i.Sigla);
          });
        });
    },
    limparFiltros() {
      this.idGrupo = null;
      this.IdUnidade = null;
      this.dynamicCol = 6;
      this.getChartMedicamentos();
      this.getChartVencidos();
    },
    inicializadora() {
      if (sessionStorage.getItem('diretor')) {
        this.idSetor = 1;
        this.idUnidadeTipo = 1;
      }
      if (sessionStorage.getItem('secretariaEscolar')) {
        this.idSetor = 1;
        this.idUnidadeTipo = 1;
      }
      if (sessionStorage.getItem('secretariaEducacao')) {
        this.idSetor = 1;
        this.idUnidadeTipo = 1;
      }
      if (sessionStorage.getItem('secretariaSaude')) {
        this.idSetor = 2;
        this.idUnidadeTipo = 2;
      }
    },
    mudarHeaders() {
      //será usado apenas se for condicional para cada tipo de tipo de setor
      // if (this.idUnidadeTipo == 1){
      //   this.headersTransferencia = [
      //       {text:'Nome da unidade', value:'Unidade', width:"50%", align:"left"},
      //       {text:'Solicitações', value:'Solicitacoes', width:"20%", align:"center"},
      //       {text:'Transferências', value:'Tranferencias', width:"20%", align:"center"},
      //       {text:'Dispensacoes', value:'Dispensacoes', width:"20%", align:"center"},
      //   ]
      // }
      // if (this.idUnidadeTipo == 2){
      //   this.headersTransferencia = [
      //       {text:'Nome da unidade', value:'Unidade', width:"60%", align:"left"},
      //       {text:'Solicitações', value:'Solicitacoes', width:"10%", align:"center"},
      //       {text:'Transferências', value:'Tranferencias', width:"10%", align:"center"},
      //       {text:'Dispensacoes', value:'Dispensacoes', width:"10%", align:"center"},
      //       {text:'Consumo', value:'Consumo', width:"10%", align:"center"},
      //   ]
      // }
      // if (this.idUnidadeTipo == 3){
      //   this.headersTransferencia = [
      //       {text:'Nome da unidade', value:'Unidade', width:"50%", align:"left"},
      //       {text:'Solicitações', value:'Solicitacoes', width:"20%", align:"center"},
      //       {text:'Transferências', value:'Tranferencias', width:"20%", align:"center"},
      //       {text:'Dispensacoes', value:'Dispensacoes', width:"20%", align:"center"},
      //   ]
      // }
      // if (this.idUnidadeTipo == 4){
      //   this.headersTransferencia = [
      //       {text:'Nome da unidade', value:'Unidade', width:"50%", align:"left"},
      //       {text:'Solicitações', value:'Solicitacoes', width:"20%", align:"center"},
      //       {text:'Transferências', value:'Tranferencias', width:"20%", align:"center"},
      //       {text:'Dispensacoes', value:'Dispensacoes', width:"20%", align:"center"},
      //   ]
      // }

      if (this.idUnidadeTipo != 2) {
        this.headersTransferencia = this.headersTransferencia.slice(0, 4);
      } else if (this.headersTransferencia.length == 4) {
        this.headersTransferencia.push({
          text: 'Dispensações',
          value: 'Dispensacoes',
          width: '10%',
          align: 'center',
        });
      }
    },
  },
  mounted() {
    this.inicializadora(); // Não tirar essa função do topo, ela define qual filtro vai ser carregado na inicialização pelo tipo do usuário
    this.mudarHeaders();
    this.getResumoSolicitacoesGrafico();
    this.getConsumoUnidade();
    this.getSetor();
    this.getUnidades();
    this.getAnos();
    this.getChartMedicamentos();
    this.getChartVencidos();
    this.getGrupoDeItensConsumidos();
    this.getAVencer();
    this.getItens();
    // this.getDispensacaoMateriais()
  },
};
</script>

<style scoped></style>
