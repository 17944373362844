<template>
  <v-app>
    <span v-if='showToolbar'>
      <toolbar-system/>

      <vue-particles
        style="height: 100vh;"
        color="#0E6BA8"
        :particleOpacity="0.6"
        linesColor="#41B883"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="4"
        :linesWidth="2"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="1"
        :click-effect='false'
        :hover-effect='false'
      />
    </span>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import ToolbarSystem from './components/ToolbarSystem'

export default {
  name: 'App',
  components: {
    ToolbarSystem
  },

  computed: {
    showToolbar()
    {
      return ![
        '/',
        '/form',
        '/termsform',
        '/resultado',
        '/saude/painel',
        'saude/painelVideos',
        '/saude/painelVideos',
        '/saude/painelAmbulatorial',
        '/saude/painelAssistencia'
      ]
      .includes(this.$route.path);
    }
  }
};
</script>

<style>
  #particles-js {
    position: absolute;
    overflow-y: hidden;
  }
</style>