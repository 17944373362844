<template>
  <div class="page">
    <progress-system :isLoad="progress"></progress-system>
    <v-container>
      <v-alert v-if="confirmacao!=null"
          class="ml-2 text--primary mt-2"
          border="left"
          type="success"
      >
        {{ confirmacao }}
      </v-alert>
    </v-container>
  </div>
</template>
<script>
import ProgressSystem from '../components/ProgressSystem'
export default{
  name: "Confirmar Email",
  components:{
    ProgressSystem,
  },
  data: () => ({
      progress: false,
      confirmacao: null,
      IdPessoaFisica: new URL(location.href).searchParams.get('pessoafisica')
  }),
  methods:{
    confirmarEmail(){ 
      this.axios.get(`usuario/${this.IdPessoaFisica}/email/confirm`).then( res => {
        this.confirmacao = res.data
        this.progress = false
      }).catch(e => {
        this.confirmacao = e.response.data
        this.progress = false
      })
    }
  },
  mounted(){
    this.confirmarEmail()
  }
}
</script>