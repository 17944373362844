export default {
    numberToMonth: number => (
        [
            'Janeiro',
            'Fervereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ][number - 1]
    )
}