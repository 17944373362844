<template>
    <div class="page">
        <div class="container-fluid">   

        <v-row>

            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                        PÁGINA EM CONSTRUÇÃO
                    </h4>
                    <h5 class="error--text font-weight-bold pb-4 mb-0" style="text-align: center"> ESTA PÁGINA ENCONTRA-SE ATUALMENTE EM CONSTRUÇÃO </h5>
                </v-card>
            </v-col>
        
        </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmConstrucao',
    data () {
        
    }
}
    
</script>

<style lang="scss">
</style>