<template>
    <div>
        <div class="container-fluid">
            <v-row>
                <v-col cols="12">
                    <v-card shaped elevation="1">
                        <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                            PREFEITURA MUNICIPAL DE MACAÍBA - SIS GESTOR SAÚDE INFORMAÇÕES GERENCIAIS
                        </h4>
                        <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center">AMBULATORIAL</h5>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="unidades"
                        v-model="idUnidade"
                        item-text="Unidade"
                        item-value="Id"
                        label="Filtrar pela unidade"
                        @change="updateUnidadeData"
                        hide-details
                        outlined
                        dense
                    />
                </v-col>
            </v-row>
            <v-row v-if="idUnidade">
                <v-col cols="12" md="3">
					<dashboard-statistics-card
                        :items="fichaList"
                        :fichaTotal="fichaToday"
                        :show="showFichasStatisticas"
                        :bool="true"
                        :height="408"
                    />
				</v-col>
                <v-col cols="12" md="6">
                    <dashboard-ultimas-senhas
                        :items="UltimasFichasChamadas"
                        :show="showFichasChamadas"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-pie-genero
                        :items="generoList"
                        :show="showPieGenero"
                        :height="408"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-atendimentos-by-procedimento-table
                        :items="atendimentosTotatisAnoProcedimento"
                        :gruposProcedimento="gruposProcedimento"
                        :show="showCardProcedimentoTable"
                        @updatedGrupoProcedimento="getAtendimentosProcedimento"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-pizza-turno :items="atendimentosTurno" :show="showCardTurnos"/>

                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-trend-dias
                        :items="ultimosDiasList"
                        :especialidades="especialidades"
                        :line="ultimosDiasLine"
                        :show="showUltimosDias"
                        @updatedEspecialidade="atendimentoUltimosDias"
                        :height="408"
                        :filtering="true"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-atendimentos-table
                        atendimentosBy="especialidade"
                        period="mês"
                        keyAtendimentosBy="Especialidade"
                        keyAmount="QtdAtendimento"
                        :items="atendimentosTotaisAnoEspecialidade"
                        :show="showCardEspecialidadeTable"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-pie-presenca
                        :items="presencaAtendimentos"
                        :show="showCardPiePresenca"
                        :especialidades="especialidades"
                        @updatedEspecialidade="getPresencaAtendimentos"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-atendimentos-table
                        atendimentosBy="bairro"
                        period="ano"
                        keyAtendimentosBy="Bairro"
                        keyAmount="Quantidade"
                        :items="atendimentosTotaisAnoBairro"
                        :show="showCardBairrosTable"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-barras-etaria
                        :items="atendimentosTotaisAnoFaixaEtaria"
                        :show="showCardFaixaEtariaTable"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <dashboard-card-barras-doencas
                        :items="doencasList"
                        :show="showBarrasDoencas"
                    /> 
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col col="12" md="6">
                    <v-alert
                        dark
                        dense
                        class="black--text"
                        color="amber lighten-2"
                        border="left"
                    >
                        <v-icon color="black"> mdi-hospital-box </v-icon>
                        <span class="pl-2">
                            Por favor, selecione uma <strong>unidade</strong> para visualizar o <strong>dashboard</strong>.
                        </span>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import DashboardStatisticsCard from '@/components/painel/DashboardStatisticsCard.vue'
import DashboardCardTrendDias from '@/components/painel/DashboardCardTrendDias.vue'
import DashboardCardAtendimentosByProcedimentoTable from '@/components/painel/DashboardCardAtendimentosByProcedimentoTable.vue'
import DashboardCardPieGenero from '@/components/painel/DashboardCardPieGenero.vue'
import DashboardUltimasSenhas from '@/components/painel/DashboardUltimasSenhas.vue'
import DashboardCardPiePresenca from '../../components/painel/DashboardCardPiePresenca.vue'
import DashboardCardAtendimentosTable from '@/components/painel/DashboardCardAtendimentosTable'
import DashboardCardBarrasEtaria from '@/components/painel/DashboardCardBarrasEtaria.vue'
import DashboardCardBarrasDoencas from '@/components/painel/DashboardCardBarrasDoencas.vue'
import DashboardCardPizzaTurno from '@/components/painel/DashboardCardPizzaTurno.vue'

    export default {
        name: 'ambulatorial',
        components: {
            DashboardStatisticsCard,
            DashboardCardTrendDias,
            DashboardCardPieGenero,
            DashboardCardAtendimentosByProcedimentoTable,
            DashboardUltimasSenhas,
            DashboardCardAtendimentosTable,
            DashboardCardBarrasEtaria,
            DashboardCardBarrasDoencas,
            DashboardCardPizzaTurno,
            DashboardCardPiePresenca
        },
        data: () => ({
            unidades: [],
            idUnidade: null,

            fichaList: [],
            fichaToday: 0,
            showFichasStatisticas: false,

            atendimentosTurno: [],
            showCardTurnos: false,

            especialidades: [],
            idEspecialidade: null,
            ultimosDiasList: [],
            ultimosDiasLine: 0,
            showUltimosDias: false,

            generoList: {Masculino: 0, Feminino: 0},
            showPieGenero: false,

            gruposProcedimento: [],
            atendimentosTotatisAnoProcedimento: [],
            showCardProcedimentoTable: false,

            UltimasFichasChamadas: [],
            showFichasChamadas: false,

            presencaAtendimentos: [],
            showCardPiePresenca: false,

            atendimentosTotaisAnoBairro: [],
            showCardBairrosTable: false,

            atendimentosTotaisAnoFaixaEtaria: [],
            showCardFaixaEtariaTable: false,

            atendimentosTotaisAnoEspecialidade: [],
            showCardEspecialidadeTable: false,

            doencasList: [],
            showBarrasDoencas: false,
        }),
        methods: {
            updateUnidadeData() {
                this.getPresencaAtendimentos()
                this.getAtendimentosTurno()
                this.atendimentoUltimosDias()
                this.classificacaoSexo()
                this.getAtendimentosProcedimento()
                this.getAtendimentoUltimasFichasChamadas()
                this.getAtendimentosEspecialidade()
                this.getFluxoUsuarios()
                this.getAtendimentoPorBairro()
                this.getAtendimentosFaixaEtaria()
                this.classificacaoDoencas()
                this.getEspecialidades()
                this.getGruposProcedimento()
            },
            getUnidades() {
                this.axios.get('unidade', {
                    params: {
                        'idUnidadeSetor': 2
                    }
                }).then((res) => {
                    this.unidades = res.data
                })
            },
            getFluxoUsuarios(){
                this.showFichasStatisticas = false

                this.axios.get('ambulatorial/atendimento/fichaschamadas', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                })
                .then(res => {
                    const fichas = res.data[0]
                  
                    this.fichaToday = fichas.FichasEntregues
                    this.fichaList = [
                        {
                            title: 'Agendados para hoje',
                            total: fichas.Agendados,
                            icon: 'mdi-account-check-outline',
                            color: '#013B61'
                        },{
                            title: 'Ainda não chegou',
                            total: fichas.NaoComparecidos,
                            icon: 'mdi-account-check-outline',
                            color: '#013B61'
                        },
                        {
                            title: 'Esperando atendimento',
                            total: fichas.AtendimentoNaoIniciado + fichas.SalaPreparo,
                            icon: 'mdi-account-clock-outline',
                            color: '#1E4661'
                        },
                        /*{
                            title: 'Sala de preparo',
                            total: fichas.SalaPreparo,
                            icon: 'mdi-account-sync-outline',
                            color: '#0288E0'
                        },*/
                        {
                            title: 'Atendimento realizado',
                            total: fichas.Atendido,
                            icon: 'mdi-account-search-outline',
                            color: '#47A6E6'
                        }
                    ]
                }).finally(() => {
                    this.showFichasStatisticas = true
                })
            },
            getAtendimentosTurno() {
                this.showCardTurnos = false

                this.axios.get('ambulatorial/atendimento/atendimentosturno', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                })
                .then(res => this.atendimentosTurno = res.data)
                .finally(() => this.showCardTurnos = true )
            },
            getEspecialidades() {
                this.axios.get('especialidade')
                .then(res => {
                    this.especialidades = res.data
                })
            },
            atendimentoUltimosDias(idEspecialidade){
                this.showUltimosDias = false

                this.axios.get('ambulatorial/atendimento/ultimostrintadias', {
                    params: {
                        'IdUnidade': this.idUnidade,
                        'IdEspecialidade': idEspecialidade
                    }
                }).then(res => {
                    this.ultimosDiasLine = Math.round(
                        res.data.reduce((prev, curr) => prev + curr.QtdAtendimento, 0)/res.data.length
                    )
                    this.ultimosDiasList = res.data.map(mun => {
                        return {
                            Data: moment(mun.DataAtendimento).format('DD/MM'),
                            Quantidade: mun.QtdAtendimento,
                        }
                    })
                }).finally(() => {
                    this.showUltimosDias = true
                })
            },
            classificacaoSexo() {
                this.showPieGenero = false

                this.axios.get('ambulatorial/atendimento/atendimentoclassificacaosexo', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                })
                .then(res => {
                    if(res.data.length === 2){
                        this.generoList.Feminino = res.data[0].Quantidade
                        this.generoList.Masculino = res.data[1].Quantidade
                    }
                    else{
                        this.generoList.Feminino = 0
                        this.generoList.Masculino = 0
                    }
                })
                .finally(() => this.showPieGenero = !this.showPieGenero )
            },
            getGruposProcedimento() {
                this.axios.get('procedimento/grupoprocedimento')
                .then(res => {
                    this.gruposProcedimento = res.data
                })
            },
            getAtendimentosProcedimento(idGrupoProcedimento) {
                this.showCardProcedimentoTable = false

                this.axios.get('ambulatorial/atendimento/atendimentosprocedimento', {
                    params: {
                        'IdUnidade': this.idUnidade,
                        'IdGrupoProcedimento': idGrupoProcedimento
                    }
                }).then(res => {
                    this.atendimentosTotatisAnoProcedimento = res.data
                }).finally(() => {
                    this.showCardProcedimentoTable = true
                })
            },
            getAtendimentoUltimasFichasChamadas() {
                this.showFichasChamadas = false

                this.axios.get('ambulatorial/atendimento/ultimassenhaschamadas', {
                    params: {
                        'IdUnidade': this.idUnidade,
                    }
                })
                .then(res => {
                    this.UltimasFichasChamadas = res.data
                }).finally(() => {
                    this.showFichasChamadas = true
                })
            },
            getAtendimentosEspecialidade(){
                this.showCardEspecialidadeTable = false
            
                this.axios.get('ambulatorial/atendimento/atendimentosespecialidade', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                }).then(res => {
                    this.atendimentosTotaisAnoEspecialidade = res.data
                }).finally(() => {
                    this.showCardEspecialidadeTable = true
                })
            },
            getPresencaAtendimentos(idEspecialidade) {
                this.showCardPiePresenca = false

                this.axios.get('ambulatorial/atendimento/fichaschamadas', {
                    params: {
                        'IdUnidade': this.idUnidade,
                        'IdEspecialidade': idEspecialidade
                    }
                })
                .then(res => {
                    const atendimentos = res.data[0]
                    this.presencaAtendimentos = [atendimentos.Atendido, atendimentos.NaoComparecidos]
                }).finally(() => {
                    this.showCardPiePresenca = true
                })
            },
            getAtendimentoPorBairro(){
                this.showCardBairrosTable = false

                this.axios.get('ambulatorial/atendimento/atendimentoporbairro', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                }).then(res => {
                    this.atendimentosTotaisAnoBairro = res.data
                        }).finally(() => {
                    this.showCardBairrosTable = true
                })
            },
            getAtendimentosFaixaEtaria() {
                this.showCardFaixaEtariaTable = false

                this.axios.get('ambulatorial/atendimento/atendimentosporfaixaetaria', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                }).then(res => {
                    this.atendimentosTotaisAnoFaixaEtaria = res.data
                }).finally(() => {
                    this.showCardFaixaEtariaTable = true
                })
            },
            classificacaoDoencas() {
                this.showBarrasDoencas = false

                this.axios.get('ambulatorial/atendimento/atendimentoclassificacaodoencas', {
                    params: {
                        'IdUnidade': this.idUnidade
                    }
                }).then(res => {
                    this.doencasList = []
                    this.doencasList = res.data
                }).finally(() => {
                    this.showBarrasDoencas = true
                })
            }
        },
        mounted() {
            this.getUnidades();

            setInterval(() => {
                if (this.idUnidade != null) {
                    this.getAtendimentoUltimasFichasChamadas();
                    this.classificacaoSexo();
                    this.getAtendimentosTurno();
                    this.getPresencaAtendimentos();
                }
            }, 5*60*1000);
        }
    }
</script>