<template>
    <div id="chart">
        <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>        
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
    },
    data() {
        return {
            series: [],
            chartOptions: {
                title: {
                    text: '',
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '14px',
                        fontWeight:  'bold',
                        fontFamily:  undefined,
                        color:  '#263238'
                    },
                },
                // chart: {
                //     width: 380,
                //     type: 'pie',
                // },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300,
                            height: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: true,
                    position: 'bottom'
                }
            },
        }
    },
    methods: {
        groupBy(array, key) {
			return array.reduce((result, currentValue) => {(
				result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
				return result;
			}, {});
		}
    },
    mounted () {
        const groupedData = this.groupBy(this.data, 'Zona');
        const obj = Object.keys(groupedData)
        for (var key in obj) {
            this.chartOptions.labels.push(obj[key])
            this.series.push(groupedData[obj[key]].length)
        }
    }
}
</script>