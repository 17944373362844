<template>
    <v-select
        :items="anos"
        v-model="id"
        item-text="id"
        item-value="id"
        label="Filtrar pelo ano"
        @change="sendMessageToParent($event)"
        hide-details
        outlined
        dense
    ></v-select>
</template>

<script>
export default {
    data() {
        return {
            anos: [],
            id: new Date().getFullYear()
        }
    },
    methods: {
        getAno() {
            let max = new Date().getFullYear();
            let min = max - 7;
            let anos = [];

            for (let i = max; i >= min; i--) {
                anos.push({
                id: i,
                ano: i.toString(),
                });
            }
            this.anos = anos;
        },
        sendMessageToParent(value){
            this.$emit('messageFromChild', value)
        }
    },
    mounted(){
        this.getAno()
    }
}
</script>
